.share-rs {
    .btn-share {

        .btn-send,
        .btn-print {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: background-image .8s cubic-bezier(.23,1,.32,1);
        }

        .btn-send {
            margin-right: 20px;
            background-image: url('img/icons/share.svg');
            
            &:hover {
                background-image: url('img/icons/share_dark.svg');
            }

            @media screen and (max-width: 576px) {
                margin-right: 10px;
            }
        }

        .btn-print {
            background-image: url('img/icons/print.svg');

            &:hover {
                background-image: url('img/icons/print_dark.svg');
            }

            @media screen and (max-width: 576px) {
                display: none;
            }
        }
    }
}
.user-favorite-detail {
    position: absolute;
    top: 0;
    right: -100vw;
    background-color: $background-secondary;
    height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity .15s linear;

    &.open {
        left: 0;
        right: unset;
        opacity: 1;
    }

    .back-to-favorites-list {
        button {
            position: relative;
            font-size: 14px;
            color: $primary-color;
            padding-bottom: 15px;
            padding-left: 1.5rem;

            &::before {
                content: url('/img/icons/arrow_back.svg');
                position: absolute;
                left: 0px;
                top: 7px;
            }

            &:hover {
                color: $text-color;
            }
        }
    }

    .main-detail-favorite-list {
        .bg-modal {
            display: none;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.5);
            z-index: 3;

            &.open {
                display: block !important;
                opacity: unset;
            }
        }

        .favorite-list-wrapper {
            margin-bottom: 50px;
            padding: 25px;
            background-color: $white-color-10;
            border: $input-border;
            border-radius: 6px;
            box-shadow: $shadow;

            @media screen and (max-width: 575px) {
                margin-bottom: 40px;
                padding: 20px 15px;
            }

            @media screen and (max-width: 430px) {
                margin-bottom: 25px;
            }

            .name-list-wrapper {
                margin-bottom: 15px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }
            }

            .buttons-action-list {
                display: flex;
                justify-content: end;

                @media screen and (max-width: 575px) {
                    justify-content: space-between;
                }

                @media screen and (max-width: 430px) {
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 15px;
                }

                .rename-list-btn,
                .delete-list-btn {
                    width: 24%;
                    padding: 8px 0px;
                    margin-left: 20px;
                    border-radius: 25px;
                    border: 1px solid $input-border;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;

                    @include media-breakpoint-down(md) {
                        width: 35%;
                    }

                    @media screen and (max-width: 575px) {
                        width: 48%;
                        margin-left: 0;
                    }

                    @media screen and (max-width: 430px) {
                        width: 100%;
                    }

                    &:hover {
                        background-color: $background-secondary;
                    }
                }

                // modals
                .modal {
                    background-color: rgba(0, 0, 0, 0.2);
                    
                    .modal-dialog {
                        margin: auto;

                        .modal-content {
                            @media screen and (max-width: 500px) {
                                max-width: 100vw;
                            }
                        }
        
                        .modal-header {
                            border-bottom: 1px solid $input-border;
                            
                            .modal-title {
                                font-size: 16px;
                                font-weight: 600;
                                color: $text-color;
                            }
                        }
        
                        .modal-body {
                            form {
                                input[type="text"] {
                                    &.has-error {
                                        border: 2px solid $primary-color;
                                    }
                                }
        
                                .label-title {
                                    position: relative;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: $text-color;
        
                                    &.required {
                                        &::after {
                                            content: "*";
                                            color: $body-secondary;
                                            position: absolute;
                                            right: -10px;
                                        }
                                    }
                                }
        
                                .msg-error {
                                    display: none;
                                    visibility: hidden;
                                    margin-top: 10px;
                                    padding: 10px;
                                    color: $primary-color;
                                    background-color: $primary-color-10;
                                    border: 1px solid $input-border-error;
                                    border-radius: 6px;
                                    font-size: 14px;
                
                                    p {
                                        line-height: 22px;
                                    }
                
                                    &.visible {
                                        display: block;
                                        visibility: visible;
                                    }
                                }
                            }
        
                            .confirm-delete {
                                margin: 10px 0;
                                color: $text-color;
                                font-size: 16px;
                                line-height: 25px;
        
                                @media screen and (max-width: 576px) {
                                    margin: 0;
                                }
                            }
                        }
        
                        .modal-footer {
                            display: block;
                            border-top: none;
        
                            .buttons-wrapper-footer {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;
        
                                @include media-breakpoint-down(sm) {
                                    flex-direction: column;
                                    gap: 10px;
                                }
            
                                button {
                                    width: 48%;
                                    padding: 12px 0px;
                                    border-radius: 25px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    text-align: center;
            
                                    @include media-breakpoint-down(sm) {
                                        width: 100%;
                                    }
            
                                    &.cancel {
                                        border: 1px solid $input-border;
                                    }
            
                                    
                                    &[class^='confirm-rename-favorite-list-'] {
                                        &:hover {
                                            background-color: $primary-color-hover;
                                        }
                                    }
                                }
                            }
        
                            [class^='confirmation-rename-list-msg-'] {
                                .done,
                                .fail {
                                    display: none;
                                    margin-top: 15px;
                                    border-radius: 6px;
                                    padding: 10px 15px;
                                    border: 1px solid $input-border;
                                    justify-content: center;
                                    align-items: flex-start;
                                    box-shadow: $shadow;
                                    
                                    p {
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        color: $text-color;
                    
                                        @media screen and (max-width:430px) {
                                            margin-left: 15px;
                                        }
                                    }
                    
                                    img {
                                        width: 25px;
                                        margin-right: 8px;
        
                                        @media screen and (max-width:425px) {
                                            margin-top: 5px;
                                            margin-right: 5px;
                                        }
                                    }
                    
                                    &.visible {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .recap-added-favorite-articles {
            .product-line-header {
                border-bottom: 1px solid $input-border;
                padding-bottom: 8px;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                div {
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 600;

                    // @media screen and (max-width: 1153px) {
                    //     font-size: 9px;
                    // }

                    &.pu-ht-head {
                        text-align: left;
                        
                        @media screen and (min-width: 992px) {
                            padding-left: 0;
                            text-align: right;
                        }
                    }

                    
                    &.total-ht-head {
                        text-align: center;

                        @media screen and (min-width: 992px) {
                            text-align: right;
                            padding-right: 0;
                            flex: 0 0 11.33333333%;
                            max-width: 11.33333333%;
                        }
                    }

                    &.articles-head{
                        padding-left: 15.5px;

                        @media screen and (min-width: 992px) {
                            flex: 0 0 55.33333333%;
                            max-width: 55.33333333%;
                        }
                    }

                    &.quantity-head {
                        //padding-left: 23.5px;
                        padding-left: 10px;
                        text-align: center;
                        
                        @media screen and (min-width: 992px) {
                            padding-left: 3px;
                        }
                    }
                }
            }

            .product-line {

                &:not(:last-of-type) {
                    border-bottom: 1px solid $input-border;

                    @include media-breakpoint-down(md) {
                        border-bottom-width: 2px;
                    }
                }

                .product-line-header-mobile {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $input-border;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;

                    div {
                        &.articles-head {
                            @media screen and (max-width: 430px) {
                                padding-left: 0;
                            } 
                        }

                        &.pu-ht-head {
                            @media screen and (max-width: 430px) {
                                padding-left: 0;
                            } 
                        }

                        &.quantity-head {
                            @media screen and (max-width: 430px) {
                                text-align: center;
                            } 
                        }

                        &.total-ht-head {
                            @media screen and (max-width: 430px) {
                                text-align: right;
                                padding-right: 0;
                            }

                            @media screen and (min-width: 429px) and (max-width: 991px) {
                                padding-left: 12.5px;
                            }
                        }
                    }
                }

                .picture-product {
                    @media screen and (max-width: 575px) {
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }

                    .img-wrapper {
                        max-width: 110px;
                        max-height: 110px;
                        width: 110px;
                        height: 110px;
                        text-align: center;

                        @media screen and (max-width: 1024px) {
                            max-width: 100px;
                            max-height: 100px;
                            width: 100px;
                            height: 100px; 
                        }

                        @media screen and (max-width: 575px) {
                            margin: auto;
                            max-width: 150px;
                            max-height: 150px;
                            width: 150px;
                            height: 150px;
                        }

                        img {
                            width: auto;
                            height: 110px;
                            max-height: 110px;

                            @media screen and (max-width: 1024px) {
                                height: 100px;
                                max-height: 100px;
                            }

                            @media screen and (max-width: 575px) {
                                height: 150px;
                                max-height: 150px;
                            }
                            
                            &.modal-open {
                                opacity: 0.05;
                            }
                        }
                    }
                }

                .description-product {

                    @media screen and (min-width: 992px) {
                        flex: 0 0 33.66666667% !important;
                        max-width: 33.66666667% !important;
                    }

                    .brand-name {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 500;
                        color: $text-light-gray;

                        // @media screen and (max-width: 430px) {
                        //     text-align: right;
                        // }
                    }

                    .name-product {
                        a {
                            font-size: 13px;
                            line-height: 20px;
                            font-weight: 600;
                            color: $text-color;
                        }
                    }

                    .ref-brand {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 400;
                        color: $text-light-gray;
                    }
                }

                // .brand-product {
                //     @media screen and (min-width: 992px) {
                //         flex: 0 0 14.66666667% !important;
                //         max-width: 14.66666667% !important;
                //     }

                //     @media screen and (min-width: 429px) and (max-width: 991px) {
                //         padding-left: 12.5px;
                //     }

                //     .brand-name {
                //         font-size: 13px;
                //         line-height: 20px;
                //         font-weight: 500;
                //         color: $text-light-gray;

                //         @media screen and (max-width: 430px) {
                //             text-align: right;
                //         }
                //     }
                // }

                .quantity-product {
                    padding-left: 0;
                    padding-right: 0;
                    
                    @media screen and (min-width: 992px) {
                        flex: 0 0 41.33333333% !important;
                        max-width: 41.33333333% !important;
                    }

                    .quantity-wrapper {
                        @media screen and (max-width: 1024px) {
                            padding-left: 0;
                            padding-right: 0;
                        }

                        .unit-price,
                        .total-one-product {
                            p {
                                text-align: right;
                                font-size: 12px;

                                // @media screen and (min-width: 992px) and (max-width: 1075px) {
                                //     font-size: 11px;
                                // }

                                @include media-breakpoint-down(md) {
                                    text-align: left;
                                    font-size: 14px;
                                }

                                @media screen and (max-width: 350px) {
                                    font-size: 13px;
                                }

                                @media screen and (max-width: 329px) {
                                    font-size: 12px;
                                }
                            }
                        }

                        .unit-price {
                            @media screen and (min-width: 992px) {
                                flex: 0 0 32.33333333% !important;
                                max-width: 32.33333333% !important;
                                justify-content: end;
                            }

                            @media screen and (max-width: 430px) {
                                padding-right: 0;
                            }
                        }

                        .total-one-product {
                            @media screen and (min-width: 992px) {
                                flex: 0 0 28% !important;
                                max-width: 28% !important;
                            }

                            @media screen and (min-width: 429px) and (max-width: 991px) {
                                padding-left: 12.5px;
                            }
                            
                        }
                        
                        .nb-quantity {
                            padding-top: 8px;
                            // padding-left: 0;

                            @media screen and (min-width: 992px) {
                                flex: 0 0 39.66666667% !important;
                                max-width: 39.66666667% !important;
                            }

                            // @include media-breakpoint-down(md) {
                            //     padding-left: 7.5px;
                            // }

                            @media screen and (max-width: 430px) {
                                padding-left: 5px;
                                padding-right: 5px;
                            }

                            .input-group {
                                flex-wrap: nowrap;

                                &.modal-open {
                                    opacity: 0.05;
                                }
        
                                .input-group-btn {
                                    width: 28%;
                                    max-width: 28%;
        
                                    .subst-prod,
                                    .add-prod {
                                        background-color: $white;
                                        width: 100%;
                                        height: calc(1.5em + .85rem + 1px);
        
                                        &:focus {
                                            outline: none;
                                            box-shadow: none;
                                        }
        
                                        span {
                                            width: 24px;
                                            height: 24px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            border: 1px solid transparent;
                                            border-radius: 50%;
                                        }
        
                                        .glyphicon {
                                            padding-bottom: 1px;
                                        }
                                    }
        
                                    .subst-prod {
                                        padding-left: 0.65rem;
                                        padding-right: 0.5rem;
                                        border-radius: 25px 0 0 25px;
                                        border-left: 1px solid $input-border;
                                        border-top: 1px solid $input-border;
                                        border-bottom: 1px solid $input-border;
                                        display: flex;
                                        justify-content: start;
                                        align-items: center;

                                        @media screen and (max-width: 430px) {
                                            padding-left: 0.5rem;
                                        }
                                    }
        
                                    .add-prod {
                                        padding-left: 0.5rem;
                                        padding-right: 0.65rem;
                                        border-radius: 0 25px 25px 0;
                                        border-right: 1px solid $input-border;
                                        border-top: 1px solid $input-border;
                                        border-bottom: 1px solid $input-border;
                                        display: flex;
                                        justify-content: end;
                                        align-items: center;

                                        @media screen and (max-width: 430px) {
                                            padding-right: 0.5rem;
                                        }
                                    }
                                }
        
                                .nb-product {
                                    width: 44%;
                                    max-width: 44%;
                                    height: calc(1.5em + .85rem + 1px);
                                    text-align: center;
                                    background-color: $white;
                                    border-top: 1px solid transparent;
                                    border-bottom: 1px solid transparent;
                                    border-left: none;
                                    border-right: none;
                                    padding-left: 1px;
                                    padding-right: 1px;
        
                                    &:focus {
                                        outline: none;
                                        box-shadow: none;
                                    }
        
                                    &::-webkit-inner-spin-button,
                                    &::-webkit-outer-spin-button {
                                        -webkit-appearance: none;
                                        opacity: 0;
                                        margin: 0;
                                    }
                                    -moz-appearance: textfield;
                                        /* Firefox */
        
        
                                    &:hover,
                                    &:focus {
                                        -moz-appearance: number-input;
                                    }
                                }
                            } 
                        }

                        .total-one-product {
                            padding-left: 0;
                            padding-right: 0;

                            @include media-breakpoint-down(md) {
                                padding-left: 7.5px;
                            }

                            @media screen and (min-width: 429px) and (max-width: 991px) {
                                padding-left: 12.5px;
                            }

                            @media screen and (max-width: 430px) {
                                padding-right: 7.5px;
                                padding-left: 0;
                                
                                .rate-tot-one-product {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }

                .delete-product {
                    padding-top: 10px;
                    text-align: center;

                    @include media-breakpoint-down(md) {
                        text-align: right;
                        padding-right: 45px;
                    }

                    @media screen and (max-width: 575px) {
                        padding-right: 25px;
                    }

                    @media screen and (max-width: 430px) {
                        text-align: center;
                        padding-right: 0;
                        padding-left: 0;
                        padding-top: 20px;
                    }

                    .delete-product-btn {
                        padding-left: 0;
                        padding-right: 0;

                        span {
                            display: block;
                            width: 16px;
                            height: 19px;
                            background-image: url('/img/icons/delete.svg');
                            background-repeat: no-repeat;
                            background-position: center;

                            &:hover {
                                background-image: url('/img/icons/delete_black.svg');
                            }
                        }
                    }
                }
            }  
        }

        .add-list-in-basket-wrapper {
            border-top: 1px solid $input-border;
            padding-top: 25px;

            @include media-breakpoint-down(md) {
                padding-top: 45px;
            }

            .add-list-in-basket-btn {
                z-index: 1;
                position: relative;
                width: 100%;
                padding: 12px 16px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;

                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }
    }
}
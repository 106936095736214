.spinner-wrapper {
  //position: absolute;
  position: fixed;
  width: 100%;
  height: 80%;
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;

  &.open {
    display: flex;
  }

  @include media-breakpoint-down(md) {
    display: none !important;
  }

  .spinner-grow {
    width: 1rem;
    height: 1rem;
    margin: 0 3px;
  }
}

#tarteaucitronRoot * {
    font-family: $font-inter, sans-serif !important;
}

#tarteaucitronAllAllowed,
#tarteaucitronAllDenied,
.tarteaucitronAllow,
.tarteaucitronDeny,
#tarteaucitronAlertBig button {
    border-radius: 25px !important;
}

#tarteaucitronAlertBig button {
    margin-bottom: 5px;
}

#tarteaucitronDisclaimerAlert {
    text-align: center !important;
    display: block !important;
    margin-bottom: 10px !important;
    line-height: 1.5 !important;
}


// Popin
#tarteaucitronClosePanel {
    border-radius: 6px 6px 0 0;

    &::before {
        content: "\2717";
        margin-right: 3px;
        position: relative;
        top: -1px;
    }

    @media screen and (max-width: 767px){
        border-radius: 0 0 0 0;
    }
}

#tarteaucitronRoot button#tarteaucitronBack {
    background: $body-primary !important;
    opacity: .4 !important;
}

#tarteaucitronServices {
    border-radius: 6px 0 6px 6px;
    box-shadow: 0 40px 60px #17161D0F !important;
}

#tarteaucitronMainLineOffset {
    color: $text-color;
    background: $white !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
    background: $white !important;
}

#dialogTitle {
    color: $text-color !important;
    background-color: $white !important;
    font-weight: 600 !important;
    font-size: 1.7em !important;
}

#tarteaucitronInfo {
    border-color: $input-border !important;
    background: $white !important;
    color: $text-color !important;
    font-weight: 400 !important;
}

.tarteaucitronH2 {
    color: $text-color !important;
    font-weight: 600 !important;
}

.tarteaucitronTitle {
    border-bottom: 1px solid $input-border !important;
    background: $white;

    button {
        border-radius: 0 6px 0 0;
    }
}

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
    background: $white !important;

    &:hover {
        background: $background-secondary !important;
    }

    &#tarteaucitronMainLineOffset {
        &:hover {
            background: $white !important;
        }
    }

    .tarteaucitronName {
        .tacCurrentStatus {
            color: $text-color !important;
        }

        .tarteaucitronListCookies {
            color: $text-color !important;
            font-size: 13px !important;
            font-weight: 500 !important;
        }
    }
}
.user-addresses {
    position: relative;

    .main-user-address {
        padding: 15px;
        margin-bottom: 25px;
        background-color: $white-color-10;
        border-radius: 6px;
        box-shadow: $shadow;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }

            &.company {
                text-transform: uppercase;
            }
        }

        .contact-calvez {
            border-top: thin solid $input-border;
            padding-top: 10px;
            padding-bottom: 5px;

            p {
                font-size: 14px;
                color: $text-color;
    
                &.title {
                    font-weight: 600;
                    font-size: 15px;
                    margin-bottom: 5px;
                }
    
                &.contact-link {
                    a {
                        color: $primary-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }



    .addresses-list {
        padding-left: 0;
        padding-right: 0;

        .title {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: $text-color;
            padding-left: 15px;
            margin-bottom: 15px;
        }

        .list-group {
            margin-bottom: 20px;
            border-radius: 6px;
            box-shadow: $shadow;

            .list-group-item {
                display: flex;
                align-items: center;
                justify-content: end;
                padding: 15px;
                border-color: $input-border;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }

                .address-label {
                    margin-right: auto;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 600;
                    color: $text-color;

                    @include media-breakpoint-down(sm) {
                        margin-right: unset;
                        margin-bottom: 20px;
                    }
                }

                .btns-wrapper {

                    @include media-breakpoint-down(sm) {
                        display: flex;
                    }

                    @media screen and (max-width: 576px) {
                        flex-direction: column;
                        width: 75%;
                    }

                    button {
                        border-radius: 25px;
                        padding: 5px 16px;
                        font-size: 14px;
                        font-weight: 500;
                        transition: all .2s ease-in-out;

                        &.delete {
                            margin-right: 15px;
                            color: $text-color;
                            border: 1px solid $input-border;

                            @media screen and (max-width: 576px) {
                                margin-right: 0px;
                                margin-bottom: 15px;
                            }

                            &:hover {
                                color: $primary-color-hover;
                                background-color: $background-secondary-80;
                            }
                        }

                        &.update {
                            background-color: $primary-color;
                            color: $white;

                            &:hover {
                                background-color: $primary-color-hover;
                            }
                        }
                    } 
                }
            }
        }

        // Modal
        .modal {
            background-color: rgba(0, 0, 0, 0.2);
            
            .modal-dialog {
                margin: auto;

                .modal-content {
                    @media screen and (max-width: 500px) {
                        max-width: 100vw;
                    }
                }

                .modal-header {
                    border-bottom: 1px solid $input-border;
                    
                    .modal-title {
                        font-size: 16px;
                        font-weight: 600;
                        color: $text-color;
                    }
                }

                .modal-body {
                    form {
                        input[type="text"],
                        input[type="tel"] {
                            &.has-error {
                                border: 2px solid $primary-color;
                            }
                        }

                        .label-title {
                            position: relative;
                            font-size: 14px;
                            font-weight: 500;
                            color: $text-color;

                            &.required {
                                &::after {
                                    content: "*";
                                    color: $body-secondary;
                                    position: absolute;
                                    right: -10px;
                                }
                            }
                        }

                        .msg-error {
                            display: none;
                            visibility: hidden;
                            margin-top: 10px;
                            padding: 10px;
                            color: $primary-color;
                            background-color: $primary-color-10;
                            border: 1px solid $input-border-error;
                            border-radius: 6px;
                            font-size: 14px;
        
                            p {
                                line-height: 22px;
                            }
        
                            &.visible {
                                display: block;
                                visibility: visible;
                            }
                        }
                    }

                    .confirm-delete {
                        margin: 10px 0;
                        color: $text-color;
                        font-size: 16px;
                        line-height: 25px;

                        @media screen and (max-width: 576px) {
                            margin: 0;
                        }
                    }
                }

                .modal-footer {
                    display: block;
                    border-top: none;

                    .buttons-wrapper-footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                            gap: 10px;
                        }
    
                        button {
                            width: 48%;
                            padding: 12px 0px;
                            border-radius: 25px;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
    
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                            }
    
                            &.cancel {
                                border: 1px solid $input-border;

                                &:hover {
                                    background-color: $background-secondary;
                                }
                            }
    
                            &[class^='confirm-modif-address'],
                            &[class^='confirm-delete-address'],
                            &.confirm-add-address {
                                &:hover {
                                    background-color: $primary-color-hover;
                                }
                            }
                        }
                    }

                    // Ajouter qd delete an address
                    .confirmation-form-address-deleted-msg,
                    .confirmation-form-address-added-msg,
                    div[class^='confirmation-form-modif-address'] {
                        
                        .done,
                        .fail {
                            display: none;
                            margin-top: 15px;
                            border-radius: 6px;
                            padding: 10px 15px;
                            border: 1px solid $input-border;
                            justify-content: center;
                            align-items: flex-start;
                            box-shadow: $shadow;
                            
                            p {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 24px;
                                color: $text-color;
            
                                @media screen and (max-width:430px) {
                                    margin-left: 15px;
                                }
                            }
            
                            img {
                                width: 25px;
                                margin-right: 8px;

                                @media screen and (max-width:425px) {
                                    margin-top: 5px;
                                    margin-right: 5px;
                                }
                            }
            
                            &.visible {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .add-address-wrapper {
            padding-left: 15px;
            padding-right: 15px;

            @media screen and (max-width: 576px) {
                text-align: center;
            }

            @media screen and (max-width: 340px) {
                padding-left: 0px;
                padding-right: 0px;
            }

            .add-address { 
                border-radius: 25px;
                padding: 12px 16px;
                font-size: 14px;
                font-weight: 500; 
                
                @media screen and (max-width: 576px) {
                    width: 75%;
                    margin: 10px auto;
                    padding: 10px 16px;
                }

                @media screen and (max-width: 340px) {
                    width: 100%;
                }
            }
        }
    }
}
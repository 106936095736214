.bloc-accordion {
    border-bottom: 1px solid $body-secondary !important;

    .btn-link {
        width: 100%;
        font-weight: 700;
        color: $text-color;
        transition: all .4s ease-in-out;

        background-color: $white;

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: $white;
        }

        &.collapsed {
            background-color: transparent;

            &:hover {
                background-color: $white;
            }
        }

        svg {
            margin-top: 5px;

            path {
                fill: $black !important;
            }
        }
    } 

    .collapse,
    .collapsing {
        padding-top: 10px;
        padding-bottom: 25px;

        p {
            margin-bottom: .5rem;
        }

        p, ul {
            color: $text-color;
            font-size: 16px;
            line-height: 22px;
        }

        ul {
            margin-top: 1rem;
        }

        a {
            color: $primary-color;
            &:hover {
                color: $primary-color-hover;
            }
        }

        h2 {
            margin-top: 1rem;
            margin-bottom: .5rem;
            font-size: 24px;
            font-weight: 600;
    
            @include media-breakpoint-down(sm) {
                font-size: 22px;
            }
        }
    
        h3 {
            margin-top: 1rem;
            margin-bottom: .5rem;
            font-size: 22px;
            font-weight: 600;
            color: $text-light-gray !important;
    
            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
        }
    
        h4 {
            margin-top: 1rem;
            margin-bottom: .5rem;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase;
    
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
    }
}
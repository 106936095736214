.all-categories {
    position: relative;

    .title-container {
        padding-top: 40px;
        padding-bottom: 40px;

        h1 {
            font-size: 36px;
            font-weight: 600;
            line-height: 32px;

            @media screen and (max-width: 576px) {
                font-size: 28px;
            }
        }
    }

    .univers-container {
        flex-wrap: wrap;

        .univers-wrapper {
            width: calc(100% / 3);
            padding: 0 15px 15px 0;

            @media screen and (max-width: 810px) {
                width: 50%;
            }

            @media screen and (max-width: 576px) {
                width: 100%;
            }

            .univers-name {
                display: flex;
                align-items: start;
                margin-bottom: 10px;

                .img-wrapper {
                    max-width: 22px;
                    max-height: 22px;
                    width: 22px;
                    height: 22px;
                    margin-right: 10px;
                    display: flex;
                    text-align: center;

                    img {
                        max-width: 22px;
                        max-height: 22px;
                    }
                }

                h2 {
                    font-size: 18px;
                }
            }

            .categories-wrap {
                .categories {
                    list-style: none;
                    padding-left: 32px;

                    .category {
                       a {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: $text-color;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                       } 
                    }
                }

                // Boutons abandonnés mais conservation de ce css si le sujet revient à l'actualité
                // .more-less-wrapper {
                //     button {
                //         border: thin solid $input-border;
                //         border-radius: 25px;
                //         width: calc(100% - 32px);
                //         margin-left: 32px;
                //         font-size: 14px;
                //         font-weight: 500;
                //         background-color: $white;
                //         transition: background-color .4s ease-in-out;

                //         &:hover {
                //             background-color: rgba($white, 0.75);
                //         }
                //     }
                // }
            }
        }
    }
}

.catalogs-feed-home {
    position: relative;
    margin-bottom: 75px;
    min-width: 170px;

    .download-wrapper {
        position: relative;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &:hover {
            figure {
                .contentwrapper {
                    img {
                        transform: scale(1.05); 
                    }
                }
            } 
        }

        figure {
            padding-top: 0% !important;
            min-height: 241px;

            @include media-breakpoint-down(md) {
                display: flex;
                justify-content: center;
            }

            .contentwrapper {
                position: static;
                
                img {
                    position: relative;
                    left: 0% !important;
                    max-width: 170px;
                    min-width: 170px;
                    border-radius: 6px;
                    transition: all .4s ease-in-out;

                    @media screen and (max-width: 414px) {
                        max-width: 190px;
                        min-width: 190px;
                    }
                }
            }
        }

        .info-download {
            position: relative;
            top: 10px;
            text-decoration: none;

            .name-file {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: $text-color;

                &:hover {
                    text-decoration: underline;
                }
            }

            .size-file {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                color: $text-light-gray;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
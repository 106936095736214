.quote {
    &__intro {
        padding-left: 3rem;

        &::before {
            display: none;
        }
    }
    &__wrapper {
        background-color: rgba($white, 0.5) !important;
        border: none;
        border-left: 3px solid $primary-color;

        &.left {
            .quote {
                &__imagewrapper {
                    figure {
                        .contentwrapper {
                            img {
                                @include media-breakpoint-down(md) {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .quote {
            &__textwrapper {
                box-shadow: none;

                .quote {
                    &__title {
                        font-size: 24px;
                        font-weight: 300;
                        font-style: italic;
                    }

                    &__description {

                        &::after {
                            display: none;
                        }

                        p {
                            color: $text-color;
                            font-weight: 500;
                        }
                    }
                }

                .btn {
                    &__moreInfo {
                        background-color: $primary-color;
                        border-radius: 50px;
                        max-width: max-content;
                        padding: 12px 16px;
                        transition: all .2s ease-in-out;

                        &:hover {
                            background-color: $primary-color-hover;
                        }

                        span {
                            color: $white;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 24px;
                        }
                    }
                }
            }

            &__imagewrapper {
                figure {
                    .contentwrapper {
                        img {
                            transition: transform .4s ease-in-out;
                            &:hover {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
        }
    }
}
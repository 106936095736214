.quick-order {
    position: relative;

    .modal {
        background-color: rgba(0, 0, 0, 0.3);

        .modal-dialog {
            @media screen and (min-width: 1400px) {
                top: 80px;
            }
            @include media-breakpoint-up(md) {
                max-width: 930px;
            }

            .modal-content {
                .modal-header {
                    h2 {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }

                .modal-body {
                    .forms-wrapper {
                        .form-wrapper {
                            position: relative;
                            width: calc(98% / 2);

                            &:first-of-type {
                                padding-right: 15px;
                            }

                            &:last-of-type {
                                padding-left: 15px;
                            }

                            h3 {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                margin-bottom: 15px;
                            }

                            .instruction {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                color: $text-light-gray;
                            }

                            form {
                                input[type="text"],
                                .input-group {
                                    height: 48px;
                                }

                                input[type="text"] {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 24px;
                                }

                                .input-group {
                                    justify-content: end;

                                    .subst-prod-quick-order {
                                        height: 48px;
                                        padding-left: 0.75rem;
                                        padding-right: 1rem;
                                        border-radius: 25px 0 0 25px;
                                        border-left: 1px solid $input-border;
                                        border-top: 1px solid $input-border;
                                        border-bottom: 1px solid $input-border;
                                        display: flex;
                                        justify-content: start;
                                        align-items: center;

                                        &[aria-disabled="true"] {
                                            background-color: $button-color-disabled;
                                        }
                                    }

                                    .add-prod-quick-order {
                                        height: 48px;
                                        padding-left: 1rem;
                                        padding-right: 0.75rem;
                                        border-radius: 0 25px 25px 0;
                                        border-right: 1px solid $input-border;
                                        border-top: 1px solid $input-border;
                                        border-bottom: 1px solid $input-border;
                                        display: flex;
                                        justify-content: end;
                                        align-items: center;

                                        &[aria-disabled="true"] {
                                            background-color: $button-color-disabled;
                                        }
                                    }

                                    .nb-product {
                                        width: 35%;
                                        max-width: 35%;
                                        height: 48px;
                                        padding: .375rem .175rem;
                                        text-align: center;
                                        background-color: $white;
                                        border-top: 1px solid $input-border;
                                        border-bottom: 1px solid $input-border;
                                        border-left: none;
                                        border-right: none;
                                        font-size: 13px;

                                        &[disabled="disabled"] {
                                            background-color: rgba(23,22,29,.09);
                                            color: $text-light-gray;
                                        }

                                        &::-webkit-inner-spin-button,
                                        &::-webkit-outer-spin-button {
                                            -webkit-appearance: none;
                                            opacity: 0;
                                            margin: 0;
                                        }
                                        -moz-appearance: textfield;
                                            /* Firefox */


                                        &:hover,
                                        &:focus {
                                            -moz-appearance: number-input;
                                        }
                                    }
                                }

                                .confirmation-add-shop-list,
                                .wrong-reference {
                                    display: none;
                                    justify-content: flex-start;
                                    align-items: center;
                                    margin-bottom: 15px;

                                    &.visible {
                                        display: flex;
                                    }

                                    p {
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        color: $text-color;

                                    }

                                    img {
                                        width: 19px;
                                        margin-right: 10px;
                                    }
                                }

                                .file-drop-area {
                                    position: relative;
                                    width: 450px;
                                    max-width: 100%;
                                    margin-top: 10px;
                                    padding: 24px 12px;
                                    border: 1px dashed #ddd;
                                    border-radius: 8px;
                                    transition: 0.2s;

                                    &.disabled {
                                        background-color: $input-border;

                                        .user-indication {
                                            .fake-btn {
                                                color: $text-light-gray !important;
                                            }
                                        }
                                    }

                                    &.file-uploaded-in-area {
                                        padding: 12px;
                                        margin-bottom: 15px;
                                        border: 1px solid $input-border;
                                    }

                                    .content-file-area {
                                        position: relative;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;

                                        .icon {
                                            margin-bottom: 5px;

                                            img {
                                                width: 15px;
                                                height: 19px;
                                                max-width: 15px;
                                                max-width: 19px;
                                            }
                                        }

                                        .user-indication {
                                            .file-msg,
                                            .fake-btn {
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 20px;
                                            }

                                            .file-msg {
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                color: $text-color;
                                            }

                                            .fake-btn {
                                                flex-shrink: 0;
                                                color: $primary-color;
                                                text-decoration: underline;
                                            }
                                        }

                                        .limit-size-indication {
                                            margin-top: 8px;

                                            span {
                                                position: relative;
                                                font-size: 12px;
                                                font-weight: 400;
                                                line-height: 16px;
                                                color: $text-light-gray;

                                                &.invalid-file {
                                                    color: $error-color;
                                                    &::before {
                                                        content: url('/img/icons/cancel.svg');
                                                        position: absolute;
                                                        top: -3px;
                                                        left: -25px;
                                                        width: 14px;
                                                        height: 14px;
                                                    }
                                                }
                                            }
                                        }

                                        .uploaded-file {
                                            width: 100%;
                                            display: flex;
                                            justify-content: space-between;

                                            .icon-uploaded {
                                                max-width: 24px;
                                                width: 24px;
                                            }

                                            p {
                                                position: relative;
                                                min-width: 325px;
                                                padding-left: 5px;
                                                padding-top: 5px;
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 24px;

                                                .separator {
                                                    position: relative;
                                                    top: -4px;
                                                }

                                                .size {
                                                    color: $text-light-gray;
                                                    margin-left: 5px;
                                                }
                                            }

                                            #delete-file {
                                                width: 24px;
                                                border: none;
                                                background-color: transparent;

                                                img {
                                                    max-width: 15px;
                                                    width: 15px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .file-input {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    height: 100%;
                                    width: 100%;
                                    cursor: pointer;
                                    opacity: 0;

                                    &:focus {
                                        outline: none;
                                    }

                                    &:disabled {
                                        cursor: default;
                                    }
                                }

                                .user-instructions {
                                    margin: 15px 0 20px;

                                    .instructions-content {
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: $text-light-gray;

                                        b {
                                            font-weight: 600;
                                        }
                                    }

                                    .download-model {
                                        margin-top: 8px;
                                        color: $primary-color;
                                        text-decoration: underline;

                                        a {
                                            color: $primary-color;
                                        }
                                    }
                                }

                                .error-in-ref-csv {
                                    margin-bottom: 15px;

                                    .list-group {
                                        .list-group-item {
                                            border: 1px solid $input-border;

                                            &.invalid-ref-content {
                                                display: none;
                                                max-height: 120px;
                                                overflow-y: scroll;

                                                &.visible {
                                                    display: block;
                                                }

                                                p {
                                                    font-size: 14px;
                                                    font-weight: 400;
                                                    line-height: 24px;
                                                    color: $text-color;
                                                }
                                            }

                                            .msg-ref {
                                                position: relative;
                                                display: flex;
                                                align-items: center;

                                                .icon-msg {
                                                  width: 19px;
                                                  height: 19px;
                                                  margin-right: 5px;
                                                }

                                                .msg-ref-content {
                                                    font-size: 14px;
                                                    font-weight: 400;
                                                    line-height: 24px;
                                                    color: $text-color;
                                                }

                                                .copy-content {
                                                    position: absolute;
                                                    right: 0;


                                                    button {
                                                        padding-left: 0;
                                                        padding-right: 0;
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        line-height: 24px;
                                                        color: $text-light-gray;

                                                        .icon-copy {
                                                            width: 15px;
                                                            margin-right: 5px;
                                                        }

                                                        .arrow {
                                                            width: 11px;
                                                            margin-left: 5px;
                                                            transition: transform 0.4s ease-in-out;

                                                            &.open {
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                button[type="submit"], .submit-like {
                                    width: 100%;
                                    padding: 12px 16px;
                                    border-radius: 25px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 24px;

                                    &.disabled {
                                        background-color: $button-color-disabled;
                                        border-color: transparent;
                                        color: $text-light-gray !important;
                                    }
                                }

                                .upload-again-csv {
                                    text-align: center;

                                    button {
                                        padding-left: 0;
                                        padding-right: 0;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        .or {
                            position: relative;
                            width: 2%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &::before {
                                content: "";
                                display: block;
                                width: 1px;
                                height: 44%;
                                position: absolute;
                                top: 0;
                                background-color: $input-border;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                width: 1px;
                                height: 42%;
                                bottom: 0;
                                background-color: $input-border;
                            }

                            span {
                                color: $text-light-gray;
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 12px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Custom fonts below */

// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


@font-face {
    font-family: 'Inter';
    font-weight: 100;
    font-display: swap;
    src: url('fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 200;
    font-display: swap;
    src: url('fonts/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 300;
    font-display: swap;
    src: url('fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-display: swap;
    src: url('fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-display: swap;
    src: url('fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    font-display: swap;
    src: url('fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    font-display: swap;
    src: url('fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    font-display: swap;
    src: url('fonts/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 900;
    font-display: swap;
    src: url('./custom-fonts/Inter-Black.ttf') format('truetype');
}

// Font Awesome
@import '../node_modules/font-awesome/scss/font-awesome.scss';
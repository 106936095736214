.user-estimates {
    .main-user-estimates {
        padding: 15px;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        @media screen and (max-width: 430px) {
            padding: 15px 5px;
        }

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }
        }

        .search-input-wrapper {
            margin-bottom: 50px;

            form {
                .label-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-color;
                }

                .indication {
                    font-style: italic;
                    color: $text-light-gray;
                }

                .input-group {
                    input {
                        border: none;
                        border-radius: 25px 0 0 25px;
                    }

                    .input-group-append {
                        button {
                            border-radius: 0 25px 25px 0;
                            font-size: 14px;
                            z-index: 1;

                            @media screen and (max-width: 430px) {
                                padding-left: .75rem;
                                padding-right: 1rem;
                            }

                            @media screen and (max-width: 375px) {
                                padding-left: .5rem;
                                padding-right: .75rem;
                            }
                        }
                    }
                }
            }
        }

        .estimates-list-wrapper {
            .estimate-wrapper {
                margin-bottom: 15px;
                padding: 20px 5px;
                min-height: 225px;
                background-color: $white-color-10;
                border: $input-border;
                border-radius: 6px;
                box-shadow: $shadow;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }

                @media screen and (max-width: 430px) {
                    max-width: 100%;
                    margin: auto auto 25px auto;
                } 

                .estimate-information {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                    }

                    .number-estimate {
                        font-size: 600;
                    }
                    
                    .date-estimate {
                        margin-top: 10px;
                    }

                    .total-estimate {
                        margin-top: 3.25rem;
                        
                        @include media-breakpoint-down(sm) {
                            margin-top: 1.25rem;
                        }
                    }
                }

                .estimate-action-wrapper {
                    display: flex;
                    flex-direction: column;

                    .estimate-status {
                        margin-right: 10px;
                        text-align: left;
                        font-weight: 600;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 10px;
                        }
                    }

                    .btn-estimate-wrapper {
                        margin-top: auto;

                        button {
                            width: 100%;
                            padding: 8px 0px;
                            border-radius: 25px;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;

                            &.delete-estimate, 
                            &.download-estimate {
                                border: 1px solid $input-border;
                                margin-bottom: 8px;

                                @include media-breakpoint-down(sm) {
                                    margin-bottom: 15px;
                                }

                                &:hover {
                                    background-color: $background-secondary;
                                }
                            }
                        }
                    }

                }
            }
        }

        // Modal
        .modal {
            background-color: rgba(0, 0, 0, 0.2);
            
            .modal-dialog {
                margin: auto;

                .modal-content {
                    @media screen and (max-width: 500px) {
                        max-width: 100vw;
                    }
                }

                .modal-header {
                    border-bottom: 1px solid $input-border;
                    
                    .modal-title {
                        font-size: 16px;
                        font-weight: 600;
                        color: $text-color;
                    }
                }

                .modal-body {
                    .confirm-delete {
                        margin: 10px 0;
                        color: $text-color;
                        font-size: 16px;
                        line-height: 25px;

                        @media screen and (max-width: 576px) {
                            margin: 0;
                        }
                    }
                }

                .modal-footer {
                    display: block;
                    border-top: none;

                    .buttons-wrapper-footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                            gap: 10px;
                        }
    
                        button {
                            width: 48%;
                            padding: 12px 0px;
                            border-radius: 25px;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
    
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                            }
    
                            &.cancel {
                                border: 1px solid $input-border;
                                
                                &:hover {
                                    background-color: $background-secondary;
                                }
                            }
    
                            &[class^='confirm-delete-devis-'] {
                                &:hover {
                                    background-color: $primary-color-hover;
                                }
                            }
                        }
                    }

                    .confirmation-form-devis-deleted-msg {
                        .done,
                        .fail {
                            display: none;
                            margin-top: 15px;
                            border-radius: 6px;
                            padding: 10px 15px;
                            border: 1px solid $input-border;
                            justify-content: center;
                            align-items: flex-start;
                            box-shadow: $shadow;
                            
                            p {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 24px;
                                color: $text-color;
            
                                @media screen and (max-width:430px) {
                                    margin-left: 15px;
                                }
                            }
            
                            img {
                                width: 25px;
                                margin-right: 8px;

                                @media screen and (max-width:425px) {
                                    margin-top: 5px;
                                    margin-right: 5px;
                                }
                            }
            
                            &.visible {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
}
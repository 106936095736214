[id^='addProductInFavoriteModal'] {

    .modal-dialog {
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }

        .modal-footer {
            border-top: none;
  
            .buttons-wrapper-save {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    gap: 10px;
                }
  
                .cancel,
                .confirm-save-product {
                    width: 48%;
                    padding: 12px 0px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
  
                .cancel {
                    border: 1px solid $input-border;

                    &:hover {
                        background-color: $background-secondary;
                    }
                }
  
                .confirm-save-product {
                    &:hover {
                        background-color: $primary-color-hover;
                    }
                }
            }
        }
    }
}
.filter-product {
    position: relative;
    background-color: $white;
    border: thin solid $input-border;
    box-shadow: $shadow;
    border-radius: 6px;

    .header-filter-product {
        padding: 16px;
        border-bottom: thin solid $input-border;

        @include media-breakpoint-down(md) {
            display: none !important;
        }

        .title {
            font-size: 16px;
            font-weight: 600;
            color: $text-color;
        }

        .reset {
            font-size: 14px;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .item-label {
        position: relative;
        padding: 16px;
        cursor: pointer;

        &::after {
            content: url('assets/img/icons/expand_less.svg');
            width: 11px;
            position: absolute;
            right: 30px;
        }

        &.collapsed {
            border-bottom: thin solid $input-border;

            &::after{
                content: url('assets/img/icons/expand_more.svg');
                width: 11px;
                position: absolute;
                right: 30px;
            }
        }

        span {
            display: inline-block;
            max-width: 235px;
            font-size: 14px;
            font-weight: 500;
            color: $text-color;

            @media screen and (max-width: 1270px) {
                max-width: 225px;
            }

            @media screen and (max-width: 1200px) {
                max-width: 215px;
            }

            @media screen and (max-width: 1150px) {
                max-width: 200px;
            }

            @media screen and (max-width: 1045px) {
                max-width: 180px;
            }

            @media screen and (max-width: 1024px) {
                max-width: 165px;
            }

            @include media-breakpoint-down(md) {
                max-width: 235px;
            }
        }
    }

    .filter-choices {
        padding: 16px;
        border-bottom: thin solid $input-border;

        .form-check {
            &:hover {
                .form-check-input {
                    border: 2px solid $primary-color-hover;
                }
            }
        }

        .form-check-input {
            pointer-events: auto;
            cursor: pointer;
            transform: none;
            margin-left: -20px;
            margin-top: 4px;
        }

        label {
            font-size: 14px;
            font-weight: 500;
            color: $text-color;
            margin-left: 3px;
            cursor: pointer;

            .result-count {
                color: $text-light-gray;
                font-size: 12px;
            }
        }

        .more-less-wrapper {
            .more,
            .less {
                border: thin solid $input-border;
                border-radius: 25px;
                width: 100%;
                font-size: 14px;
                font-weight: 500;

                &:hover {
                    background-color: $background-secondary;
                }
            }
        }

        .range_container {
            display: flex;
            flex-direction: column;
            margin: 20px 0;

            // Jquery Slider range
            .sliders_control {
                min-height: auto !important;
                height: 2px !important;
                margin-bottom: 25px;
                width: 95% !important;
            }

            .ui-slider-handle {
                -webkit-appearance: none;
                pointer-events: all;
                width: 14px !important;
                height: 14px !important;
                background-color: $primary-color !important;
                border: none !important;
                border-radius: 50% !important;
                cursor: pointer;
            }

            .ui-slider-handle:active {
                box-shadow: inset 0 0 3px $primary-color, 0 0 9px $primary-color !important;
                -webkit-box-shadow: inset 0 0 3px $primary-color, 0 0 9px $primary-color !important;
                border: none !important;
                background: $primary-color !important;

            }

            .ui-slider-handle:hover {
                background: $primary-color !important;
                border: none !important;
            }

            .ui-slider-handle:focus {
                outline: none;
            }

            .ui-slider-range {
                -webkit-appearance: none;
                appearance: none;
                height: 2px;
                width: 100%;
                position: absolute;
                background-color: $primary-color;
                pointer-events: none;
            }

            .ui-widget-content {
                border: none !important;
                background-color: $body-secondary !important;
            }

            .ui-slider-handle {
                margin-left: 0 !important;
            }

            .form_control {
                position: relative;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: $text-light-gray;

                &_container {
                    width: 45%;
                    padding: 5px;
                    border: 1px solid $input-border;
                    border-radius: 6px;

                    .input-wrapper {
                        position: relative;

                        label {
                            margin-bottom: 0;
                        }

                        span {
                            position: absolute;
                            top: 6px;
                            right: 2px;
                            color: $text-color;
                        }

                        input[type="number"] {
                            position: relative;
                            width: 70%;
                            color: $text-color;
                            height: 30px;
                            font-size: 14px;
                            border: 2px solid transparent;

                            @include media-breakpoint-down(md) {
                                width: 25%;
                            }

                            @include media-breakpoint-down(md) {
                                width: 35%;
                            }

                            &.no-valid {
                                border-color: $primary-color;
                            }
                        }

                        // .form_control_container__input-number {
                        //     width: 100%;
                        // }

                        input[type="number"]::-webkit-inner-spin-button,
                        input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            opacity: 0;
                            margin: 0;
                        }

                        input[type="number"] {
                            width: 100%;
                            -moz-appearance: textfield;
                            /* Firefox */
                        }

                        input[type="number"]:hover,
                        input[type="number"]:focus {
                            -moz-appearance: number-input;
                        }
                    }
                }
            }
        }

    }

    .mobile-buttons-wrapper {
        padding-top: 15px;

        .btn-wrapper {
            width: 100%;
            padding: 5px 15px;

            .apply-btn,
            .reset-mobile {
                width: 100%;
                padding: 12px 16px;
                height: 48px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }

            .reset-mobile {
                border: 1px solid $primary-color;
                color: $primary-color;
            }
        }
    }
}

.filter-product-mobile {
  .range_container {
    margin: 0 0 20px 0 !important;

    ion-range {
      --bar-background: #86868B;
      --bar-background-active: #DE3A3A;
      --pin-background: #DE3A3A;
      --knob-size: 20px;
    }
  }
}
.dowload-pdf-block {
    .download-label {
        color: $text-color;
        font-weight: 500;
    }

    .download-pdf-wrapper {
        .column-image {
            figure {
                @media screen and (max-width: 430px) {
                    margin-bottom: 5px;
                }
                
                .contentwrapper {
                    img {
                        transition: transform .4s ease-in-out;
                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
        
        .column-content {
            .publication-date {
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: $text-light-gray;

                @media screen and (max-width: 575px) {
                    margin-top: 15px;
                }
            }

            h3 {
                font-size: 1.5rem;
                margin-bottom: 15px;
                font-weight: 600;

                @media screen and (max-width: 575px) {
                    margin-top: 15px;
                }
            }

            .description-file {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-color;
                }
            }

            .btn-download {
                width: max-content;
                padding: 12px 16px;
                justify-content: center;
                border-radius: 50px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;

                @media screen and (max-width: 575px) {
                    width: 100%;
                }
            }
        }
    }
}
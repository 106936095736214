.order-summary {
    margin-bottom: 75px;

    @include media-breakpoint-down(md) {
        margin-bottom: 45px;
    }

    // .breadcrumb-wrapper {
    //     padding-left: 0;
    //     padding-right: 0;
    // }

    &__header {
        h1 {
            font-size: 32px;
            font-weight: 600;
            
            @media screen and (max-width: 576px) {
                font-size: 18px;
                margin-top: 3px;
            }
        }
    }

    &__content {

        @include media-breakpoint-down(md) {
            padding-right: 0 !important;
        }
        
        @media screen and (max-width: 575px) {
            margin-left: 0;
            margin-right: 0;
        }

        .summary-container {
            .products-container {
                padding-bottom: 25px;

                .product-line {
                    border-top: 1px solid $input-border;
                    
                    &:last-of-type {
                        border-bottom: 1px solid $input-border;
                    }
    
                    .picture-product {
                        padding-left: 0;
                        
                        .img-wrapper {
                            width: 112px;
                            height: 112px;
                            max-width: 112px;
                            max-height: 112px;

                            @media screen and (max-width: 345px) {
                                width: 100px;
                                height: 100px;
                                max-width: 100px;
                                max-height: 100px;
                            }
                            
                            img {
                                width: auto;
                                height: 112px;
                                max-width: 112px;
                                max-height: 112px;

                                @media screen and (max-width: 345px) {
                                    width: auto;
                                    height: 100px;
                                    max-width: 100px;
                                    max-height: 100px;
                                }
                            }
                        }
                    }
    
                    .description-product {
                        padding-right: 8px;

                        @media screen and (min-width: 992px) and (max-width: 1125px) {
                            padding-left: 20px;
                        }
    
                        @media screen and (max-width: 412px) {
                            padding-right: 0;
                            padding-left: 15px;
                        }
    
                        @media screen and (max-width: 400px) {
                            padding-left: 25px;
                        }
    
                        @media screen and (max-width: 345px) {
                            padding-left: 30px;
                        }
    
                        .brand-product, 
                        .name-product,
                        .ref-brand,
                        .ref-calvez {
                            font-size: 13px;
                            line-height: 20px;
                        }
                        
                        .brand-product,
                        .ref-brand,
                        .ref-calvez {
                            color: $body-secondary;
                        }
    
                        .brand-product {
                            font-weight: 500;
                        }
    
                        .ref-brand,
                        .ref-calvez {
                            font-weight: 400;
                        }
    
                        .name-product {
                            font-weight: 600;
                            a {
                                color: $text-color;
                            }
                        }
                    }
    
                    .quantity-product {
                        padding-left: 0;
                        padding-right: 0;

                        @include media-breakpoint-down(md) {
                            padding-top: 0 !important;
                        }
    
                        .quantity-wrapper {
                            padding-left: 0;
                            padding-right: 0;

                            .unit-price {
                                display: flex;
                                justify-content: end;
                                align-items: center;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 20px;
                                color: $body-secondary;

                                @media screen and (min-width: 992px) and (max-width: 1048px) {
                                    padding-left: 0;
                                }
                            }
    
                            .nb-quantity {
                                padding-left: 0;
                                padding-right: 0;
                                
                                .quantity {
                                   font-size: 13px;
                                   font-weight: 400;
                                   line-height: 20px;
                                   text-align: right;
                                   color: $text-color;
                                   
                                   @include media-breakpoint-down(md) {
                                        text-align: left;
                                        padding-left: 34%;
                                        margin-bottom: 10px;
                                    }

                                    @media screen and (max-width: 700px) {
                                        padding-left: 34.3%;
                                    }

                                    @media screen and (max-width: 545px) {
                                        padding-left: 34.5%;
                                    }

                                    @media screen and (max-width: 412px) {
                                        padding-left: 37.5%;
                                    }

                                    @media screen and (max-width: 400px) {
                                        padding-left: 40.5%;
                                    }

                                    @media screen and (max-width: 345px) {
                                        padding-left: 43.5%;
                                        margin-top: 10px;
                                    }
                                }
                            }
    
                            .total-one-product {
                                display: flex;
                                justify-content: end;
                                align-items: center;
                                font-size: 13px;
                                font-weight: 600;
                                line-height: 20px;
                                color: $text-color;

                                @media screen and (min-width: 992px) and (max-width: 1048px) {
                                    padding-left: 0;
                                    padding-right: 0;
                                    align-items: start;
                                }

                                @include media-breakpoint-down(md) {
                                    justify-content: start;
                                    padding-left: 34%;
                                }

                                @media screen and (max-width: 700px) {
                                    padding-left: 34.3%;
                                }

                                @media screen and (max-width: 545px) {
                                    padding-left: 34.5%;
                                }

                                @media screen and (max-width: 412px) {
                                    padding-left: 37.5%;
                                }

                                @media screen and (max-width: 400px) {
                                    padding-left: 40.5%;
                                }

                                @media screen and (max-width: 345px) {
                                    padding-left: 43.5%;
                                }
                            }
                        }
    
                        .storage-state {
                            text-align: center;
    
                            p {
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 20px;
                                color: $text-color;
    
                                .color-stock {
                                    display: inline-block;
        
                                    img {
                                        margin-right: 5px;
                                        vertical-align: sub;
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .col-summary {
                border-bottom: 1px solid $input-border;
                padding-bottom: 20px;
    
                &:last-of-type {
                    border-bottom: none;
                }
            }

            .summary-sub-title {
                margin: 15px 0 5px;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }

            .shipping-way {
                .addresses-customer {
                    margin-top: 15px;
                    padding: 16px;
                    border-radius: 6px;
                    border: 1px solid $input-border;
                    background-color: $white;
                    box-shadow: $shadow;

                    .shipping-address,
                    .billing-address {
                        h3 {
                           font-size: 14px;
                           font-weight: 600;
                           line-height: 20px;
                           margin-bottom: 10px; 
                        }

                        .name-company {
                            font-size: 14px;
                            line-height: 20px;
                            color: $text-color;
                        }
        
                        .name-company {
                            margin-bottom: 5px;
                            font-weight: 500;
                        }
        
                        .address-company {
                            margin-bottom: 10px;
                            font-size: 14px;
                            line-height: 20px;
                            color: $body-secondary;
                        }  
                    }

                    .shipping-address {
                        border-right: 1px solid $input-border;

                        @include media-breakpoint-down(sm) {
                            border-right: none;
                            border-bottom: 1px solid $input-border;
                        }
                    }

                    .billing-address {
                        padding-left: 20px;

                        @include media-breakpoint-down(sm) {
                            padding-top: 10px;
                            padding-left: 5px;
                        }
                    }
                }
            }

            .shipping-information {
                table {
                    margin-top: 20px;

                    tr {
                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }

                        &:last-child {
                            td {
                                border-bottom: 1px solid $input-border;
                            }
                        }

                        td {
                            font-size: 14px;
                            line-height: 20px;
                            color: $text-color;

                            &.label-info {
                                padding-left: 0;
                                font-weight: 600;
                            }
    
                            &.content-info {
                                font-weight: 400; 
                            }
                        }
                    }
                }
            }

            .buttons-wrapper {
                display: flex;
                justify-content: space-between;
                padding: 20px 0;

                .back-to-shoplist, 
                .next-step {
                    padding: 12px 16px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .back-to-shoplist {
                    border: 1px solid $input-border;
                }

                .next-step {
                    background-color: $primary-color;
                    color: $white;
                    transition: all 0.4s ease-in-out;
                }
            }
        }

        .shopping-list-wrapper {
            padding-left: 0;
            padding-right: 0;

            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            .total-container {

                @media screen and (max-width: 330px) {
                    padding: 25px 10px;

                    table {
                        td {
                            &.summary-label {
                                max-width: 150px;
                            }
                        }
                    }
                }

                @media screen and (max-width: 280px) {
                    table {
                        td {
                            padding-left: 0;

                            &.summary-label {
                                max-width: 145px;
                            }
                        }
                    }
                }
            }
        }
    }  
}
.breadcrumb-order {
    .breadcrumb-wrapper {
        .step-shop-list,
        .step-shipping,
        .step-summary {
            width: calc(100% / 3);

            .step-bar {
                height: 3px;
                margin-bottom: 5px;
    
                &.bg-disabled {
                    background-color: $disabled-color;
                }
            }

            .step-name {
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                color: $text-color;

                &.disabled {
                    color: $body-secondary;
                }
            }
        }

        .step-summary {
            padding-right: 0;
        }
    }
}
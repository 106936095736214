#viewComparatorModal {
    background-color: rgba(0, 0, 0, 0.2);
    padding-right: 0;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .modal-header {
        .modal-title {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .modal-body {

        .header-body-wrapper {
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            .instruction {
                font-size: 15px;
                font-weight: 500;
                line-height: 22px;
                color: $text-color;
            }

            .empty-comparator {
                span {
                    color: $text-light-gray;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 22px;

                    &::before {
                        content: url('/img/icons/delete.svg');
                        width: 15px;
                        display: inline-block;
                        position: relative;
                        left: -10px;
                        top: 4px;
                    }

                    &:hover {
                        color: $text-color;

                        &::before {
                            content: url('/img/icons/delete_black.svg');
                        }
                    }
                }
            }

        }

        .products-comparator-wrapper {
            border-bottom: thin solid $input-border;
            padding-bottom: 25px;

            .product-comparator {
                position: relative;
                width: 24%;
                min-width: 24%;
                max-width: 24%;
                padding: 15px;

                &:not(:first-of-type) {
                    border-left: thin solid $input-border;
                }

                .delete-of-comparator {
                    position: absolute;
                    right: 15px;
                    top: 15px;

                    .delete-btn {
                        padding: 0.5rem;
                        border: thin solid $input-border;

                        span {
                            display: block;
                            width: 16px;
                            height: 19px;
                            background-image: url('/img/icons/delete.svg');
                            background-repeat: no-repeat;
                            background-position: 50%;

                            &:hover {
                                background-image: url('/img/icons/delete_black.svg');
                            }
                        }
                    }
                }

                .product-photo-wrapper {
                    max-height: 150px;
                    height: 150px;
                    //min-height: 150px;
                    margin-bottom: 15px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    text-align: center;

                    @include media-breakpoint-down(md) {
                        max-height: 100px;
                        height: 100px;
                        min-height: 100px;
                    }

                    img {
                        max-height: 100%;
                        width: auto;
                    }
                }

                .product-designation {
                    max-height: 38.4px;
                    min-height: 38.4px;
                    height: 38.4px;
                    overflow: hidden;

                    p {
                        font-size: 13px;
                        font-weight: 600;
                        display: -webkit-box;
                        display: -moz-box;
                        display: box;
                        max-height: 33.4px;
                        min-height: 33.4px;
                        height: 33.4px;
                        -webkit-line-clamp: 2;
                        box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        a {
                            color: $text-color;
                        }
                    }
                }

                .product-reference {
                    margin-bottom: 5px;

                    p {
                        font-size: 11px;
                        line-height: 20px;
                        font-weight: 400;
                        color: $text-light-gray;
                    }
                }

                .product-brand {
                    margin-bottom: 5px;
                    max-height: 34.4px;
                    min-height: 34.4px;
                    height: 34.4px;
                    overflow: hidden;

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        color: $text-light-gray;
                        display: -webkit-box;
                        display: -moz-box;
                        display: box;
                        max-height: 31.4px;
                        min-height: 31.4px;
                        height: 31.4px;
                        -webkit-line-clamp: 2;
                        box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .product-price {
                    margin-bottom: 15px;

                    .price {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        color: $text-color;

                        .price-cents {
                            display: inline-block;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 24px;
                            vertical-align: top;
                            margin-top: -3px;
                        }

                        .price-change {
                            font-size: 12px;
                            display: inline-block;
                            vertical-align: top;
                            margin-top: -1px;
                        }
                    }

                    .eco-part {
                        font-size: 11px;
                        line-height: 20px;
                        font-weight: 400;
                        color: $text-light-gray;
                    }
                }

                .shop-actions-wrapper {
                    align-items: center;
                    .quantity {
                        .input-group {
                            flex-wrap: nowrap;

                            .input-group-btn {
                                width: 28%;
                                max-width: 28%;

                                .subst-prod,
                                .add-prod {
                                    background-color: $white;
                                    width: 100%;
                                    height: calc(1.5em + .85rem + 1px);

                                    &:focus {
                                        outline: none;
                                        box-shadow: none;
                                    }

                                    span {
                                        width: 24px;
                                        height: 24px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border: 1px solid transparent;
                                        border-radius: 50%;
                                    }

                                    .glyphicon {
                                        padding-bottom: 1px;
                                    }
                                }

                                .subst-prod {
                                    padding-left: 0.25rem;
                                    padding-right: 0.5rem;
                                    border-radius: 25px 0 0 25px;
                                    border-left: 1px solid $input-border;
                                    border-top: 1px solid $input-border;
                                    border-bottom: 1px solid $input-border;
                                    display: flex;
                                    justify-content: start;
                                    align-items: center;
                                }

                                .add-prod {
                                    padding-left: 0.5rem;
                                    padding-right: 0.25rem;
                                    border-radius: 0 25px 25px 0;
                                    border-right: 1px solid $input-border;
                                    border-top: 1px solid $input-border;
                                    border-bottom: 1px solid $input-border;
                                    display: flex;
                                    justify-content: end;
                                    align-items: center;

                                    .glyphicon-plus {
                                        &.added-in-shopping-card {
                                            background-color: $primary-color;
                                            color: $white;
                                        }
                                    }
                                }
                            }

                            .nb-product {
                                width: 44%;
                                max-width: 44%;
                                height: calc(1.5em + .85rem + 1px);
                                text-align: center;
                                background-color: $white;
                                border-top: 1px solid $input-border;
                                border-bottom: 1px solid $input-border;
                                border-left: none;
                                border-right: none;
                                font-size: 13px;

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }

                                &::-webkit-inner-spin-button,
                                &::-webkit-outer-spin-button {
                                    -webkit-appearance: none;
                                    opacity: 0;
                                    margin: 0;
                                }
                                -moz-appearance: textfield;
                                    /* Firefox */


                                &:hover,
                                &:focus {
                                    -moz-appearance: number-input;
                                }
                            }
                        }
                    }

                    .add-shop {
                        a {
                            display: block;
                            height: calc(1.5em + .85rem + 5px);
                            max-height: calc(1.5em + .85rem + 5px);
                            min-height: calc(1.5em + .85rem + 5px);
                            background-image: url('/img/icons/add-shop-list.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: center right;
                            cursor: pointer;
                            transition: all .4s ease-in-out;

                            &:hover {
                                background-image: url('/img/icons/add-shop-list-hover.svg');
                            }
                        }
                    }
                }

                .product-availibility {
                    .stock-state {

                        &.active {
                            cursor: pointer;
                        }

                        .color-stock {
                            width: 14px;
                            height: 14px;
                            max-width: 14px;
                            max-height: 14px;
                            min-width: 14px;
                            min-height: 14px;

                            img {
                                width: 14px;
                                height: 14px;
                                max-width: 14px;
                                max-height: 14px;
                                min-width: 14px;
                                min-height: 14px;
                            }
                        }

                        .item-stock {
                            font-size: 12px;
                            font-weight: 500;
                            color: $text-color;
                        }

                        .tooltip-stock {
                            img {
                                width: 15px;
                                height: 15px;
                                max-width: 15px;
                                max-height: 15px;
                                min-width: 15px;
                                min-height: 15px;
                            }
                        }
                    }

                    .tooltip-custom {
                        box-shadow: 1px 1px 4px 0px #17172F2B;
                        position: absolute;
                        z-index: 2;
                        //top: 30px;
                        left: 5px;
                    }
                }
            }
        }

        .products-details-wrapper {
            height: 250px;
            max-height: 250px;
            margin-bottom: 50px;
            border-bottom: thin solid $input-border;
            overflow-y: scroll;

            .line-detail-header {
                text-align: center;
                padding: 12px 0;
                text-transform: uppercase;
                background-color: $background-secondary;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-color;
                }
            }

            .line-detail-body {

                .detail-prod {
                    width: 24%;
                    min-width: 24%;
                    max-width: 24%;
                    text-align: center;
                    padding: 12px 0;

                    &:not(:first-of-type) {
                        border-left: thin solid $input-border;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: $text-color;
                    }
                }
            }
        }
    }
}
.univers-menu {
    position: relative;


    &.closed {
        display: none;
    }

    .close-btn {
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: $white;
        border-radius: 50%;
        z-index: 999;
        top: 0px;
        right: 850px;
        transition: right 0.4s ease-in-out;
        cursor: pointer;
        
        &.open-submenu-cat {
            right: 600px;
        }
    }
    
    .nav-container {
        position: relative;
        display: flex;
        width: 330px;
        padding: 15px;
        background-color: $white;
        min-height: 620px;
        max-height: 620px;
        font-size: 13px;
        font-weight: 500;
        border-radius: 6px;
        transition: width 0.4s ease-in-out;
        z-index: 999;
    
        &.open-submenu-cat {
            width: 618px;
        }
    
        .nav-univers {
            position: relative;
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 2;
            
    
            .main-item {
                display: flex;
                position: relative;
                min-width: 50%;
                padding-top: 1px;
                padding-bottom: 1px;
    
                .sub-family {
                    min-width: 290px;
                    max-width: 290px;
                    padding: 6px 0px;
                    border-radius: 6px;
                    display: inline-flex;

                    .img-wrapper {
                        max-width: 22px;
                        max-height: 22px;
                        width: 22px;
                        height: 22px;
                        text-align: center;
                        margin-right: 10px;
                        margin-left: 5px;

                        img {
                            max-width: 22px;
                            max-height: 22px;
                        }
                    }
    
                    &:hover,
                    &.active-sub-family {
                        background-color: $background-secondary;
                        text-decoration: none;
                    }

                    @for $i from 1 through 18 {

                        &.sub-family-#{$i}{
                            &::before {
                                content: url('assets/img/univers/ico-univers-#{$i}.svg');
                                position: absolute;
                                left: 5px;
                                top: 9px;
                            }
                        }
                    }
    
                    span {
                        display: inline-block;
                        max-width: 235px;
                        color: $text-color;
                    }
                }
    
                &.has-children {

                    &::after {
                        content: "\f054";
                        font-family: "FontAwesome";
                        font-size: 10px;
                        position: relative;
                        top: 12px;
                        right: 18px;
                        color: $body-secondary;
                    } 
                }
            } 
        }

        .submenu {
            position: relative;
            display: none;
            flex: 1;
            flex-direction: column;
            padding: 10px 12px;
            z-index: 1;
            opacity: 0;
            transition: all 0.4s ease-in-out;
            z-index: 1;
            
            &.open-submenu-cat {
                display: flex;
                opacity: 1;
            }

            .is-children {
                display: none;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;

                &.open-children {
                    display: block;
                    opacity: 1;
                }

                li {
                    padding: 0 10px 10px 0;
                    
                    a {
                        &:hover {
                            text-decoration: underline solid $body-secondary;
                        }

                        span {
                            display: block;
                            min-width: 250px;
                            max-width: 250px;
                            color: $text-color;
                        }
                    }
                }
            }
        }
    }
}
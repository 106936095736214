.card-block {
    .card {
        border: 1px solid $input-border;
        border-radius: 6px !important;
        box-shadow: $shadow;

        figure {
            .contentwrapper {
                img {
                    transition: transform .4s ease-in-out;

                    &:hover {
                        transform: scale(1.025);
                    }
                }
            }
        }

        .card-body {
            .card-text {
                p {
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 400;
                    color: $text-color;
                }
            }

            a.btn {
                padding: 12px 16px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                border-radius: 50px;
            }
        }
    }
}
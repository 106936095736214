.login-both {
    position: relative;

    .breadcrumb-back {
        .breadcrumb-item {
            &.come-back {
                a {
                    color: $text-color;

                    &::before {
                        content: url("assets/img/icons/arrow_back_dark.svg");
                        top: 3px;
                    }
                }
            }
        }
    }

    .container {
        padding-top: 35px;

        @include media-breakpoint-down(md) {
            padding-top: 10px;
        }

        .connection-description,
        .block-connection-wrapper {
            margin: auto;
        }
    
        .logo {
            margin-bottom: 10px;
            text-align: center;
        }
    
        .connection-description {
            max-width: 560px;
    
            h1 {
               font-size: 24px;
               font-weight: 600;
               line-height: 32px;
               text-align: center;
               
               @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 24px;
               }
            }
        }

        .block-connection-wrapper {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 125px;
            margin-top: 50px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 50px;
            }

            .block-connection {
                width: 365px;
                max-width: 365px;
                padding: 25px;
                background-color: $white;
                border: 1px solid $input-border;
                border-radius: 6px;
                box-shadow: $shadow;

                @include media-breakpoint-down(sm) {
                    max-width: 80%;
                    width: 80%;
                }

                @media screen and (max-width: 576px) {
                    max-width: 100%;
                    width: 100%;
                }

                @media screen and (max-width: 390px) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 24px;
                }

                form {
                    margin-top: 15px;

                    .label-title {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        color: $text-color;
                    }

                    input[type="text"],
                    input[type="email"],
                    input[type="password"] {
                        box-shadow: $shadow;
                        height: 48px;

                        &.has-error {
                            border: 2px solid $input-border-error;
                        }
                    }

                    .identifiant-group {
                        position: relative;
                        
                        #infoIdUser {
                            position: absolute;
                            top: 45px;
                            right: 12px;
                            cursor: pointer;
                        }

                        #detailInfoIdUser {
                            display: none;
                            position: absolute;
                            z-index: 1;
                            visibility: hidden;
                            font-size: 14px;
                            font-weight: 500;
                            border: 1px solid $input-border;
                            box-shadow: $shadow;
                            transition: visibility .4s ease-in-out;

                            &.visible {
                                display: block;
                            }
                        }
                    }

                    .pwd-wrapper {
                        position: relative;

                        #visible-pwd {
                            position: absolute;
                            top: 25%;
                            right: 3%;
                            cursor: pointer;
                        }
                    }

                    .wrapper {
                        justify-content: space-between;
                        align-items: baseline;
                        gap: 2px;

                        @media screen and (max-width: 414px) {
                            align-items: center;
                            gap: 3px;
                        }

                        @media screen and (max-width: 280px) {
                            gap: 5px;
                        }

                        .remember-user {
                            margin-left: 5px;

                            @media screen and (max-width: 390px) {
                                margin-left: 2px;
                            }
                        }

                        .forgotten-pwd {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                        }
                    }

                    input[type="submit"]{
                        margin-top: 20px;
                        width: 100%;
                        height: 48px;
                        border-radius: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 24px;
                    }

                    .msg-error {
                        display: none;
                        visibility: hidden;
                        margin-top: 10px;
                        padding: 10px;
                        color: $primary-color;
                        background-color: $primary-color-10;
                        border: 1px solid $input-border-error;
                        border-radius: 6px;
                        font-size: 14px;
                        line-height: 22px;

                        &.visible {
                            display: block;
                        }
                    }
                }

                .description,
                .create-account-way p a {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-light-gray;
                }

                .create-account-way {
                    position: relative;
                    padding: 15px;
                    border-radius: 6px;
                    background-color: $background-secondary;
                    box-shadow: $shadow;
                    margin-bottom: 15px;
                    transition: background-color .4s ease-in-out;

                    &::after {
                        content: url('/img/icons/chevron_right_dark.svg');
                        position: absolute;
                        top: 40%;
                        right: 8%;
                    }

                    &:hover {
                        background-color: $background-secondary-80;

                        &::after {
                            content: url('/img/icons/chevron_right_red.svg');
                        }
                    }

                    h3 {
                        margin-bottom: 5px;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;

                        a, a:hover, a:active {
                            text-decoration: none;
                            color: $text-color;
                        }
                    }

                    p {
                        max-width: 80%;

                        a, a:hover, a:active {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
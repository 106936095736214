.accordion-product {

    .tab-content {
        padding: 20px 0;
        font-size: 14px;
        line-height: 20px;
        color: $text-color;

        h2, h3 {
            font-size: 14px;
        }

        .description {

            &__title {
                font-weight: 600;
            }

            &__name {
                font-weight: 500;
            }

            &__content {
                font-weight: 400;
                line-height: 20px;
            }
        }

        .technical-charac {

            &__table {
                tr {
                    &:first-of-type {
                        th, td {
                            border-top: none;
                        }
                    }

                    th {
                        min-width: 240px;
                        max-width: 240px;

                        @media screen and (max-width: 576px) {
                            min-width: 175px;
                            max-width: 175px;
                        }
                    }
                }

            }
        }

        .more-charac {
            width: 100%;
            border: thin solid #17161D1F;
            border-radius: 25px;
            padding: 12px 16px;
            font-size: 14px;
        }

        .technical-data {
            &__title {
                font-weight: 600;
            }
        }

        .other-documents {
            &__title {
                font-weight: 600;
            }
        }

        .documents-list {
            font-weight: 400;

            li {
                border: 1px solid $input-border;
                
                &:hover {
                    background-color: rgba($white, 0.7);
                }

                a {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .doc-name,
            .doc-type {
                &::after {
                    content: ".";
                    margin: 0 5px;
                    font-weight: bold;
                    position: relative;
                    top: -3px;
                    color: $text-light-gray;
                }
            }

            .doc-type,
            .doc-size {
                color: $text-light-gray;
            }

            img {
                cursor: pointer;
            }
        }
    }
}
.checkout-shipping {
    margin-bottom: 75px;

    @include media-breakpoint-down(md) {
        margin-bottom: 45px;
    }

    &__header {
        h1 {
            font-size: 32px;
            font-weight: 600;

            @media screen and (max-width: 576px) {
                font-size: 18px;
            }
        }
    }

    &__content {

        @include media-breakpoint-down(md) {
            padding-right: 0 !important;
        }

        .col-address {
            border-bottom: 1px solid $input-border;
            padding-bottom: 20px;

            &:last-of-type {
                border-bottom: none;
            }
        }

        .shipping-sub-title {
            margin: 15px 0 5px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }

        .indication-sub-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $body-secondary;
        }

        .shipping-mode {
                padding-right: 0;
                padding-left: 0;

                @include media-breakpoint-down(md) {
                    padding-left: 7.5px;
                }

            form {
                margin-top: 15px;
                gap: 15px;

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                    padding-left: 0;
                    gap: 7px;
                }
    
                .form-check {
                    padding: 16px;
                    background-color: $white;
                    border-radius: 6px;
                    box-shadow: $shadow;
                    border: 1px solid $input-border;
                    transition: border 0.2s ease-in-out;
                    cursor: pointer;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 0;
                    }
    
                    &.selected {
                        border: 2px solid $primary-color;
                    }

                    &:hover {
                        border: 2px solid $primary-color;
                    }
    
                    .label-wrapper {
                        label {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            color: $text-color;
                            margin-bottom: 5px;
                        }
    
                        .label-description {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            color: $body-secondary;
                        }
                    }
    
                    .radio-wrapper {
                        padding-left: 25px;
                    }
                }
            }
        }

        .shipping-address {
            margin-top: 15px;
            background-color: $white;
            padding: 16px;
            border: 1px solid $input-border;
            border-radius: 6px;
            box-shadow: $shadow;

            .customer-address {
                .current-address,
                .name-company {
                    font-size: 14px;
                    line-height: 20px;
                    color: $text-color;
                }
                
                .current-address {
                    margin-bottom: 15px;
                    font-weight: 600;
                }

                .name-company {
                    margin-bottom: 5px;
                    font-weight: 500;
                }

                .address-company {
                    margin-bottom: 10px;
                    font-size: 14px;
                    line-height: 20px;
                    color: $body-secondary;
                }

                .choose-another-address-btn {
                    padding: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-decoration: underline;
                    color: $text-color;
                }

                .modal:nth-of-type(even) {
                    z-index: 1052 !important;
                }
                .modal-backdrop.show:nth-of-type(even) {
                    z-index: 1051 !important;
                }

                .modal {

                    &.modal-address {
                        background-color: rgba(0, 0, 0, 0.2);
                        
                        .modal-dialog {
                            &.modal-xl {
                                @media (min-width: 1200px) {
                                    max-width: 850px;
                                }
                            }
                            .modal-content {
                                .modal-header {
                                    border-bottom: 1px solid $input-border;
                                    .modal-title {
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: $text-color;
                                    }
                                }
    
                                .modal-body {

                                    .label-title {
                                        margin-bottom: 10px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 20px;
                                        color: $text-color;
                                    }

                                    select,
                                    input[type="text"],
                                    input[type="tel"] {
                                        height: 48px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        border: 1px solid $input-border;
                                        border-radius: 8px;
                                    }

                                    .choose-address {
                                        position: relative;
                                        margin: 25px 0;
                                        font-size: 10px;
                                        font-weight: 500;
                                        line-height: 12px;
                                        color: $body-secondary;
                                        text-transform: uppercase;

                                        &::before {
                                            content: "";
                                            display: block;
                                            height: 1px;
                                            width: 47%;
                                            background-color: $input-border;
                                            position: absolute;
                                            top: 6px;
                                        }

                                        &::after {
                                            content: "";
                                            display: block;
                                            height: 1px;
                                            width: 47%;
                                            background-color: $input-border;
                                            position: absolute;
                                            right: 0;
                                            top: 6px;
                                        }
                                    }
                                    
                                    
                                    .new-address {
                                        padding: 12px 16px;
                                        width: 100%;
                                        border: 1px solid $input-border;
                                        border-radius: 25px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 24px;
                                    }
                                   
                                }
    
                                .modal-footer {
                                    justify-content: space-between;
                                    border-top: 1px solid $input-border;
    
                                    button {
                                        width: 48%;
                                        padding: 12px 0px;
                                        border-radius: 25px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        text-align: center;

                                        @include media-breakpoint-down(sm) {
                                            width: 100%;
                                        }
    
                                        &.cancel {
                                            border: 1px solid $input-border;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // CSS modal todo
                
            }

            .choice {
                @include media-breakpoint-down(sm) {
                    padding: 25px 0 25px 7.5px;
                    justify-content: start !important;
                }

                span {
                    position: relative;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 12px;
                    color: $body-secondary;
                    text-transform: uppercase;

                    &::before {
                        content: "";
                        display: block;
                        width: 58px;
                        height: 1px;
                        background-color: $input-border;
                        position: absolute;
                        transform: rotate(90deg) translateY(21px);
                        top: -40px;

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }

                    &::after {
                        content: "";
                        display: block;
                        width: 58px;
                        height: 1px;
                        background-color: $input-border;
                        position: absolute;
                        transform: rotate(90deg) translateY(21px);
                        top: 50px;

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                }
            }

            .calvez-office {
                form {
                    .office-address {
                        margin-bottom: 15px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: $text-color;
                    }

                    select {
                        height: 48px;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        border: 1px solid $input-border;
                        border-radius: 8px;
                    }

                }
            }

        }

        .shipping-informations {
            padding-right: 0;

            form {
                margin-top: 10px;
                padding-left: 0;
                padding-right: 0;

                .label-title {
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $text-color;
                }

                input[type="text"],
                textarea {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    border: 1px solid $input-border;
                    border-radius: 8px;
                }
                
                input[type="text"] {
                    height: 48px;
                }
            }
        }

        .billing-address {
            margin-top: 15px;
            background-color: $white;
            padding: 16px;
            border: 1px solid $input-border;
            border-radius: 6px;
            box-shadow: $shadow;

            .name-company {
                font-size: 14px;
                line-height: 20px;
                color: $text-color;
                margin-bottom: 5px;
                font-weight: 500;
            }

            .address-company {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 20px;
                color: $body-secondary;
            }
        }

        .buttons-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;

            .back-to-shoplist, 
            .next-step {
                padding: 12px 16px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }

            .back-to-shoplist {
                border: 1px solid $input-border;
            }

            .next-step {
                background-color: $primary-color;
                color: $white;
                transition: all 0.4s ease-in-out;

                &.disabled {
                    background-color: $button-color-disabled;
                    color: $body-secondary;
                }
            }
        }

        .shopping-list-wrapper {
            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
.autoblock-horizontal {
    .row {
        @media screen and (max-width: 575px) {
            border-color: transparent !important;

            .autoblock-item {
                border-bottom: 1px solid $input-border;
            }

            &:last-of-type {
                .autoblock-item {
                    &:last-of-type {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .image-text-horizontal {
        @media screen and (max-width: 575px) {
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
        }

        figure {
            @media screen and (max-width: 575px) {
                margin-bottom: 1.25rem;
            }
        }

        h3 {
            a {
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                color: $text-color;
                transition: color .2s ease-in-out;

                &:hover {
                    color: $primary-color;
                    text-decoration: none;
                }
            }
        }

        .metadata-wrapper {
            position: relative;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            color: $text-color;
        }

        .abstract {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: $text-color;

            // @include media-breakpoint-down(sm) {
            //     margin-top: 1rem;
            // }
        }
    }

    .text-image-vertical {
        .col-md-8 {
            h3 {
                a {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 26px;
                    color: $text-color;
                    transition: color .2s ease-in-out;
    
                    &:hover {
                        color: $primary-color;
                        text-decoration: none;
                    }
                }
            }

            .abstract {
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: $text-color;
    
                @include media-breakpoint-down(sm) {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }

            .metadata-wrapper {
                position: relative;
                margin-top: 0;
                margin-right: 5px;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: $text-color;

                @media screen and (max-width: 575px) {
                    margin-top: 10px;
                }
            }

            .published-at-and-tag-wrap {
                .metadata-wrapper {
                    position: relative;
                    //margin-top: 0;
                    //margin-right: 5px;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-color;
                }
            }
        }
    }

    .image-text-vertical {

        h3 {
            a {
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                color: $text-color;
                transition: color .2s ease-in-out;

                &:hover {
                    color: $primary-color;
                    text-decoration: none;
                }
            }
        }

        .abstract {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: $text-color;

            @include media-breakpoint-down(sm) {
                margin-top: 1rem;
            }
        }

        .published-at-and-tag-wrap {

            @media screen and (max-width: 575px) {
                margin: 10px 0;
            }

            .metadata-wrapper {
                position: relative;
                margin-top: 0;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: $text-color;

                @media screen and (max-width: 575px) {
                    margin-top: 10px;
                }
            }
        }

        .tag {
            position: relative;
            font-size: 13px;
            font-weight: 600;
            line-height: 20px;
            color: $text-light-gray;
            padding-left: 15px;
            
            @media screen and (max-width: 575px) {
                margin-top: 10px;
            }

            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: $text-color;
                border-radius: 50%;
                left: 4px;
                top: 8px;
            }

            a {
                transition: color .2s ease-in-out;
                
                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}
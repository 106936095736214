/* Custom variables below */
$primary-color-hover:                   #C83434;

$body-primary:                          #17161D;
$body-secondary:                        #86868B;

$background-primary:                    #FFFFFF;
$background-secondary:                  #F5F5F7;
$background-secondary-80:               rgba($background-secondary, 0.8);

// TEXT COLORS
$text-color:                            #17161D;
$text-footer:                           $background-secondary; 
$text-light-white:                      rgba($background-secondary, 0.7);
$text-light-gray:                       $body-secondary;

// TYPOGRAPHY
$text-banner-nav:                       13px;
$text-input:                            13px;

// VAR FOR STYLING
$black:                                 #000000;
$white:                                 #ffffff;
$shadow:                                0px 1px 2px 0 #1111220F;
//
$banner-nav-link:                       #F5F5F7;
// 
$input-border:                          #17161D0F;
$light-white-border:                    rgba($background-secondary, 0.3);
$input-border-error:                    rgba(222, 58, 58, 0.2);
//
$font-family-sans-serif:                'Inter', sans-serif;
$font-inter:                            $font-family-sans-serif; 
//
$scrollbar-grey:                        rgba($background-secondary, 0.5);
//
$primary-color-10:                      #DE3A3A1A;
//
$white-color-10:                        rgba($white, 0.5);
$disabled-color:                        #D9D9D9;
//
$success-color:                         #28a745;
$success-bg:                            rgba($success-color, 0.2);
$error-color:                           #FF5253;

// BUTTON
$button-color:                          #DE3A3A;
$button-color-hover:                    #C83434;
$button-color-disabled:                 #17161D1F;

// BADGE
$banner-new:                            #4F76DD;
$banner-destocking:                     #D89533;
$banner-on-sale:                        #DE3A3A;

// BOOTSTRAP VAR
$screen-sm-min:               768px;
$screen-md-min:               992px;
$screen-lg-min:               1200px;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

// FORMS

// GRID SYSTEM
$grid-columns:                12;
$grid-gutter-width:           15px;
//== Container sizes
$container-tablet:             (720px + $grid-gutter-width);
$container-desktop:            (940px + $grid-gutter-width);
$container-large-desktop:      (1140px + $grid-gutter-width);

// Font Awesome
$fa-font-path: "../fonts/";

// CSS3 Animations - animate Less
$animationLessTime: 1s;

// Customized Navbar z-index
$zindex-navbar-fixed: 9989;

$grid-float-breakpoint: $screen-md-min;

$container-max-widths: (
  md: $container-tablet,
  lg: $container-desktop,
  xl: $container-large-desktop,
  xxl : 1400px
);

$container-max-widths: (
  xxl: 1260px
)
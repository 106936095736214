header {
    border-bottom: thin solid $input-border;
    z-index: 3;

    .main-menu {
        z-index: 3;
    }
    .container-header {
        padding: 0 16px;
        z-index: 3;
        background-color: $white;

        .header-wrap {

            .header-left {
                position: initial;
                padding-right: 0;

                // Icone Menu mobile
                .menu-mobile {
                    position: relative;
                    display: none;
                    @include media-breakpoint-down(md) {
                        display: block;
                    }

                    .btn-menu-mobile {
                        cursor: pointer;

                        span {
                            margin-top: 5px;
                            font-weight: 400;
                            font-size: 8px;
                            line-height: 12px;
                            text-transform: uppercase;
                        }
                    }
                }

                // Main menu mobile
                .menu-mobile-content {
                    position: fixed;
                    display: none;
                    top: 0;
                    left: -100vw;
                    width: 100vw;
                    min-width: 100vw;
                    height: 100vh;
                    min-height: 100vh;
                    padding: 30px 25px;
                    background-color: $white;
                    z-index: 10;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    touch-action: pan-y;
                    transition: left 0.4s ease-in-out;

                    &.open {
                        display: block;
                        left: 0;
                    }

                    @include media-breakpoint-down(md) {
                        display: block;
                    }

                    &__header {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;

                        .close-menu-main-mobile {
                            padding: 0;

                            img {
                                height: 15px;
                            }
                        }
                    }

                    &__content {
                        position: relative;
                        min-height: 95%;
                        padding-left: 0;
                        padding-right: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .nav-container {
                            ul {
                                .nav-item {
                                    position: relative;
                                    border-bottom: 1px solid $input-border;

                                    &::after {
                                        content: url('/img/icons/chevron_right.svg');
                                        position: absolute;
                                        right: 10px;
                                        top: 10px;
                                    }

                                    .nav-link {
                                        padding: .5rem 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        color: $text-color;
                                    }
                                }

                                &.user-nav-mobile {
                                    .nav-item {
                                        padding-left: 12px;
                                        padding-right: 12px;
                                        background-color: $background-secondary;

                                        &.no-chevron {
                                            &::after {
                                                display: none;
                                            }
                                        }

                                        #logout-mobile {
                                            color: $primary-color;
                                        }
                                    }
                                }
                            }

                            &.first-part,
                            &.second-part {
                                max-height: 180px;
                                height: 180px;
                            }

                            &.user-btns {
                                .user,
                                .logout {
                                    position: relative;
                                }

                                .user {
                                    &::before {
                                        content: url('/img/account/account_circle.svg');
                                        position: relative;
                                        left: 0;
                                        top: 6px;
                                        margin-right: 5px;
                                    }
                                }

                                .logout {
                                    border-bottom: none;

                                    &::before {
                                        content: url('/img/icons/logout.svg');
                                        position: relative;
                                        left: 0;
                                        top: 6px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                // Menu Univers Mobile
                .menu-univers-mobile-content {
                    position: fixed;
                    display: none;
                    top: 0;
                    right: -100vw;
                    width: 100vw;
                    min-width: 100vw;
                    height: 100vh;
                    min-height: 100vh;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    touch-action: pan-y;
                    padding: 30px 25px;
                    background-color: $white;
                    z-index: 11;
                    transition: right 0.4s ease-in-out;

                    &.open {
                        display: block;
                        right: 0;
                    }

                    @include media-breakpoint-down(md) {
                        display: block;
                    }

                    &__header {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;

                        .close-menu-univers-mobile {
                            padding: 0;

                            img {
                                height: 15px;
                            }
                        }
                    }

                    &__content {
                        position: relative;

                        .title-nav {
                            position: relative;
                            padding: .5rem 0;
                            border-bottom: 1px solid $input-border;

                            &::before {
                                content: url('/img/icons/chevron_left_dark.svg');
                                position: absolute;
                                top: 15px;
                                left: 7px;
                            }

                            button {
                                width: 100%;
                                padding-left: 35px;
                                padding-right: 0;
                                text-align: left;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 24px;

                            }
                        }

                        .nav-container {
                            ul {
                                .nav-item {
                                    position: relative;
                                    border-bottom: 1px solid $input-border;

                                    &::after {
                                        content: url('/img/icons/chevron_right.svg');
                                        position: absolute;
                                        right: 10px;
                                        top: 15px;
                                    }

                                    .nav-link {
                                        padding: .75rem 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        color: $text-color;

                                        .img-wrapper {
                                            display: inline-block;
                                            max-width: 22px;
                                            max-height: 22px;
                                            width: 22px;
                                            height: 22px;
                                            text-align: center;
                                            margin-right: 2px;
                                            margin-left: 5px;

                                            img {
                                                max-height: 22px;
                                            }
                                        }

                                        span {
                                            display: inline-block;
                                            max-width: 255px;
                                            vertical-align: top;
                                        }

                                    }

                                    // @for $i from 1 through 18 {

                                    //     .nav-link-#{$i} + .menu-sub-family-mobile-content {

                                    //         .menu-sub-family-mobile-content__content {
                                    //             .title-nav {
                                    //                 button {
                                    //                     &::before {
                                    //                         content: url('assets/img/univers/ico-univers-#{$i}.svg');
                                    //                         position: relative;
                                    //                         top: 6px;
                                    //                         margin-right: 10px;
                                    //                     }
                                    //                 }
                                    //             }
                                    //         }
                                    //     }
                                    // }

                                    // Menu Sub-family of this univers family
                                    .menu-sub-family-mobile-content {
                                        position: fixed;
                                        //display: none;
                                        top: 0;
                                        right: -200vw;
                                        width: 100vw;
                                        min-width: 100vw;
                                        height: 100vh;
                                        min-height: 100vh;
                                        overflow-y: scroll;
                                        overflow-x: hidden;
                                        touch-action: pan-y;
                                        padding: 30px 25px;
                                        background-color: $white;
                                        z-index: 12;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: display 0.2s, visibility 0.8s, opacity 0.8s, right 1s ease-in-out
                                        ;

                                        &.open {
                                            display: block;
                                            visibility: visible;
                                            right: 0;
                                            opacity: 1;
                                        }

                                        @include media-breakpoint-up(lg) {
                                            display: none;
                                        }

                                        &__header{
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: flex-start;

                                            .close-menu-sub-family-mobile {
                                                padding: 0;

                                                img {
                                                    height: 15px;
                                                }
                                            }
                                        }

                                         // Content of sub-family
                                         &__content {
                                            .title-nav {
                                                //background-color: yellow; // test
                                                &::before {
                                                    content: url('/img/icons/chevron_left_dark.svg');
                                                    position: absolute;
                                                    top: 18px;
                                                    left: 7px;
                                                }

                                                .close-menu-sub-family-mobile {
                                                    padding-left: 25px;
                                                    
                                                    img {
                                                        max-width: 22px;
                                                        max-height: 22px;
                                                        margin-right: 8px;
                                                    }

                                                    span {
                                                        display: inline-block;
                                                        max-width: 250px;
                                                        vertical-align: top;
                                                    }
                                                }
                                            }

                                            .nav-container {
                                                ul {
                                                    .nav-item {
                                                        .nav-link {
                                                            .img-wrapper {
                                                                max-height: 22px;

                                                                @include media-breakpoint-down(md) {
                                                                    display: none;
                                                                }
                                                            }

                                                            span {
                                                                display: inline-block;
                                                                max-width: 250px;
                                                                vertical-align: top;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                .navbar-brand {
                    @include media-breakpoint-down(md) {
                        padding-top: 0rem;
                        padding-bottom: 0.4125rem;
                    }

                    @media screen and (max-width: 375px) {
                        margin-right: 0;
                    }

                    a .logo img {
                        max-width: 165px;
                        max-height: 32px;
                    }
                }
            }

            .header-center {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .header-right {
                .account {
                    .login-user {
                        position: relative;
                        a {
                            color: $text-color;
                            cursor: pointer;

                            &.logged-user {
                                position: relative;
                                background-color: $background-secondary;
                                border-radius: 25px;
                                padding-right: 25px;
                                max-height: 38px;
                                max-width: 180px;

                                @include media-breakpoint-down(md) {
                                    padding-right: 0;
                                }
                            }

                            .icon-account {
                                position: relative;

                                @media screen and (min-width: 992px) and (max-width: 1024px) {
                                    width: 35px;
                                }
                                
                                img {
                                    @media screen and (min-width: 992px) and (max-width: 1024px) {
                                        margin-left: 5px;
                                    }
                                }
                            }

                            p {
                                font-size: 13px;

                                &.username-logged {
                                    margin-right: 8px;
                                    text-align: center;
                                    max-width: 90px;
                                    max-height: 31.2px;
                                    display: -webkit-box;
                                    display: -moz-box; /* Mozilla */
                                    display: box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    -moz-box-orient: vertical;
                                    box-orient: vertical;
                                    overflow: hidden;
                                    text-overflow: ellipsis;

                                    @media screen and (max-width: 1120px) {
                                        max-width: 90px;
                                    }

                                    @media screen and (max-width: 1075px) {
                                        max-width: 80px;
                                    }

                                    @media screen and (min-width: 992px) and (max-width: 1024px) {
                                        margin-right: 5px;
                                    }

                                    &::after {
                                        content: url('/img/icons/expand_more_dark.svg');
                                        position: absolute;
                                        right: 12px;
                                        top: 12px;
                                        //transition: transform .2s ease-in-out;
                                    }

                                    // &.open {
                                    //     &::after {
                                    //         transform: rotate(-180deg);
                                    //     }
                                    // }
                                }

                                @include media-breakpoint-down(md) {
                                    display: none !important;
                                }
                            }
                        }

                        .sub-menu-account {
                            display: none;
                            visibility: hidden;
                            opacity: 0;
                            position: absolute;
                            top: 50px;
                            background-color: $white;
                            z-index: 4;
                            box-shadow: $shadow;
                            border: 1px solid $input-border;
                            border-radius: 6px;
                            font-size: 13px;
                            transition: visibility .2s ease-in-out;

                            @include media-breakpoint-down(md) {
                                left: -10px;
                            }

                            @media screen and (max-width: 430px){
                                left: -15px;
                            }

                            &.open {
                                display: block;
                                visibility: visible;
                                opacity: 1;
                            }

                            .dropdown-item {
                                padding: .75rem 1rem;

                                &:hover {
                                    background-color: $banner-nav-link;
                                }

                                &:not(:first-of-type) {
                                    border-top: 1px solid $input-border;
                                }

                                &.account {
                                    position: relative;
                                    &::before {
                                        content: url('/img/account/account_circle.svg');
                                        position: relative;
                                        left: -2px;
                                        top: 7px;
                                        margin-right: 1px;
                                    }
                                }

                                &.logout {
                                    position: relative;
                                    color: $primary-color;

                                    &::before {
                                        content: url('/img/icons/logout.svg');
                                        position: relative;
                                        left: 0;
                                        top: 4px;
                                        margin-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                .login-user,
                .favorite-list,
                .shop-list {
                    span {
                        display: inline-flex;
                        width: 44px;
                        height: 44px;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .favorite-list {
                    .icon-fav {
                        a {
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            background-image: url('/img/account/favorite.svg');
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            transition: background-image .4s ease-in-out; 

                            &:hover {
                               background-image: url('/img/icons/favorite-filled-red.svg'); 
                            }
                        }
                    }
                }

                .shop-list {
                    .icon-shop {
                        .notification-shop-list {
                            position: absolute;
                            display: block;
                            width: 16px;
                            height: 16px;
                            top: 0px;
                            right: 9px;
                            background-color: $primary-color;
                            border-radius: 50%;
                            color: $white;
                            font-size: 11px;
                            text-align: center;

                            @media screen and (max-width: 575px){
                                right: 6px;
                            }
                        }
                    }
                }
            }
        }

        .sub-header-wrap {
            .searchbar-wrap {
                @include media-breakpoint-down(md) {
                    .input-group-append {
                        display: none;
                    }
                }
            }

            .secondary-menu {
                font-size: 13px;

                @include media-breakpoint-down(md) {
                    display: none !important;
                }

                &__left,
                &__right {
                    .item-univers-menu {
                        position: relative;
                        border-bottom: 3px solid $white;
                        transition: all .8s cubic-bezier(0.4, 0, 0.2, 1);

                        &::after {
                            content:"";
                            position: absolute;
                            right: 0;
                            width: 100%;
                            height: 3px;
                            bottom: -3px;
                            background-color: $white;
                            transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
                        }

                        &:hover,
                        &:active {
                            border-bottom: 3px solid $primary-color;
                            &::after {
                                width: 0%;
                                background-color: $white;
                            }
                        }

                        a {
                            .item-univers-menu-link {
                                font-weight: 500;
                                &:not(.univers) {
                                    color: $text-color;
                                }

                                &.univers {
                                    position: relative;
                                    color: $primary-color;

                                    &::before {
                                        content: url('assets/img/menu/menu-pink.svg');
                                        position: absolute;
                                        left: -28px;
                                        top: -3px;
                                    }
                                }

                                &.comparator {
                                    position: relative;

                                    &::before {
                                        content: url('assets/img/menu/compare_arrows.svg');
                                        position: absolute;
                                        left: -28px;
                                        top: 0px;
                                    }

                                    .notification-comparator {
                                        position: absolute;
                                        display: block;
                                        width: 16px;
                                        height: 16px;
                                        top: -10px;
                                        right: -10px;
                                        background-color: $primary-color;
                                        border-radius: 50%;
                                        color: $white;
                                        font-size: 11px;
                                        text-align: center;
                                    }
                                }

                                &.speed-order {
                                    position: relative;

                                    &::before {
                                        content: url('assets/img/menu/speed-order.svg');
                                        position: absolute;
                                        left: -28px;
                                        top: 0px;
                                    }
                                }
                            }
                        }
                    }
                }

                &__left {
                    .item-univers-menu {
                        &.univers {
                            cursor: pointer;

                            .menu-univers-wrapper {
                                position: absolute;
                                top: 35px;
                                z-index: 9999;

                                &.closed {
                                    display: none;
                                }

                                .univers-menu {
                                    .close-btn {
                                        right: -45px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .article-added {
            display: none;
            position: fixed;
            top: 115px;
            right: 15px;
            padding: 12px;
            background-color: $white;
            border-radius: 6px;
            border: 1px solid $input-border;
            box-shadow: $shadow;
            text-align: center;

            @include media-breakpoint-down(md) {
                right: unset;
                width: 96%;
            }

            &.visible {
                display: block;
            }

            img {
                margin-right: 10px;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: $text-color;
            }
        }

        .favorite-deleted {
            display: none;
            position: fixed;
            top: 115px;
            right: 15px;
            padding: 12px;
            background-color: $white;
            border-radius: 6px;
            border: 1px solid $input-border;
            box-shadow: $shadow;
            text-align: center;

            @include media-breakpoint-down(md) {
                right: unset;
                width: 96%;
            }

            &.visible {
                display: block;
            }

            img {
                margin-right: 10px;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: $primary-color;
            }
        }
    }

    .bg-univers-menu {
        position: fixed;
        width: 100vw;
        min-height: 100%;
        background-color: rgb(0, 0, 0, 0.5);
        z-index: 2;
        opacity: 1;
        transition: opacity 0.4s ease-in-out;

        &.closed {
            display: none;
            opacity: 0;
            z-index: 0;
        }
    }

    .bg-result-searchbar {
        position: fixed;
        width: 101vw;
        min-height: 100%;
        height: 100vh;
        // top: 175px;
        background-color: rgb(0, 0, 0, 0.5);
        z-index: 2;
        opacity: 1;
        transition: opacity 0.4s ease-in-out;

        @include media-breakpoint-down(md) {
            width: 102vw;
        }

        &.closed {
            display: none;
            opacity: 0;
            z-index: 0;
        }
    }
}
.information-block {
    border-radius: 6px;
    box-shadow: $shadow;

    @media screen and (max-width: 430px) {
        padding: 2rem !important;
    }
    
    a, a:hover {
        text-decoration: none;
    }
    
    h2 {
        font-size: 24px;
        font-weight: 600;
        color: $text-color !important;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
        }

        @media screen and (max-width: 430px) {
           margin-top: 2rem !important;
        }
    }

    .information-image {
        transition: transform .4s ease-in-out;

        &:hover {
            transform: scale(1.025);
        }
    }

    .information-description {
        p {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: $text-color; 

            @media screen and (max-width: 430px) {
                font-size: 14px;
            }
        }
    }

    .arrowLink {
        display: inline-block;
        margin-top: 15px;
        background-color: $primary-color;
        padding: 12px 16px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        border-radius: 50px;
        color: $white;
        transition: all .4s ease-in-out;

        &:hover {
            background-color: $primary-color-hover;
        }

        @include media-breakpoint-down(sm) {
            display: block;
            margin: 15px auto 0;
        }
    }
}
// Image push 
figure {
    &.push-image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 120%;
        transition: background-size .4s ease-in-out;
        border-radius: 6px;

        &:hover {
            background-size: 125%;
        }
    }
}
// Manual autoblock
.highlight {
    .row {
        &:not(:last-of-type) {
            .image-text-vertical,
            .text-image-vertical {
                border-bottom: 1px solid $input-border;
            }
        }
    }
}

// Autoblock title
.autoblock-vertical, 
.autoblock-horizontal {
    .autoblock-title {
        line-height: 24px;
    }
}
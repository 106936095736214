.articlelayout {

    .article-title {
        padding-top: 40px;

        @include media-breakpoint-down(sm) {
            padding-top: 10px;
        }

        h1 {
            font-size: 36px;
            font-weight: 600;
            line-height: 38px;
            
            @include media-breakpoint-down(sm) {
                font-size: 24px;
                line-height: 32px;
            }
        }

        .metadata-wrapper {
            line-height: 1.5;
            font-family: $font-inter;

            .metadata-tag {
                color: $body-secondary;
                text-transform: none;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    h2 {
        font-size: 24px;
        font-weight: 600;

        @include media-breakpoint-down(sm) {
            font-size: 22px;
        }
    }

    h3 {
        font-size: 22px;
        font-weight: 600;
        color: $text-light-gray !important;

        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 300;
        text-transform: uppercase;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    .lead {
        color: $text-color;
    }

    .paragraph {
        .rte-text {
            p {
                font-size: 16px;
                line-height: 22px;
                color: $text-color;
            }

            a {
                color: $primary-color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul {
                color: $text-color;
            }
        }
    }
}
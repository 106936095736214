[id^='msg-confirm-product-fav-modal'] {
    top: 15px;

    .modal-dialog {
        margin: auto;

        @media screen and (min-width: 1200px) {
            max-width: 550px;
        }

        .modal-content {
            @media screen and (max-width: 500px) {
                max-width: 100vw;
            }
        }

        .modal-header {
            border-bottom: 1px solid $input-border;

            .modal-title {
                font-size: 16px;
                font-weight: 600;
                color: $text-color;
            }

        }

        .modal-body {
            color: $text-color;

            .confirm-added {
                position: relative;
                margin-bottom: 25px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                text-align: center;

                img {
                    margin-right: 5px;
                }

            }

            .product-information {
                .column-img {

                    @media screen and (max-width: 575px) {
                        margin-bottom: 20px;
                        text-align: center;
                    }

                    .img-wrapper {
                        height: 105px;
                        max-height: 105px;

                        img {
                            width: auto;
                            height: 105px;
                            max-height: 105px;
                        }
                    }
                }

                .column-description {
                    // @media screen and (max-width: 575px) {
                    //     text-align: center;
                    // }
                    p {
                        font-size: 14px;
                        line-height: 20px; 

                        &.product-name {
                            margin-bottom: 10px;
                            font-weight: 600;
                        }
                        
                        &.ref-manufacturer,
                        &.ref-calvez {
                            color: $text-light-gray;
                            font-weight: 400;
                        }
                        
                        &.brand-product { 
                            margin-top: 10px;
                            color: $text-light-gray;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .modal-footer {
            display: block;
            border-top: none;

            .buttons-wrapper-footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    gap: 10px;
                }

                button {
                    width: 48%;
                    padding: 12px 0px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }

                    &.view-all-lists {
                        border: 1px solid $input-border;
                    }

                    &[class^='confirm-save-product'] {
                        &:hover {
                            background-color: $primary-color-hover;
                        }
                    }
                }
            }
        }
    }

}
.searchbar-wrapper {
    .input-group {
        .input-group-text {
            padding: 0.375rem 0.95rem !important;
            border: none;
            background-color: $background-secondary;
            border-radius: 44px 0 0 44px !important;

            &::after {
                font-family: 'FontAwesome';
                content: "\f002";
                position: absolute;
                font-size: 0.9rem;
                color: $body-secondary;
            }
        }

        input[type="text"] {
            border: none;
            background-color: $background-secondary;
            padding: 10px 16px 10px 12px;
            border-radius: 0 44px 44px 0 !important;
            font-size: 13px;

            &::placeholder {
                color: $body-secondary;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:active,
            &:-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0 1000px $background-secondary inset !important;
                box-shadow: 0 0 0 1000px $background-secondary inset !important;
            }

        }
    }
}
.product-overview {
    .card {
        border-radius: 0;
        border: thin solid $input-border;

        .product-img-wrapper {
            position: relative;
            height: 180px;
            max-height: 180px;
            padding: .5rem;
            text-align: center;

            img {
                max-height: 100%;
                max-width: 100%;
            }

            .product-comparator {
                position: absolute;
                top: 1.25rem;
                left: 2.25rem;
                display: flex;
                padding-top: 4px;
                padding-left: 25px;
                padding-right: 10px;
                border-radius: 6px;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                &.hover {
                    background-color: $background-secondary;
                }

                label {
                    display: none;
                    margin-left: -10px;
                    margin-top: 2px;
                    padding-top: 2px;
                    font-size: 12px;
                    font-weight: 500;
                    color: $body-secondary;

                    .compare-selected {
                        display: block !important;
                    }
                }
            }

            .product-img {
                height: 100%;
                max-height: 180px;
                width: auto;
                object-fit: contain;
                object-position: center;
            }

            .product-banner {
                position: absolute;
                bottom: 1.25rem;
                right: 1.25rem;
            }
        }

        .epuise {
            font-size: 13px;
            text-align: center;
        }

        .fds {
            width: 100%;
            text-align: center;
            
            a {
                border: 1px solid $input-border;
                border-radius: 50px;
                padding: 4px;
                font-size: 13px;
                font-weight: 500;
                color: $text-color;
                transition: background-color .4s ease-in-out;

                @media screen and (max-width: 991px) {
                    padding: 12px;
                }
                
                &:hover {
                    background-color: $background-secondary;
                    text-decoration: none;
                }
            } 
        }

        .product {
            padding: .25rem 1.25rem 1.25rem !important;

            .brand-wrap {
                max-height: 38.4px;
                min-height: 38.4px;
                height: 38.4px;
                overflow: hidden;
                line-height: 1.7;
            }

            .name-wrap {
                max-height: 36.4px;
                min-height: 36.4px;
                height: 36.4px;
                overflow: hidden;
            }

            &__brand {
                display: -webkit-box;
                display: -moz-box; /* Mozilla */
                display: box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 11px;
                font-weight: 400;
                color: $text-light-gray;
            }

            &__name {
                display: -webkit-box;
                display: -moz-box; /* Mozilla */
                display: box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                min-height: 33px;
                max-height: 33px;
                font-size: 13px;
                font-weight: 600;
                color: $text-color;
            }

            &__ref {
                font-size: 13px;
                font-weight: 400;
                color: $text-light-gray;
            }

            &__price {
                .price-floor,
                .price-cent,
                .price-change {
                    color: $text-color;
                    font-weight: 700;
                }

                .price-floor {
                    font-size: 18px;
                }

                .price-cent,
                .price-change {
                    font-size: 11px;
                    display: inline-block;
                    vertical-align: top;
                    padding-top: 2px;
                }

                .price-eco-part {
                    font-size: 11px;
                    font-size: 400;
                    color: $text-light-gray;
                }
            }

            &__shop-actions {
                min-height: 50px;

                @media screen and (min-width: 992px) and (max-width: 1033px) {
                    min-height: 70px;
                }

                @media screen and (max-width: 991px) {
                    min-height: 65px;
                }

                @media screen and (max-width: 575.98px) {
                    min-height: auto;
                }

                .quantity {
                    padding-left: 0;
                    padding-right: 5px;

                    .input-group {
                        flex-wrap: nowrap;

                        .input-group-btn {
                            width: 28%;
                            max-width: 28%;

                            .subst-prod,
                            .add-prod {
                                background-color: $white;
                                width: 100%;
                                height: calc(1.5em + .85rem + 1px);

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }

                                span {
                                    width: 24px;
                                    height: 24px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid transparent;
                                    border-radius: 50%;
                                }

                                .glyphicon {
                                    padding-bottom: 1px;
                                }
                            }

                            .subst-prod {
                                padding-left: 0.5rem;
                                padding-right: 0.5rem;
                                border-radius: 25px 0 0 25px;
                                border-left: 1px solid $input-border;
                                border-top: 1px solid $input-border;
                                border-bottom: 1px solid $input-border;
                                display: flex;
                                justify-content: start;
                                align-items: center;
                            }

                            .add-prod {
                                padding-left: 0.5rem;
                                padding-right: 0.5rem;
                                border-radius: 0 25px 25px 0;
                                border-right: 1px solid $input-border;
                                border-top: 1px solid $input-border;
                                border-bottom: 1px solid $input-border;
                                display: flex;
                                justify-content: end;
                                align-items: center;

                                .glyphicon-plus {
                                    &.added-in-shopping-card {
                                        background-color: $primary-color;
                                        color: $white;
                                    }
                                }
                            }
                        }


                        .nb-product {
                            width: 44%;
                            max-width: 44%;
                            height: calc(1.5em + .85rem + 1px);
                            text-align: center;
                            background-color: $white;
                            border-top: 1px solid $input-border;
                            border-bottom: 1px solid $input-border;
                            border-left: none;
                            border-right: none;
                            font-size: 13px;

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }

                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                -webkit-appearance: none;
                                opacity: 0;
                                margin: 0;
                            }
                            -moz-appearance: textfield;
                                /* Firefox */


                            &:hover,
                            &:focus {
                                -moz-appearance: number-input;
                            }
                        }
                    }
                }

                .add-shop-list {
                    background-color: $background-secondary;
                    padding: 4px;
                    width: 100%;

                    p.title {
                        font-size: 12px;
                        color: $text-color;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: center;
                    }

                    a.login {
                        font-size: 12px;
                        text-align: center;
                        background-color: $background-secondary;
                        color: $body-secondary;

                        p.indication {
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }
                }

                .add-shop {
                    position: relative;
                    height: calc(1.5em + .85rem + 5px);
                    max-height: calc(1.5em + .85rem + 5px);
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                    cursor: pointer;
                    transition: all .3s ease-in-out;

                    &.selected a {
                        background-image: url('/img/icons/add-shop-list-hover.svg');
                    }

                    a {
                        display: block;
                        height: calc(1.5em + .85rem + 5px);
                        max-height: calc(1.5em + .85rem + 5px);
                        min-height: calc(1.5em + .85rem + 5px);
                        background-image: url('/img/icons/add-shop-list.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;

                        &:hover {
                            background-image: url('/img/icons/add-shop-list-hover.svg');
                        }

                    }
                }

                .on-command {
                    position: relative;
                    height: calc(1.5em + .85rem + 5px);
                    max-height: calc(1.5em + .85rem + 5px);
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                    cursor: auto;

                    img {
                        height: 100%;
                        max-height: calc(1.5em + .85rem + 5px);
                    }

                }

                .add-fav {
                    padding-left: 0;
                    padding-right: 0;
                    text-align: center;
                    cursor: pointer;

                    &.can-to-be-add-fav {
                        .add-to-fav {
                            transition: all .2s ease-in-out;
                            &:hover {
                                filter: contrast(200%);
                                transform: scale(1.1);
                            }
                        }
                    }

                    &.added-in-fav-list {
                        .favorite-remove {
                            margin: auto;
                        }
                    }
                }
            }

            &__availibility {
                position: relative;

                .stock-state {
                    &.active {
                        cursor: pointer;
                    }

                    .color-stock {
                        width: 14px;
                        height: 14px;
                        max-width: 14px;
                        max-height: 14px;
                        min-width: 14px;
                        min-height: 14px;

                        img {
                            width: 14px;
                            height: 14px;
                            max-width: 14px;
                            max-height: 14px;
                            min-width: 14px;
                            min-height: 14px;
                        }
                    }

                    .item-stock {
                        font-size: 12px;
                        font-weight: 500;
                        color: $text-color;
                    }

                    .tooltip-stock {
                        img {
                            width: 15px;
                            height: 15px;
                            max-width: 15px;
                            max-height: 15px;
                            min-width: 15px;
                            min-height: 15px;
                        }
                    }
                }

                .tooltip-custom {
                    position: absolute;
                    z-index: 2;
                    top: 30px;
                    left: -20px;

                    @include media-breakpoint-down(sm) {
                        top: 30px;
                        left: 10px;
                    }

                    @media screen and (max-width: 575.98px) {
                        left: 25%;
                    }

                    @media screen and (max-width: 360px) {
                        left: 20%;
                    }
                }
            }

            &__invited-user {
                background-color: $background-secondary;
                padding: 8px;
                border-radius: 6px;
                text-align: center;

                span {
                    display: block;
                }

                .invited-user-price {
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $text-color;
                }

                .invited-user-indications {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $text-light-gray;

                    a, a:hover {
                        text-decoration: underline;
                        color: $text-light-gray;
                    }
                }
            }
        }
    }
}
.user-orders {
    //position: relative;

    .main-user-orders {
        padding: 15px;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        @media screen and (max-width: 430px) {
            padding: 15px 5px;
        }

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }
        }

        .search-input-wrapper {
            form {
                .label-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: $text-color;
                }

                .indication {
                    font-style: italic;
                    color: $text-light-gray;
                }

                .input-group {
                    input {
                        border: none;
                        border-radius: 25px 0 0 25px;
                    }

                    .input-group-append {
                        button {
                            border-radius: 0 25px 25px 0;
                            font-size: 14px;

                            @media screen and (max-width: 430px) {
                                padding-left: .75rem;
                                padding-right: 1rem;
                            }

                            @media screen and (max-width: 375px) {
                                padding-left: .5rem;
                                padding-right: .75rem;
                            }
                        }
                    }
                }
            }
        }

        .download-orders-list-wrapper {
            a {
                color: $primary-color;
                text-decoration: underline;
            }
        }

        .orders-list-wrapper {
            .order-wrapper {
                margin-bottom: 15px;
                padding: 20px 5px;
                min-height: 225px;
                background-color: $white-color-10;
                border: $input-border;
                border-radius: 6px;
                box-shadow: $shadow;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }

                @media screen and (max-width: 430px) {
                    max-width: 100%;
                    margin: auto auto 25px auto;
                }

                .order-information {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                    }

                    .number-order {
                        font-weight: 600;
                    }

                    .date-order {
                        margin-top: 10px; 
                    }

                   .total-order {
                    margin-top: 3.25rem;

                        @include media-breakpoint-down(sm) {
                            margin-top: 1.25rem;
                        }
                   } 
                }

                .order-actions-wrapper {
                    display: flex;
                    flex-direction: column;

                    .order-status {
                        margin-right: 10px;
                        text-align: right;
                        font-weight: 600;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 10px;
                        }
                    }

                    .btn-order-wrapper {
                        margin-top: auto;

                        button {
                            width: 100%;
                            padding: 8px 0px;
                            border-radius: 25px;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;

                            &.display-order {
                                border: 1px solid $input-border;
                                margin-bottom: 8px;

                                @include media-breakpoint-down(sm) {
                                    margin-bottom: 15px;
                                }

                                &:hover {
                                    background-color: $background-secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
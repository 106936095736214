.user-order-detail {
    position: absolute;
    top: 0;
    right: -100vw;
    background-color: $background-secondary;
    height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity .15s linear;

    &.open {
        left: 0;
        right: unset;
        opacity: 1;
    }

    .back-to-orders-list {
        button {
            position: relative;
            font-size: 14px;
            color: $primary-color;
            padding-bottom: 15px;
            padding-left: 1.5rem;

            &::before {
                content: url('/img/icons/arrow_back.svg');
                position: absolute;
                left: 0px;
                top: 7px;
            }

            &:hover {
                color: $text-color;
            }
        }
    }

    .main-detail-order {
        .order-wrapper {
            padding-left: 25px !important;
            padding-right: 25px !important;

            @media screen and (max-width: 575px) {
                padding: 20px !important;
            }

            .order-information {
                margin-bottom: 0 !important;
            }
        }

        .recap-delivered-articles,
        .recap-no-delivered-articles {
            margin-top: 25px;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; 
            }

            .product-line-header {
                border-bottom: 1px solid $input-border;
                padding-bottom: 8px;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                div {
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 600;

                    // @media screen and (max-width: 1153px) {
                    //     font-size: 9px;
                    // }

                    &.pu-ht-head,
                    &.quantity-head,
                    &.total-ht-head {
                        text-align: right;
                    }

                    &.quantity-head {
                        @media screen and (min-width: 992px) {
                            padding-left: 0;
                        }
                    }

                    &.articles-head {
                        padding-left: 15.5px;
                    }
                }
            }

            .product-line {

                &:not(:last-of-type) {
                    border-bottom: 1px solid $input-border;

                    @include media-breakpoint-down(md) {
                        border-bottom-width: 2px;
                    }
                }

                .product-line-header-mobile {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $input-border;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;

                    div {
                        &.articles-head {
                            @media screen and (max-width: 991px) {
                                padding-left: 0;
                            } 
                        }

                        &.pu-ht-head {
                            @media screen and (max-width: 991px) {
                                padding-left: 0;
                            } 
                        }

                        &.quantity-head {
                            @media screen and (max-width: 430px) {
                                text-align: center;
                            } 
                        }

                        &.total-ht-head {
                            @media screen and (max-width: 430px) {
                                text-align: right;
                                padding-right: 0;
                            }
                        }
                    }
                }

                .picture-product {
                    @media screen and (max-width: 575px) {
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }

                    .img-wrapper {
                        max-width: 112px;
                        max-height: 112px;
                        width: 112px;
                        height: 112px;
                        text-align: center;

                        @media screen and (max-width: 1024px) {
                            max-width: 100px;
                            max-height: 100px;
                            width: 100px;
                            height: 100px; 
                        }

                        @media screen and (max-width: 575px) {
                            margin: auto;
                            max-width: 150px;
                            max-height: 150px;
                            width: 150px;
                            height: 150px;
                        }

                        img {
                            width: auto;
                            height: 112px;
                            max-height: 112px;

                            @media screen and (max-width: 1024px) {
                                height: 100px;
                                max-height: 100px;
                            }

                            @media screen and (max-width: 575px) {
                                height: 150px;
                                max-height: 150px;
                            }
                        }
                    }
                }

                .description-product {
                    .brand-name {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 500;
                        color: $text-light-gray;
                    }

                    .name-product {
                        a {
                            font-size: 13px;
                            line-height: 20px;
                            font-weight: 600;
                            color: $text-color;
                        }
                    }

                    .ref-brand {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 400;
                        color: $text-light-gray;
                    }
                }

                // .brand-product {
                //     .brand-name {
                //         font-size: 13px;
                //         line-height: 20px;
                //         font-weight: 500;
                //         color: $text-light-gray;

                //         @media screen and (max-width: 430px) {
                //             text-align: right;
                //         }
                //     }
                // }

                .quantity-product {
                    padding-left: 0;
                    padding-right: 0;

                    .quantity-wrapper {
                        @media screen and (max-width: 1024px) {
                            padding-left: 0;
                            padding-right: 0;
                        }

                       .unit-price,
                       .nb-quantity,
                       .total-one-product {
                            p {
                                text-align: right;

                                @include media-breakpoint-down(md) {
                                    text-align: left;
                                }

                                @media screen and (max-width: 350px) {
                                    font-size: 13px;
                                }

                                @media screen and (max-width: 329px) {
                                    font-size: 12px;
                                }
                            }
                        }

                        .unit-price {
                            @include media-breakpoint-up(lg) {
                                justify-content: end;
                                padding-right: 15px;
                            }

                            @media screen and (max-width: 430px) {
                                padding-right: 0;
                            }
                        }
                        
                        .nb-quantity {
                            @include media-breakpoint-up(lg) {
                                padding-right: 0;
                            }

                            @media screen and (max-width: 430px) {
                                .quantity {
                                    text-align: center;
                                }
                            }
                        }

                        .total-one-product {
                            padding-left: 0;
                            padding-right: 0;

                            @include media-breakpoint-down(md) {
                                padding-left: 7.5px;
                            }

                            @media screen and (max-width: 430px) {
                                padding-right: 7.5px;
                                padding-left: 0;

                                .rate-tot-one-product {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }

        .total-amount-order {
            display: flex;
            justify-content: end;
            border-top: 1px solid $input-border;

            table {
                width: 50%;
                margin-top: 1rem;

                @media screen and (max-width: 900px) {
                    width: 60%;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                th, tr, td {
                    padding: 0.5rem;
                }

                .total {
                    font-weight: bold;
                }

                .item {
                    @media screen and (max-width: 991px) {
                        padding-left: 0;
                    }
                }

                .item-value {
                    text-align: right;
                    padding-right: 0;

                    @media screen and (max-width: 430px) {
                        min-width: 100px;
                    }
                }
            }
        }

        .shipping-way {
            border-top: 1px solid $input-border;
            padding: 25px 0;

            p > b {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .address-shipping {
            border-top: 1px solid $input-border;
            padding: 25px 0;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; 
            }

            .address-wrapper {
                display: flex;
                background-color: $white;
                border-radius: 6px;
                
                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                }

                address {
                    width: 50%;
                    padding: 0 15px;
                    margin-top: 1rem;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                    
                    &:first-of-type {
                        border-right: 1px solid $input-border;

                        @include media-breakpoint-down(sm) {
                            padding-bottom: 15px;
                            border-right: none;
                            border-bottom: 1px solid $input-border;
                            margin-bottom: 0;
                        }
                    }
                    
                    h3 {
                        margin-bottom: 10px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 22px;  
                    }

                    .company {
                        text-transform: uppercase;
                    }

                    .street,
                    .city,
                    .country,
                    .phone-nb {
                        color: $text-light-gray;
                        font-weight: 400;
                    }
                }
            }
        }

        .info-shipping {
            border-top: 1px solid $input-border;
            padding: 25px 0;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; 
            }

            table {
                margin-top: 1rem;

                th, tr, td {
                    padding: 0.5rem;
                }

                .item {
                    padding-left: 0;
                    min-width: 50%;
                }
                
                .item-value {
                    @media screen and (max-width: 430px) {
                        min-width: 50%;
                    }
                }
            }
        }
    }
}



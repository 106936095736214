.user-shopping-lists {
    .main-user-shopping-lists {
        padding: 15px;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        @media screen and (max-width: 430px) {
            padding: 15px 5px;
        }

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }
        }
        
        .shopping-list-wrapper {
            .basket-wrapper {
                margin-bottom: 15px;
                padding: 20px 5px;
                //min-height: 225px;
                background-color: $white-color-10;
                border: $input-border;
                border-radius: 6px;
                box-shadow: $shadow;
    
                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }
    
                @media screen and (max-width: 430px) {
                    max-width: 100%;
                    margin: auto auto 25px auto;
                }
                
                .basket-information {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 30px;
                    }

                    .name-basket {
                        font-weight: 600;
                    }

                    .date-basket {
                        margin-top: 10px;
                    }

                    .total-basket {
                        margin-top: 3.25rem;
                        
                        @include media-breakpoint-down(sm) {
                            margin-top: 1.25rem;
                        }
                    }
                }

                .basket-action-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    gap: 5px;

                    .display-basket-btn,
                    .delete-basket-btn,
                    .add-basket-in-shop-list-btn {
                        width: 100%;
                        padding: 8px 0px;
                        border-radius: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                    }
                    
                    .display-basket-btn,
                    .delete-basket-btn {
                        border: 1px solid $input-border;
                        margin-bottom: 8px;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 15px;
                        }

                        &:hover {
                            background-color: $background-secondary;
                        }
                    }

                    .add-basket-in-shop-list-btn {
                        &:hover {
                            background-color: $primary-color-hover;
                        }
                    }
                    
                     // Modal
                    .modal {
                        background-color: rgba(0, 0, 0, 0.2);
                        
                        .modal-dialog {
                            margin: auto;

                            .modal-content {
                                @media screen and (max-width: 500px) {
                                    max-width: 100vw;
                                }
                            }

                            .modal-header {
                                border-bottom: 1px solid $input-border;
                                
                                .modal-title {
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: $text-color;

                                    &[id^='deleteBasketLabel'] {
                                        position: relative;
                                        color: $primary-color !important;
                                        margin-left: 40px;

                                        &::before {
                                            content: url('/img/icons/sensitive_action.svg');
                                            position: absolute;
                                            top: -5px;
                                            left: -45px;
                                            transform: scale(0.7);
                                            -moz-transform: scale(0.7);
                                            -webkit-transform: scale(0.7);
                                        }
                                    }
                                }
                            }

                            .modal-body {
                                .confirm-delete {
                                    margin: 10px 0;
                                    color: $text-color;
                                    font-size: 16px;
                                    line-height: 25px;

                                    @media screen and (max-width: 576px) {
                                        margin: 0;
                                    }
                                }
                            }

                            .modal-footer {
                                display: block;
                                border-top: none;

                                .buttons-wrapper-footer {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    width: 100%;

                                    @include media-breakpoint-down(sm) {
                                        flex-direction: column;
                                        gap: 10px;
                                    }
                
                                    button {
                                        width: 48%;
                                        padding: 12px 0px;
                                        border-radius: 25px;
                                        font-size: 14px;
                                        font-weight: 500;
                                        text-align: center;
                
                                        @include media-breakpoint-down(sm) {
                                            width: 100%;
                                        }
                
                                        &.cancel {
                                            border: 1px solid $input-border;
                                        }
                
                                        &[class^='confirm-delete-basket-'] {
                                            &:hover {
                                                background-color: $primary-color-hover;
                                            }
                                        }
                                    }
                                }

                                .confirmation-form-basket-deleted-msg {
                                    .done,
                                    .fail {
                                        display: none;
                                        margin-top: 15px;
                                        border-radius: 6px;
                                        padding: 10px 15px;
                                        border: 1px solid $input-border;
                                        justify-content: center;
                                        align-items: flex-start;
                                        box-shadow: $shadow;
                                        
                                        p {
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 24px;
                                            color: $text-color;
                        
                                            @media screen and (max-width:430px) {
                                                margin-left: 15px;
                                            }
                                        }
                        
                                        img {
                                            width: 25px;
                                            margin-right: 8px;

                                            @media screen and (max-width:425px) {
                                                margin-top: 5px;
                                                margin-right: 5px;
                                            }
                                        }
                        
                                        &.visible {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
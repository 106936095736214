.block-slider {
    .owl-carousel {
        &.reponsive {
            background-color: transparent;

            @media screen and (max-width: 575px) {
                padding-left: 0rem !important;
                padding-right: 0rem !important;
            }
            
            &.mx-slider {
                &.one-by-one {
                    @media screen and (max-width: 575px) {
                        margin-left: 5% !important;
                        margin-right: 5% !important;
                    }
                }
            }

            .owl-stage-outer {
                padding-bottom: 1rem;

                .slider-item {
                    background-color: #eee;
                    .figure {
                        img {
                            transform: translate3d(-50%,-50%,0) scale(0.95);
                        }
                    }

                    figcaption {
                        display: none;
                    }
                }
            }

            .owl-nav {
                button i {
                    color: $text-light-gray;
                    transition: color .4s ease-in-out;
                    &:hover {
                        color: $text-color;
                    }
                }
            }

            .owl-dots {
                .owl-dot {
                    @media screen and (max-width: 430px) {
                        padding: 3px !important;
                    }

                    @media screen and (max-width: 375px) {
                        padding: 2px !important;
                    }
                }
            }
        }
 
        .paragraph {
            &[data-parameter-name = "title"]{
                display: flex;
                align-items: flex-end;

                .image-picto-wrapper {
                    width: 20px;
                    height: 20px;
                    max-width: 20px;
                    max-height: 20px;
                    margin-right: 3px;

                    @media screen and (max-width: 575.98px) {
                        display: none;
                    }
        
                    .image-picto {
                        height: 16px;
                        width: auto;
                        max-width: 16px;
                        max-height: 16px;
                    }
                }

                p {
                    margin-top: 0;
                    font-family: $font-inter;
                }
            }
        }
    }
}
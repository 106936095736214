.user-informations {
    position: relative;

    .main-informations {
        background-color: $white-color-10;
        border-radius: 6px;
        padding: 15px;
        box-shadow: $shadow;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        @include media-breakpoint-down(md) {
            padding-left: 20px;
            padding-right: 20px;
        }
        
        p {
            line-height: 22px;

            &.company {
                text-transform: uppercase;
            }

            &.customer-fullname {
                .lastname {
                    text-transform: uppercase;
                }
            }
        }

        .buttons-wrapper {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 15px;

            @media screen and (max-width: 430px) {
                align-items: center;
                
                .btn-modify-info,
                .btn-modify-pwd {
                    width: 100%;
                }    
            }

            button:not(.close) {
                padding: 12px 16px;
                border-radius: 25px;
                border: 1px solid $input-border;
                font-weight: 500;
                line-height: 24px;
                font-size: 14px;

                &:hover {
                    background-color: $background-secondary;
                }
            }

            .modal {
                background-color: rgba(0, 0, 0, 0.2);
                
                .modal-dialog {
                    margin: auto;

                    .modal-content {
                        @media screen and (max-width: 500px) {
                            max-width: 100vw;
                        }
                    }

                    .modal-header {
                        border-bottom: 1px solid $input-border;
                        
                        .modal-title {
                            font-size: 16px;
                            font-weight: 600;
                            color: $text-color;
                        }
                    }
    
                    .modal-body {
                        .form-modif-info,
                        .form-modif-pwd {
                            input[type="tel"],
                            input[type="email"],
                            input[type="password"] {
                                &.has-error {
                                    border: 2px solid $primary-color;
                                }
                            }
    
                            .pwd-wrapper {
                                position: relative;
        
                                .see-pwd {
                                    position: absolute;
                                    top: 25%;
                                    right: 2%;
                                    cursor: pointer;
                                }
                            }
    
                            .msg-error {
                                display: none;
                                visibility: hidden;
                                margin-top: 10px;
                                padding: 10px;
                                color: $primary-color;
                                background-color: $primary-color-10;
                                border: 1px solid $input-border-error;
                                border-radius: 6px;
                                font-size: 14px;
            
                                p {
                                    line-height: 22px;
                                }
            
                                &.visible {
                                    display: block;
                                    visibility: visible;
                                }
                            }
                        }
                    }
    
                    .modal-footer {
                        display: block;
                        border-top: none;
    
                        .buttons-wrapper-footer {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;
    
                            @include media-breakpoint-down(sm) {
                                flex-direction: column;
                                gap: 10px;
                            }
        
                            button {
                                width: 48%;
                                padding: 12px 0px;
                                border-radius: 25px;
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
        
                                @include media-breakpoint-down(sm) {
                                    width: 100%;
                                }
        
                                &.cancel {
                                    border: 1px solid $input-border;
                                }
        
                                &.confirm-modif-info,
                                &.confirm-modif-pwd {
                                    &:hover {
                                        background-color: $primary-color-hover;
                                    }
                                }
                            }
                        }
    
                        .confirmation-form-modif-info-msg,
                        .confirmation-form-modif-pwd-msg {
                            
                            .done,
                            .fail {
                                display: none;
                                margin-top: 15px;
                                border-radius: 6px;
                                padding: 10px 15px;
                                border: 1px solid $input-border;
                                justify-content: center;
                                align-items: flex-start;
                                box-shadow: $shadow;
                                
                                p {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 24px;
                                    color: $text-color;
                
                                    @media screen and (max-width:430px) {
                                        margin-left: 15px;
                                    }
                                }
                
                                img {
                                    width: 25px;
                                    margin-right: 8px;
    
                                    @media screen and (max-width:425px) {
                                        margin-top: 5px;
                                        margin-right: 5px;
                                    }
                                }
                
                                &.visible {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .calvez-informations {
        padding: 12px 20px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: $text-color;

        @include media-breakpoint-down(md) {
            margin-top: 15px;
        }

        p {
            margin-bottom: 5px;

            &.title {
                font-weight: 700;

                &:not(:first-of-type) {
                    margin-top: 25px;
                }
            }
        }
    }
}
.user-account {
    position: relative;

    header {
        padding-top: 40px;
        padding-bottom: 25px;

        h1 {
            font-size: 36px;
            font-weight: 600;
            line-height: 32px;

            @media screen and (max-width: 576px) {
                font-size: 28px;
            }
        }
    }

    section {
        @media screen and (max-width: 576px) {
            margin-bottom: 50px; 
        }

        .container {
            @media screen and (max-width: 576px) {
                padding-left: 0;
                padding-right: 0;
            }

            .nav-tabs {
                display: none;
            }
              
            @media screen and (min-width: 768px) {
                .nav-tabs {
                    display: flex;
                    flex-flow: column nowrap;
                    width: 25%;
                    margin: 0;
                    border-bottom: none;
                    border-right: 1px solid $input-border;
                    display: flex;

                    @media screen and (max-width: 1160px) {
                        width: 19%;
                    }

                    @media screen and (max-width: 991px) {
                        width: 25%;
                    }
    
                    .nav-item + .nav-item {
                        margin-top: 0.25rem;
                    }
    
                    .nav-link {
                        transition: border-color 0.125s ease-in;
                        white-space: nowrap;
                        font-size: 14px !important;
                        margin-right: 0 !important;
                        color: $text-color !important;
    
                        &:hover {
                            background-color: $white-color-10;
                            border-color: transparent;
                        }
    
                        &.active {
                            color: $primary-color!important;
                            border-bottom-color: $primary-color;
                            border-bottom-left-radius: 0.25rem;
                            border-top-right-radius: 0;
                            margin-right: -1px;
                        }
                    }
                }
            
                .tab-content {
                    width: 75%;

                    @media screen and (max-width: 1160px) {
                        width: 81%;
                    }

                    @media screen and (max-width: 991px) {
                        width: 75%;
                    }

                    .card {
                        border: none;
                        background-color: transparent;
        
                        .card-header {
                            display: none;
                        }
        
                        .collapse {
                            display: block;

                            .card-body {
                                &#card-body-favorite {
                                    @media screen and (min-width: 992px) and (max-width: 1070px) {
                                        padding: 1.25rem 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
              
            @media screen and (max-width: 767px) {
    
                .tab-content {
                    width: 100%;
    
                    .tab-pane {
                        display: block !important;
                        opacity: 1;
                        border: none;
                        background-color: transparent;
    
                        .card-header {
                            border-bottom: 1px solid $input-border;
                            background-color: $white;
    
                            h5 {
                                text-align: center;
                                font-size: 16px;
    
                                a {
                                    text-decoration: none;
                                    color: $text-color;
                                }
                            }
                        }
    
                        .card-body {
                            border-left: 1px solid $input-border;
                            border-right: 1px solid $input-border;

                            @media screen and (max-width: 430px) {
                                padding: 1.25rem .75rem;
                            }

                            &#card-body-favorite {
                                @media screen and (max-width: 430px) {
                                    padding: 1.25rem .25rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.list-catalogs-feed-home {
    position: relative;

    .container {
        max-width: 1400px;
        padding-left: 0;
        padding-right: 0;
    }

    @media screen and (max-width: 576px) {
        margin-left: 20px;
        margin-right: 20px;
    }

    @media screen and (max-width: 430px) {
        margin-left: 10px;
        margin-right: 10px;
    }

    &__header {
        padding-top: 50px;
        //padding-bottom: 15px;
        padding-bottom: 5px;
        justify-content: space-between;

        .title-wrapper {
            h2 {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }
        }

        .link-all-catalogs {
            margin-right: 8px;

            a {
                position: relative;
                color: $primary-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-decoration: none;

                &::after {
                    content: url('img/icons/chevron_right_red.svg');
                    position: relative;
                    top: 1px;
                    margin-left: 15px;
                }
            }
        }
    }
}
/* Custom styles below */

// Custom partials
@import 'custom_partials/reset';
@import 'custom_partials/layouts/header';
@import 'custom_partials/layouts/footer';
@import 'custom_partials/layouts/headerNav';

@import 'custom_partials/components/searchBar';
@import 'custom_partials/components/menuUnivers';
@import 'custom_partials/components/breadcrumb';
@import 'custom_partials/components/filterProduct';
@import 'custom_partials/components/accordionProduct';
@import 'custom_partials/components/productOverview';
@import 'custom_partials/components/pagination';
@import 'custom_partials/components/accordionBloc';
@import 'custom_partials/components/autoblock';
@import 'custom_partials/components/informationBloc';
@import 'custom_partials/components/icon-font';
@import 'custom_partials/components/authorBlock';
@import 'custom_partials/components/cardBlock';

@import 'custom_partials/components/listCatalogsFeedHome';
@import 'custom_partials/components/breadcrumbOrder';
@import 'custom_partials/components/promotionDownload';
@import 'custom_partials/components/social';
@import 'custom_partials/components/quickOrder';
@import 'custom_partials/components/quickOrderMobile';
@import 'custom_partials/components/contactForm';
@import 'custom_partials/components/autoblockVertical';
@import 'custom_partials/components/autoblockHorizontal';
@import 'custom_partials/components/addProductInFavoriteList';
@import 'custom_partials/components/addProductInComparator';
@import 'custom_partials/components/comparateProducts';
@import 'custom_partials/components/msgConfirmationProductFav';
@import 'custom_partials/components/resultSearchbar';
@import 'custom_partials/components/sliderOwlCarousel';
@import 'custom_partials/components/sliderProduct';
@import 'custom_partials/components/spinner';
@import 'custom_partials/components/resetPassword';
@import 'custom_partials/components/downloadableFile';
@import 'custom_partials/components/quoteBloc';
@import 'custom_partials/components/tarteAuCitron';

@import 'custom_partials/pages/pageCategory';
@import 'custom_partials/pages/pageProduct';
@import 'custom_partials/pages/articlePage';
@import 'custom_partials/pages/searchbarResultsList';
@import 'custom_partials/pages/home/sliderHome';
@import 'custom_partials/pages/home/newsFeedHome';
@import 'custom_partials/pages/home/catalogsFeedHome';
@import 'custom_partials/pages/allCategories';

@import 'custom_partials/components/orderJourney/shoppingList';
@import 'custom_partials/components/orderJourney/totalShoppingList';
@import 'custom_partials/components/orderJourney/checkoutShipping';
@import 'custom_partials/components/orderJourney/orderSummary';
@import 'custom_partials/components/orderJourney/orderMessage';

@import 'custom_partials/user/login/loginBoth';
@import 'custom_partials/user/login/loginRegister';
@import 'custom_partials/user/login/forgottenPassword';

@import 'custom_partials/user/userAccount';
@import 'custom_partials/user/userInformations';
@import 'custom_partials/user/userAddresses';
@import 'custom_partials/user/userOrders';
@import 'custom_partials/user/userDetailOrder';
@import 'custom_partials/user/userEstimates';
@import 'custom_partials/user/userDetailEstimate';
@import 'custom_partials/user/userInvoices';
@import 'custom_partials/user/userFavorites';
@import 'custom_partials/user/userDetailFavoriteList';
@import 'custom_partials/user/userShoppingLists';
@import 'custom_partials/user/userStatistics';

// Temporary css

// .paragraph h3:not(:first-child) {
//     margin-top: 0.25rem;
//     line-height: 1rem;
// }

// Customize row of catalogs on the homepage
#container_caef834f8398b943fb94bc1ab4c918ca {
    max-width: 1400px;

    .row-item {
        @include media-breakpoint-down(md) {
            flex: 0 0 49%;
        }

        @media screen and (max-width: 414px) {
            flex: 0 0 100%;
        }
    }
}
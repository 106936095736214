.user-statistics {
    position: relative;

    .main-user-statistics {
        padding: 15px;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;
        
        @media screen and (max-width: 430px) {
            padding: 15px 5px;
        }

        @media screen and (max-width: 375px) {
            font-size: 15px;
        }

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }
        }

        .stat-wrapper {
            .yearly-total-amount {
                margin: 25px 0;

                span {
                    font-weight: 600;
                }

                @media screen and (max-width: 575px) {
                    display: flex;
                    flex-direction: column;
                }
            }

            .indication {
                margin-bottom: 15px;
            }

            form {
                .input-group {
                    width: 50%;
                    align-items: baseline;

                    @media screen and (max-width: 900px) {
                        width: 75%;
                    }

                    @media screen and (max-width: 575px) {
                        width: 100%;
                    }
                    
                    label {
                        margin-right: 25px;
                        
                        @media screen and (max-width: 360px) {
                            margin-right: 15px;
                        }
                    }

                    select {
                        border-color: $input-border;

                        @media screen and (max-width: 345px) {
                            padding-left: .25rem;
                            padding-right: .25rem;
                        }
                    }
                }

                .btn-wrapper {
                    margin-top: 25px;
                    width: 20%;

                    @media screen and (max-width: 900px) {
                        width: 30%;
                    }

                    @media screen and (max-width: 575px) {
                        width: 100%;
                    }

                    .download-yearly-report {
                        width: 100%;
                        padding: 8px 0px;
                        border-radius: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;

                        &:hover {
                            background-color: $primary-color-hover;
                        }
                    }
                }
            }
        }
    }
}
.login,
.register {
    position: relative;
    
    .breadcrumb-back {
        .breadcrumb-item {
            &.come-back {
                a {
                    color: $text-color;

                    &::before {
                        content: url("assets/img/icons/arrow_back_dark.svg");
                        top: 3px;
                    }
                }
            }
        }
    }

    .container {
        padding-top: 35px;

        @include media-breakpoint-down(md) {
            padding-top: 10px;
        }

        .connection-description,
        .block-connection-form {
            margin: auto;
        }

        .logo {
            margin-bottom: 10px;
            text-align: center;
        }

        .connection-description {
            max-width: 560px;
    
            h1 {
               font-size: 24px;
               font-weight: 600;
               line-height: 32px;
               text-align: center;
               margin-bottom: 5px;
               
               @include media-breakpoint-down(sm) {
                font-size: 18px;
                line-height: 24px;
               }
            }

            p {
                max-width: 365px;
                margin: 0 auto;
                color: $text-light-gray;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;

                @media screen and (max-width: 576px) {
                    max-width: 345px;
                }
            }
        }

        .block-connection-form {
            margin: 45px auto 100px auto;
            width: 560px;
            max-width: 560px;
            padding: 25px;
            background-color: $white;
            border: 1px solid $input-border;
            border-radius: 6px;
            box-shadow: $shadow;

            @media screen and (max-width: 576px){
                width: 95%;
                max-width: 95%;
                margin-top: 30px;
                margin-bottom: 50px;
            }

            h2 {
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 15px;

                @media screen and (max-width: 576px) {
                    font-size: 16px;
                }
            }

            form {

                .name-account-group {
                    position: relative;

                    #infoAccountUser {
                        position: absolute;
                        top: 45px;
                        right: 15px;
                        cursor: pointer;
                    }

                    #detailInfoAccountUser {
                        display: none;
                        position: absolute;
                        z-index: 1;
                        visibility: hidden;
                        font-size: 14px;
                        font-weight: 500;
                        border: 1px solid $input-border;
                        box-shadow: $shadow;
                        transition: visibility .4s ease-in-out;

                        &.visible {
                            display: block;
                        }
                    }
                }

                .gdpr-consent-group {
                    label {
                        margin-left: 5px;
                        font-size: 14px;
                        font-weight: 400;
                        color: $text-color;

                        a, 
                        a:hover {
                            color: $primary-color;
                        }
                    }
                }

                .label-title {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $text-color;
                }

                input[type="text"],
                input[type="email"],
                input[type="tel"],
                input[type="number"],
                select {
                    box-shadow: $shadow;
                    height: 48px;

                    &.has-error {
                        border: 2px solid $primary-color;
                    }
                }

                input[type="checkbox"] {
                    &.has-error {
                        border: 2px solid $primary-color;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                    /* Firefox */
                }

                input[type="number"]:hover,
                input[type="number"]:focus {
                    -moz-appearance: number-input;
                }

                select {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    color: $text-light-gray;
                }

                input[type="submit"]{
                    margin-top: 15px;
                    width: 100%;
                    height: 48px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                }

                .msg-error {
                    display: none;
                    visibility: hidden;
                    margin-top: 10px;
                    padding: 10px;
                    color: $primary-color;
                    background-color: $primary-color-10;
                    border: 1px solid $input-border-error;
                    border-radius: 6px;
                    font-size: 14px;

                    p {
                        line-height: 22px;
                    }

                    &.visible {
                        display: block;
                        visibility: visible;
                    }
                }
            }

            .confirmation-form-message {
                display: none;
                margin-top: 15px;
                border-radius: 6px;
                padding: 10px 15px;
                border: 1px solid $input-border;
                justify-content: space-evenly;
                align-items: flex-start;
                box-shadow: $shadow;
                
                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    color: $text-color;

                    @media screen and (max-width:430px) {
                        margin-left: 15px;
                    }
                }

                img {
                    width: 25px;
                    margin-top: 5px;
                }

                &.visible {
                    display: flex;
                }
            }
        }
    }
}

/* --------------  BEGINNING OF HANDLING SCROLLBAR ------------- */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-grey;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: thin;
}

*::-webkit-scrollbar-track {
  background: $scrollbar-grey;
  border-radius: 8px;
  border: 1px solid rgba($body-secondary, 0.2);
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-grey;
  border-radius: 8px;
  border: 1px solid transparent;
  background-clip: content-box;
  -moz-background-clip: content-box;
  -webkit-background-clip: content-box;
  -o-background-clip: content-box;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: $primary-color;
}

.scrollbar-primary {
  scrollbar-color: $primary-color #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-danger::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #ff3547;
}

.scrollbar-danger {
  scrollbar-color: #ff3547 #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-warning::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #FF8800;
}

.scrollbar-warning {
  scrollbar-color: #FF8800 #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-success::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #00C851;
}

.scrollbar-success {
  scrollbar-color: #00C851 #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-info::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #33b5e5;
}

.scrollbar-info {
  scrollbar-color: #33b5e5 #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-default::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
    background-color: #2BBBAD;
}

.scrollbar-default {
    scrollbar-color: #2BBBAD #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-secondary::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}


.scrollbar-secondary {
  scrollbar-color: $scrollbar-grey #F5F5F5;
  -webkit-scrollbar-color: $scrollbar-grey #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba($body-secondary, 0.1);
    background-color: $scrollbar-grey;
}
/* --------------  END HANDLING SCROLLBAR ------------- */

body {
  font-family: $font-inter;
}

.bb-site-wrapper {
    font-family: $font-inter;

    main {
      padding-top: 0 !important;
      background-color: $background-secondary;
      padding-left: 7.5px;
      padding-right: 7.5px;

      @include media-breakpoint-down(md) {
        padding-left: 12.5px;
        padding-right: 12.5px;
      }
    }
}

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: $font-inter !important;
    color: $text-color !important;
}

header {
  &.bb-content {
    padding-top: 40px;
    padding-bottom: 25px;

    h1 {
      font-size: 36px;
      font-weight: 600;
      line-height: 32px;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
      }
    }
  }
}

// H2, H3, H4, Paragraphs - All types of pages
.paragraph {
  .rte-text {
    h2 {
      font-size: 24px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        font-size: 22px;
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 600;
      color: $text-light-gray !important;

      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }

    p, ul {
      font-size: 16px;
      line-height: 22px;
      color: $text-color;
    }

    a {
      color: $primary-color;
      text-decoration: none;

      &:hover {
          text-decoration: underline;
      }
    }
  }
}


// Beginning of Custom Inputs Checkbox & Radio

input[type="checkbox"],
input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: thin solid #17161D1F;
  transform: translate(-20px, 4px);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    transform: scale(0);
    transition: transform 120ms ease-in-out;
    border: 4px solid $primary-color;
    border-radius: 3px;
  }

  &:checked::before {
    transform: scale(1);
  }
}

input[type="checkbox"] {
  border-radius: 4px;

  &::before {
    border-radius: 3px;
  }
}

input[type="radio"] {
  border-radius: 25px;

  &::before {
    border-radius: 8px;
  }
}

// End of Custom Inputs Checkbox & Radio

// Fix font-size of input in order to stop auto zoom for ios
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
  font-size: 16px !important;

  &:focus {
    font-size: 16px !important;
    outline: none;
    box-shadow: none;
  }
}

// Remove outline shadow on the buttons
.button > a.btn,
.buttonwrapper > a.btn {
  //outline: none;
  box-shadow: none;
  border-radius: 50px !important;

  &:focus {
    //outline: none !important;
    box-shadow: none !important;
  }
}

.btn {
  &:focus {
    box-shadow: none !important;
  }
}

.buttonwrapper > a.btn {
  padding: 12px 16px;
}

.content {
  &.button {
    a.btn {
      padding: 12px 16px;
    }
  }
}

// Input text | email | tel | ...
.form-control {
  border-color: $input-border;
  border-radius: 6px;

  &:focus {
    box-shadow: none !important;
  }
}

// Etiquettes Nouveauté | Déstockage | Promotion sur page produit
.badge {
  width: 73px;
  height: 24px;
  border-radius: 6px;
  padding-top: .5em;
  font-weight: 500;
  font-size: 11px;

  &.new {
    background-color: $banner-new;
  }

  &.destocking {
    background-color: $banner-destocking;
  }

  &.on-sale {
    background-color: $banner-on-sale;
  }
}

// Etiquettes Nouveauté | Déstockage | Promotion sur aperçu produit
.banner-ticket {
  padding: 6px 8px;
  border-radius: 6px 0px 0px 0px;
  color: $white;
  font-size: 11px;
  font-weight: 500;

  &.new {
    background-color: $banner-new;
  }

  &.destocking {
    background-color: $banner-destocking;
  }

  &.on-sale {
    background-color: $banner-on-sale;
  }
}

// Footer
footer, .footer {
    font-family: $font-inter !important;
}

// Accordion type Page produit
.nav {
  &.nav-tabs {
    &[role="tablist"]{
      .nav-link {
        padding: .5rem 0rem .5rem 0;
        margin-right: 15px;
        border: 2px solid transparent;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $text-light-gray;

        &.active {
          color: $primary-color;
          background: none;
          border-color: transparent transparent $primary-color;
        }
      }
    }
  }
}

// Tooltip
.tooltip-custom {
  box-shadow: 1px 1px 4px 0px #17172F2B;
  width: 250px;
  max-width: 250px;
  border-radius: 0 6px 6px 6px;
  background-color: $white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .list-group {
    .list-group-item {
      padding: .25rem .15rem;

      .icon-stock {
        width: 14px;
        height: 14px;
        max-width: 14px;
        min-width: 14px;
        max-height: 14px;
        min-height: 14px;
        margin-left: 4px;
      }

      .agency {
        display: block;
        font-size: 11px;
        font-weight: 500;
        color: $text-color;
        padding-right: 0px;
        text-align: left;
      }

      .nb-stock {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: $text-color;
        text-align: right;
        padding-left: 1px;

        &.out-of-stock {
          color: $text-light-gray;
        }
      }

    }
  }

}

// Custom bootstrap's modals
.modal {
  .modal-content {
    border: none;

    .modal-header {
      padding: 1.5rem;
      .close {
        opacity: 1;
      }
    }

    .modal-body {
      padding: 1.5rem;

      .label-title {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $text-color;
      }

      select {
        &:focus {
          box-shadow: none;
        }
      }
    }

    .modal-footer {
      padding: 1.5rem;
    }
  }
}

.modal-backdrop {
  z-index: 1;
}

// Iframe
.iframe {
  iframe {
    border-color: $input-border;
  }
}

// Dividing lines
.cloud-liner {
  &::after {
    border-top: 1px solid $body-secondary;
  }
}

// Reset background color on autocomplete input
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px $white inset !important;
  box-shadow: 0 0 0 1000px $white inset !important;
}

// 404 page
.site-wrapper-inner {
  @media screen and (min-width: 768px) {
    vertical-align: inherit !important;
  }

  .reason {
    em {
      font-size: 16px;
      font-style: normal;
      line-height: 22px;
      color: $text-color;
    }
  }

  a.btn {
    border-radius: 50px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 500;
  }
}
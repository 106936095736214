/* Custom theme below */

$primary-color:      #DE3A3A;
$secondary-color:    #17161D;
$text-color:         #17161D;

$background-color:   #ffffff;
$light-gray:         #F5F5F7;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "light-gray": $light-gray,
    "background": $background-color,
    "text": $text-color
)
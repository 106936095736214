.result-searchbar {
  display: none;
  position: absolute;
  width: 900px;
  height: auto;
  max-width: 900px;
  overflow: hidden;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid $input-border;
  border-radius: 6px;
  background-color: $white;
  box-shadow: $shadow;
  z-index: 500;

  @include media-breakpoint-down(md) {
    top: 155px;
    width: 94%;
    max-width: 94%;
    height: auto;
    max-height: 475px;
  }

  @media screen and (max-width: 685px) {
    width: 92%;
    max-width: 92%;
    min-height: 475px;
  }

  @media screen and (max-width: 430px) {
    min-height: 530px;
  }

  @media screen and (max-width: 375px) {
    min-height: 575px;
  }

  @media screen and (max-width: 360px) {
    min-height: 585px;
  }

  @media screen and (max-width: 345px) {
    min-height: 620px;
  }

  &.open {
    display: block;
  }

  .categories-wrapper,
  .brands-wrapper,
  .products-wrapper {
    padding: 10px;

    a.add-shopping-card {
      font-size: 12px;
      padding: 8px 8px;
      max-width: 175px;
    }
  }

  .title-block {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $text-light-gray;
    margin-bottom: 10px;
  }

  .form-check-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $text-color;
    margin-left: 3px;

    @media screen and (max-width: 345px) {
      font-size: 12px;
      line-height: 16px;
      margin-left: 1px;
    }
  }

  .result-searchbar-container {
    .left-column {
      padding-right: 0;

      @include media-breakpoint-down(md) {
        display: flex;
      }

      .categories-wrapper,
      .brands-wrapper {
        form {
          .form-check {
            &:nth-child(n + 6) {
              display: none;
            }

            &:hover {
              .form-check-input {
                border: 2px solid $primary-color-hover;
              }
            }

            .form-check-label {
              max-width: 180px;

              .result-count {
                color: $body-secondary;
                font-size: 12px;
              }
            }
          }
        }
      }

      .categories-wrapper {
        border-bottom: 1px solid $input-border;

        @include media-breakpoint-down(md) {
          display: none;
          // width: 50%;
        }
      }

      .brands-wrapper {
        @include media-breakpoint-down(md) {
          display: none;
          // width: 50%;
          // border-bottom: 1px solid $input-border;
          // border-left: 1px solid $input-border;
        }
      }
    }

    .right-column {
      padding-left: 0;
      position: relative;

      .products-wrapper {
        border-left: 1px solid $input-border;
        min-height: 100%;
        //height: 436px;
        //max-height: 436px;

        @include media-breakpoint-down(md) {
          padding: 10px 20px;
        }

        @media screen and (max-width: 430px) {
          padding: 10px 15px;
        }

        .product-wrapper {
          &:not(:last-of-type) {
            padding-bottom: 5px;
            border-bottom: 1px solid $input-border;
          }

          &:not(:first-child) {
            padding-top: 5px;
          }

          .container-product {
            padding: 10px;
            border-radius: 6px;
            transition: background-color .4s ease-in-out;

            @media screen and (max-width: 430px) {
              padding-left: 0;
              padding-right: 0;
            }

            .add-shop-list {
              background-color: $background-secondary;
              padding: 16px;

              @media screen and (max-width: 430px) {
                max-width: 120px;
              }

              p.title {
                font-size: 12px;
                color: $text-color;
                font-weight: 500;
                text-align: center;
              }

              a.login {
                font-size: 12px;
                text-align: center;
                background-color: $background-secondary;
                color: $body-secondary;

                p.indication {
                  font-weight: 400;
                  line-height: 16px;
                }
              }
            }

            .epuise {
              font-size: 13px;
              text-align: center;
            }

            .fds {
              display: flex;
              align-items: center;
              font-size: 13px;
              text-align: center;

              @media screen and (max-width: 575.98px) {
                padding-left: 5px;
                padding-right: 5px;
              }

              a {
                border: 1px solid $input-border;
                border-radius: 50px;
                padding: 4px;
                font-size: 13px;
                font-weight: 500;
                color: $text-color;
                background-color: $white;
                transition: background-color .4s ease-in-out;

                @media screen and (max-width: 575.98px) {
                  font-size: 12px;
                  border-radius: 8px;
                }

                &:hover {
                  background-color: $white;
                  text-decoration: none;
                  box-shadow: $shadow;
                }
              }
            }

            &:hover {
              background-color: $background-secondary;
            }

            .img-wrapper {
              width: 64px;
              height: 64px;
              max-width: 64px;
              max-height: 64px;
              min-width: 64px;
              min-height: 64px;
              text-align: center;
              padding-left: 0;
              padding-right: 0;

              @media screen and (max-width: 820px) {
                width: 50px;
                max-width: 50px;
                height: 50px;
                max-height: 50px;
              }

              @media screen and (max-width: 430px) {
                width: 45px;
                max-width: 45px;
                height: 45px;
                max-height: 45px;
              }

              @media screen and (max-width: 375px) {
                width: 40px;
                max-width: 40px;
                height: 40px;
                max-height: 40px;
              }


              img {
                height: 100%;
                max-height: 64px;
                width: auto;

                @media screen and (max-width: 820px) {
                  max-height: 50px;
                }

                @media screen and (max-width: 430px) {
                  max-height: 45px;
                }

                @media screen and (max-width: 375px) {
                  max-height: 40px;
                }
              }
            }

            .description-wrapper {
              flex: 0 0 380px;
              max-width: 380px;
              margin-left: 10px;

              @include media-breakpoint-down(md) {
                flex: 0 0 62%;
                max-width: 62%;
              }

              @media screen and (max-width: 685px) {
                flex: 0 1 64%;
                max-width: 64%;
                margin-left: 5px;
              }

              @media screen and (max-width: 576px) {
                flex: 0 1 60%;
                max-width: 60%;
              }

              @media screen and (max-width: 430px) {
                flex: 0 1 50%;
                max-width: 50%;
              }

              .brand {
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                color: $text-light-gray;
              }

              .name-product {
                max-width: 380px;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;

                a, a:hover {
                  color: $text-color;
                }

                @media screen and (max-width: 685px) {
                  max-width: 300px;
                }

                @media screen and (max-width: 576px) {
                  max-width: 275px;
                }

                @media screen and (max-width: 448px) {
                  max-width: 190px;
                }

                @media screen and (max-width: 430px) {
                  max-width: 155px;
                }

                @media screen and (max-width: 375px) {
                  max-width: 145px;
                }

                @media screen and (max-width: 358px) {
                  max-width: 130px;
                }

                @media screen and (max-width: 335px) {
                  max-width: 115px;
                }

                @media screen and (max-width: 320px) {
                  max-width: 105px;
                }
              }

              .ref-calvez {
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                color: $text-light-gray;
              }
            }

            .price-wrapper {
              flex: 0 0 110px;
              max-width: 110px;
              padding-top: 15px;

              @include media-breakpoint-down(md) {
                flex: 0 0 15%;
                max-width: 15%;
              }

              @media screen and (max-width: 685px) {
                flex: 0 0 20%;
                max-width: 20%;
              }

              @media screen and (max-width: 430px) {
                flex: 0 0 24%;
                max-width: 24%;
              }

              .price {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: right;
                color: $text-color;

                @include media-breakpoint-down(sm) {
                  font-size: 14px;
                }

                @media screen and (max-width: 430px) {
                  font-size: 12px;
                }
              }

              .debit {
                text-align: right;
                font-size: 13px;
                font-weight: 400;
                color: $body-secondary;

                @include media-breakpoint-down(sm) {
                  font-size: 11px;
                }

                @media screen and (max-width: 430px) {
                  font-size: 9px;
                }
              }
            }

            .add-shopping-list {
              flex: 0 0 75px;
              max-width: 75px;
              text-align: end;
              padding-top: 5px;

              @include media-breakpoint-down(md) {
                flex: 0 0 12%;
                max-width: 12%;
              }

              @media screen and (max-width: 685px) {
                padding-top: 10px;
              }

              @media screen and (max-width: 390px) {
                padding-top: 12px;
              }

              button {
                width: 40px;
                height: 40px;
                border: none;
                background-color: transparent;

                @media screen and (max-width: 685px) {
                  margin-right: 8px;
                  width: 30px;
                  height: 30px;
                }

                @media screen and (max-width: 375px) {
                  width: 22px;
                  height: 22px;
                }

                // img {
                //     width: 40px;
                //     height: 40px;
                //     max-width: 40px;
                //     max-height: 40px;

                //     @media screen and (max-width: 685px) {
                //         width: 30px;
                //         height: 30px;
                //         max-width: 30px;
                //         max-height: 30px;
                //     }

                //     @media screen and (max-width: 375px) {
                //         width: 22px;
                //         height: 22px;
                //         max-width: 22px;
                //         max-height: 22px;
                //     }

                // }

                &.selected span {
                  background-image: url('/img/icons/add-shop-list-hover.svg') !important;
                }

                span {
                  display: inline-block;
                  width: 40px;
                  height: 40px;
                  max-width: 40px;
                  max-height: 40px;
                  background-image: url('/img/icons/add-shop-list.svg');
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;

                  &:hover {
                    background-image: url('/img/icons/add-shop-list-hover.svg') !important;
                  }

                  @media screen and (max-width: 685px) {
                    width: 30px;
                    height: 30px;
                    max-width: 30px;
                    max-height: 30px;
                  }

                  @media screen and (max-width: 375px) {
                    width: 22px;
                    height: 22px;
                    max-width: 22px;
                    max-height: 22px;
                  }
                }
              }
            }
          }
        }

        .display-all-product {
          margin-top: 10px;
          width: 100%;
          padding: 12px 16px;
          border: 1px solid $input-border;
          border-radius: 25px;
          font-size: 14px;
          font-weight: 500;
          color: $text-color;

          &:hover {
            background-color: $background-secondary;
          }
        }
      }

      .no-result-found {
        position: relative;
        margin: 25px 0;
        color: $text-color;
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        left: -15%;

        @include media-breakpoint-down(md) {
          left: 2%;
        }

        @include media-breakpoint-down(md) {
          margin: 25px;
        }
      }
    }
  }
}
.promotion-download {
    position: relative;

    .download-label {
        color: $text-color;
        font-weight: 500;
    }

    .download-pdf-wrapper {
        .column-image {
            @include media-breakpoint-down(md) {
                margin-bottom: 25px;
            }

            figure {
                .contentwrapper {
                    img {
                        min-width: 100%;
                        height: auto;
                        transition: all .4s ease-in-out;

                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }

        .column-content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .date-publication {
                margin-bottom: 5px;
                font-size: 14px;
                color: $text-light-gray;

                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }

            h3 {
                font-size: 1.5rem;
                margin-bottom: 15px;
                font-weight: 600;

                @media screen and (max-width: 430px){
                    font-size: 1.25rem;
                }
            }

            .description-file {
                margin-bottom: auto;

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-color; 
                }

                a {
                    color: $primary-color;

                    &:hover {
                        color: $primary-color-hover;
                    }
                }
            }
            
            .link-download {
                width: max-content;
                padding: 12px 16px;
                justify-content: center;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin-bottom: 25px;
                }

                @media screen and (max-width: 365px) {
                    //flex-direction: column;
                    border-radius: 50px;
                }

                .size-unit {
                    margin-left: 5px;
                    font-size: 13px;
                    margin-top: 1px;
                }
            }
        }
    }
}
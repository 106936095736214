footer {
    border-top: none !important;
    .reassurances-footer {
        .reassurance-item-container {
            .reassurance-item {
                .icon-reassurance {
                    width: 48px;
                    height: 48px;
                }

                .title-reassurance {
                    h2 {
                        font-size: 1rem;
                        color: $text-color;
                        font-weight: 600;
                    }
                }

                .paragraph {
                    font-size: 14px;
                    color: $text-light-gray;
                    
                    p {
                        line-height: 20px !important;
                    }
                }
            }
        }
    }

    .footer-main {
        padding: 48px 30px !important;
        background-color: $primary-color;
        color: $text-footer;

        @include media-breakpoint-down(lg) {
            padding: 48px 16px !important;
        }

        .footer-company {
            font-size: 12px;
            line-height: 16px;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }
            
            .logo-wrapper {
                width: 165px;
                max-width: 165px;
            }
            
            h2 {
                font-size: 12px;
                font-weight: 500;
                color: $light-gray !important;
            }

            .baseline {
                padding: 0.2rem 0;
                font-weight: 400;
                color: $text-light-white;
            }
        }

        .footer-menus {

            .heading-wrap {
                position: relative;

                h2 {
                    color: $text-light-white !important;
                    font-size: 10px;
                    line-height: 16px;
                    text-transform: uppercase;
    
                    @include media-breakpoint-down(sm) {
                        border-top: thin solid $light-white-border;
                        padding: 12px 0 0;
                        font-size: 13px;
                        line-height: 24px;
                        font-weight: 500;
                        color: $text-footer !important;
                        text-transform: initial;
                        
                        &.open {
                            padding-bottom: 0;
                        }
                    }
                }

                img {
                    display: none;

                    @include media-breakpoint-down(sm){
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 20px;
                        transition: transform 0.4s ease-in-out;

                        &.open {
                            transform: rotate(-180deg);
                            -webkit-transform: rotate(-180deg);
                            -moz-transform: rotate(-180deg);
                            -o-transform: rotate(-180deg);
                        }

                        @media (max-width: 430px) {
                            top: 22px;
                        }
                    }
                }
            }
            
            a, 
            a:active, 
            a:visited {
                color: $text-footer;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }

            .menu-footer {
                .navbar-nav {
                    padding-left: 0;
                    list-style-type: none;
                    transition: all 1s ease-in-out;

                    @include media-breakpoint-down(sm) {
                        display: none;
                        opacity: 0;
                        transition: all 1s ease-in-out;
                        
                        &.visible {
                            display: block;
                            opacity: 1;
                            transition: all 1s ease-in-out;
                        }

                        .footer-item-li {
                            
                            &:last-child {
                                padding-bottom: 1rem;
                            }
                        }
                    }

                    .footer-item-li {
                        margin-bottom: .5rem;
                    }
                }
            }

            .footer-item {
                

                @include media-breakpoint-down(sm) {
                    &.contact {
                        .navbar-nav {
                            border-bottom: thin solid $light-white-border;
                        }
                    }   
                }
            }

            .legal {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }

    .footer-copyright {
        padding: 24px 30px !important;
        color: $text-footer;
        background-color: $primary-color;
        border-top: thin solid $light-white-border;

        @include media-breakpoint-down(sm) {
            padding: 15px 16px !important;
        }
        
        .paragraph {
            p {
                color: $text-footer;
                text-transform: initial;
            }
        }

        .bb-content {
            @include media-breakpoint-down(md) {
                text-align: center !important;
            }
            .social-list {
                .list-inline-item {
                    a {
                        color: $text-light-white;
                        
                        &:hover {
                            color: $text-footer;
                        }
                    }
                }
            }
        }

    }
}
.author-block {

    &:hover {
        a {
            text-decoration: none;
            color: $text-light-gray;
        }

        img {
            transition: transform .2s ease-in-out;
            transform: scale(1.025);
        }
    }

    a {
        color: $text-light-gray;
    }

    .card {
        background-color: rgba($white, 0.5);
        box-shadow: $shadow;
        border: none;

        .d-flex {
            img {
                @media screen and (max-width: 575px) {
                    flex: 1 0 25%;
                }

                @media screen and (max-width: 430px) {
                    flex: 1 0 35%;
                }

                @media screen and (max-width: 385px) {
                    flex: 1 0 45%;
                }
            }
            
            .card-body {
                @media screen and (max-width: 385px) {
                    padding: 1.5rem;
                }

                h3 {
                    margin-top: 5px;
                }

                p {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
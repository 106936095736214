nav[aria-label="pagination"],
.autoblock-vertical > nav {
    width: 100%;
    position: relative;
    z-index: 1;
    
    .pagination {
        border-top: 1px solid $input-border;

        .page-item { 
            &.disabled {
                .page-link {
                    span {
                        color: $body-secondary !important;
                    }
                }
            }
        }
        
        a[aria-label="Previous"],
        a[aria-label="Next"] {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            
            @media screen and (min-width: 768px) {
                padding-top: 5px;
            }

            span.previous,
            span.next {
                color: $text-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                -webkit-text-stroke: transparent;
                
                @media screen and (max-width: 768px) {
                    font-size: 12px;
                }
            }
        }

        a[aria-label="Previous"] {
            position: absolute;
            left: 0;

            @media screen and (max-width: 575.98px) {
                bottom: -3px;
            }

            @media screen and (max-width: 390px) {
                bottom: -5px;
            }

            span {
                &.fa-arrow-left {
                    color: $text-color;
                    margin-right: 10px;
                    font-size: 20px;
                    -webkit-text-stroke: 1.2px $background-secondary;
                } 
            }
        }

        a[aria-label="Next"] {
            position: absolute;
            right: 0;

            @media screen and (max-width: 575.98px) {
                bottom: -3px;
            }

            @media screen and (max-width: 390px) {
                bottom: -5px;
            }

            span {
                &.fa-arrow-right {
                    color: $text-color;
                    margin-left: 10px;
                    font-size: 20px;
                    -webkit-text-stroke: 1.2px $background-secondary;
                } 
            }
        }

        .page-item {

            // @media screen and (max-width: 575.98px){
            //     padding-top: 9px;
            // }

            .page-link {
                color: $text-color;
                font-size: 14px;
                border-radius: 50%;
                background-color: transparent;
                border: none;
                transition: background-color .4s ease-in-out;

                &:focus {
                    box-shadow: none;
                }

                &.number-page {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 1px;
                    margin-right: 1px;

                    &:hover {
                        background-color: $white;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 40px;
                        height: 40px;
                    }

                    @media screen and (max-width: 575.98px) {   
                        width: 30px;
                        height: 30px;
                    }

                    @media screen and (max-width: 430px) {   
                        width: 20px;
                        height: 20px;
                        font-size: 12px;
                        margin-left: 2px;
                        margin-right: 2px;
                    }

                    @media screen and (max-width: 375px) {  
                        font-size: 11px;
                        margin-left: 1px;
                        margin-right: 1px;
                    }

                    @media screen and (max-width: 350px) {  
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                &:not(.number-page) {
                    width: auto;
                    height: 40px;
                    border-radius: 0;

                    @media screen and (max-width: 575.98px) { 
                        .previous,
                        .next {
                            display: none;
                        }
                    }
                }
            }

            &.active {
                .page-link {
                    background-color: $white;
                    border-color: $white;
                }
            }
        }
    }
}
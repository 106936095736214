.slider-product {
    
    #myCarousel .list-inline {
        white-space:nowrap;
        overflow-x:auto;
    }
    
    #myCarousel .carousel-indicators {
        position: static;
        left: initial;
        width: initial;
        margin-left: initial;
        margin-right: 5% !important;
        
        @media screen and (max-width: 1065px) {
            margin-right: 1% !important;
        }

        @include media-breakpoint-down(md) {
            margin-right: 5% !important;
        }

        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
    
    #myCarousel .carousel-indicators > li {
        width: initial;
        height: initial;
        text-indent: initial;
    }
    
    #myCarousel .carousel-indicators > li.active img {
        opacity: 0.7;
    }

    #myCarousel {
        z-index: 1;

        @include media-breakpoint-down(sm) {
            padding-right: 0 !important;
        }

        .list-inline {
            li {
                max-width: 72px;
                max-height: 72px;

                a {
                    img {
                        max-height: 72px;
                        min-width: 72px;
                        object-fit: cover;
                        object-position: top;
                    }
                }
            }
        }

        .carousel-inner {
            position: relative;
            width: 485px;
            height: 485px;
            border-radius: 6px;
            touch-action: pan-x;

            @media screen and (max-width: 635px) {
                width: 450px;
                height: 450px;
            }

            @include media-breakpoint-down(sm) {
                margin: auto;
            }

            @media screen and (max-width: 475px) {
                width: 324px;
                height: 324px;
            }
            
            .carousel-item {
                position: absolute;
                top: 0;
                left: 3%;
                width: 485px;
                height: 485px;
                display: flex;
                opacity: 0;
                visibility: hidden;
                transition: transform .0s ease-in-out,opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
                
                &.active {
                    justify-content: center;
                    margin-left: -25px;
                    visibility: visible;
                    opacity: 1;
                }
                
                @media screen and (max-width: 635px) {
                    width: 450px;
                    height: 450px;
                }
                
                @media screen and (max-width: 475px) {
                    width: 324px;
                    height: 324px;
                }

                img {
                    width: auto;
                    max-height: 485px;
                    object-fit: contain;
                    object-position: center;
                    
                    @media screen and (max-width: 635px) {
                        max-height: 450px;
                    }

                    @media screen and (max-width: 475px) {
                        max-height: 324px;
                        
                    }
                }
            }

            .carousel-control-prev,
            .carousel-control-next {
                top: calc(50% - 20px);
                padding: 5px;
                width: 40px;
                height: 40px;
                margin: 0 10px;
                background-color: $white;
                border: 1px solid $input-border;
                border-radius: 25px;
            }

            .carousel-control-prev {
                .carousel-control-prev-icon {
                    background-image: url("/img/icons/chevron_left.svg");
                }
            }

            .carousel-control-next {
                .carousel-control-next-icon {
                    background-image: url("/img/icons/chevron_right.svg");
                }
            }
        }
    }
}

.total-shopping-list {
    .total-container {
        padding: 25px 20px;
        background-color: $white;
        border-radius: 6px;
        border: 1px solid $input-border;
        box-shadow: $shadow;

        @include media-breakpoint-down(md) {
            border-radius: 0px;
        }

        @media screen and (max-width: 335px) {
            padding: 25px 15px;
        }

        @media screen and (max-width: 280px) {
            padding: 20px 5px;
        }

        .total-title {
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: $text-color;
        }

        .total-summary {

            td {
                padding: .75rem 0 .75rem .45rem;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: $text-color;

                &.summary-label {
                    padding-left: 0;
                    color: $body-secondary;

                    @media screen and (max-width: 335px) {
                        width: 60%;
                    }
                }
                
                &.total-ttc-label {
                    padding-left: 0;
                    font-size: 16px;
                    
                    @media screen and (max-width: 335px) {
                        width: 60%;
                    }
                }
                
                .total-ttc {
                    font-weight: 600;
                } 
            }
        }

        .order-btn,
        .next-step-btn,
        .prev-step-btn {
            width: 100%;
            border-radius: 25px;
            font-size: 14px;
            padding-top: 12px;
            padding-bottom: 12px;
        }

        .next-step-btn {
            &.disabled {
                background-color: $button-color-disabled;
                color: $body-secondary;
                border: none;
            }
        }

        .prev-step-btn {
            border: 1px solid $input-border;
        }

        .btn-wrapper {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 430px) {
                flex-direction: column;
                justify-content: center;
                gap: 15px;
            }

            .estimate-btn,
            .register-shop-list-btn {
                width: 48%;
                border: 1px solid $input-border;
                background-color: $white;
                border-radius: 25px;
                padding: 12px 0px;
                font-size: 14px;
                font-weight: 500;

                @media screen and (max-width: 430px) {
                    width: 100%;
                }

                &:hover {
                    background-color: $background-secondary;
                }
            }

            .modal {
                .modal-dialog {
                    .modal-content {
                        .modal-header {
                            border-bottom: 1px solid $input-border;
                            .modal-title {
                                font-size: 16px;
                                font-weight: 600;
                                color: $text-color;
                            }
                        }

                        .modal-body {
                            padding-bottom: .25rem;

                            .label-title {
                                margin-bottom: 10px;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 20px;
                                color: $text-color;
                            }

                            select, 
                            input[type="text"] {
                                height: 48px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 24px;
                                border: 1px solid $input-border;
                            }

                            select {
                                option {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 24px;
                                }
                            }

                            .choose-register {
                                position: relative;
                                margin: 25px 0;
                                font-size: 10px;
                                font-weight: 500;
                                line-height: 12px;
                                color: $body-secondary;
                                text-transform: uppercase;

                                &::before {
                                    content: "";
                                    display: block;
                                    height: 1px;
                                    width: 47%;
                                    background-color: $input-border;
                                    position: absolute;
                                    top: 6px;
                                }

                                &::after {
                                    content: "";
                                    display: block;
                                    height: 1px;
                                    width: 47%;
                                    background-color: $input-border;
                                    position: absolute;
                                    right: 0;
                                    top: 6px;
                                }
                            }
                        }

                        .modal-footer {
                            justify-content: space-between;
                            border-top: none;
                            border-top: .25rem;

                            button {
                                width: 48%;
                                padding: 12px 16px;
                                border-radius: 25px;
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;

                                @media screen and (max-width: 442px) {
                                    width: 45%;
                                    padding: 12px 0px;
                                }

                                &.cancel {
                                    border: 1px solid $input-border;
                                    
                                    &:hover {
                                        background-color: $background-secondary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
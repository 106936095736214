.block-slider-home {
    .main-container {
        background-color: $white;
        border-radius: 6px;
        max-width: 1400px;
        margin: 0 auto;

        .slider-wrapper {
            border-radius: 6px;

            .owl-carousel {
                height: 380px !important;
                border-radius: 6px;

                @media screen and (max-width: 810px) {
                    height: 250px !important;
                }

                @media screen and (max-width: 430px) {
                    height: 225px !important;
                }

                &.resized-slider {
                    margin-bottom: 0 !important;
                }

                .owl-stage-outer {
                    max-height: 380px;
                    border-radius: 6px;
                    border: 1px solid $input-border;
                    box-shadow: $shadow;
                    touch-action: pan-x;

                    .owl-stage {
                        .owl-item {
                            &,
                            &.active,
                            &.cloned {
                                .slider-item {
                                    display: flex;
                                    flex-direction: row-reverse;

                                    .slider-content {
                                        position: relative;
                                        flex: 0 0 40%;
                                        background-color: $white;
                                        text-align: left !important;
                                        padding: 5.5em 1.5em 5.5em 5em;
                                        display: flex;
                                        flex-direction: column;
                                        font-family: $font-inter;

                                        @include media-breakpoint-down(md) {
                                            flex: 0 0 50%;
                                        }

                                        @media screen and (max-width: 875px) {
                                            padding: 2.5em 3.5em;
                                        }

                                        @include media-breakpoint-down(md) {
                                            padding: 2.5em 2.5em 2.5em 3.5em;
                                        }

                                        @include media-breakpoint-down(sm) {
                                            padding-right: 1em;
                                        }

                                        @media screen and (max-width: 576px) {
                                            padding: 2.5em 1.5em 2.5em 1.5em;
                                        }

                                        @media screen and (max-width: 430px) {
                                            flex: 0 0 55%;
                                            padding: 2.5em 1em;
                                        }

                                        @media screen and (max-width: 393px) {
                                            flex: 0 0 55%;
                                            padding: 1em 0.5em;
                                        }

                                        .paragraph {
                                            &[data-parameter-name = "title"]{
                                                order: 0;
                                                color: $primary-color;
                                                font-size: 14px;
                                                font-weight: 500;
                                                line-height: 20px;
                                                display: flex;

                                                .image-picto-wrapper {
                                                    width: 20px;
                                                    height: 20px;
                                                    max-width: 20px;
                                                    max-height: 20px;
                                                    margin-right: 3px;

                                                    .image-picto {
                                                        height: 16px;
                                                        width: auto;
                                                        max-width: 16px;
                                                        max-height: 16px;
                                                    }
                                                }

                                                p {
                                                    font-weight: 500;
                                                    font-family: $font-inter;
                                                    margin-top: 0;
                                                }
                                            }

                                            &[data-parameter-name = "description"]{
                                                order: 1;
                                                color: $text-color;
                                                margin-bottom: 15px;
                                                font-size: 32px;
                                                font-weight: 700;
                                                line-height: 40px;

                                                h1, h2, p {
                                                    font-size: 32px;
                                                    font-weight: 700;
                                                    line-height: 40px;
                                                    font-family: $font-inter;
                                                }

                                                @media screen and (max-width: 875px) {
                                                    font-size: 20px;
                                                    line-height: 24px;
                                                    
                                                    h1, h2, p {
                                                        font-size: 20px;
                                                        line-height: 24px;
                                                    }
                                                }

                                                @include media-breakpoint-down(sm) {
                                                    display: block !important;
                                                }

                                                @media screen and (max-width:393px) {
                                                    margin-bottom: 10px;
                                                }
                                            }
                                        }

                                        .button {
                                            order: 2;

                                            a {
                                                position: relative;
                                                border-radius: 25px;
                                                font-size: 14px;
                                                font-weight: 500;
                                                font-family: $font-inter;
                                                padding-left: 1rem !important;
                                                padding-right: 1rem !important;

                                                &::after {
                                                    content: url('/img/icons/arrow_forward-white.svg');
                                                    position: relative;
                                                    top: 4px;
                                                    margin-left: 4px;
                                                }

                                                @media screen and (max-width: 576px) {
                                                    padding-left: 0.5rem !important;
                                                    padding-right: 0.5rem !important;
                                                }

                                                @media screen and (max-width: 430px) {
                                                    font-size: 12px;
                                                }

                                                @media screen and (max-width: 393px) {
                                                    font-size: 11px;
                                                    padding: .2rem .3rem .3rem .3rem !important;
                                                }

                                                @media screen and (max-width: 280px) {
                                                    font-size: 10px;

                                                    &::after {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    figure {
                                        flex: 0 0 60%;
                                        position: relative;
                                        border-radius: 0 0 0 75px;
                                        z-index: 0;

                                        @include media-breakpoint-down(md) {
                                            flex: 0 0 50%;
                                        }

                                        @media screen and (max-width: 430px) {
                                            flex: 0 0 45%;
                                        }

                                        &::before {
                                            content: "";
                                            display: block;
                                            width: 100%;
                                            position: absolute;
                                            height: 100%;
                                            max-height: 380px;
                                            border-left: 18px solid $primary-color;
                                            border-radius: 0 0 0 75px;
                                            z-index: 1;
                                        }

                                        img {

                                            transform: translate3d(-45%,-50%,0) scale(1.2);
                                            image-rendering: optimizeQuality;
                                            transform-style: preserve-3d;

                                            // @media screen and (max-width: 1160px) {
                                            //     transform: translate3d(-45%,-50%,0) scale(1.1);
                                            // }

                                            @media screen and (max-width: 1070px) {
                                                transform: translate3d(-45%,-50%,0) scale(1.25);
                                            }

                                            @media screen and (max-width: 1025px) {
                                                transform: translate3d(-45%,-50%,0) scale(1.3);
                                            }

                                            @media screen and (max-width: 991px) {
                                                transform: translate3d(-25%,-50%,0) scale(1.7);
                                            }

                                            @media screen and (max-width: 935px) {
                                                //transform: translate3d(-15%,-50%,0) scale(1.7);
                                                transform: translate3d(-10%,-50%,0) scale(1.9);
                                            }

                                            @media screen and (max-width: 845px) {
                                                //transform: translate3d(-10%,-50%,0) scale(1.9);
                                                transform: translate3d(5%,-50%,0) scale(2.2);
                                            }

                                            @media screen and (max-width: 810px) {
                                                //transform: translate3d(5%,-50%,0) scale(2.2);
                                                transform: translate3d(-25%,-50%,0) scale(1.5);
                                            }

                                            @media screen and (max-width: 768px) {
                                                transform: translate3d(-25%,-50%,0) scale(1.5);
                                            }

                                            @include media-breakpoint-down(sm) {
                                                width: auto;
                                                max-width: none;
                                                transform: translate3d(-2%,-2%,0) scale(1.1);
                                                left: 1%;
                                                top: 1%;
                                                height: 100%;
                                            }

                                            @media screen and (max-width: 412px) {
                                                transform: translate3d(-5%,-2%,0) scale(1);
                                            }

                                            @media screen and (max-width: 390px) {
                                                transform: translate3d(-4%,-2%,0) scale(1.05);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .owl-nav {
                    .owl-prev,
                    .owl-next {
                        width: 40px;
                        height: 40px;
                        border-radius: 25px;
                        display: flex;
                        align-items: center;
                        background-color: $white;
                        border: 1px solid $input-border;
                        box-shadow: $shadow;

                        @media screen and (max-width: 576px) {
                            display: none;
                        }

                        i {
                            color: $text-color;
                            font-size: 14px;
                        }
                    }

                    .owl-prev {
                        left: 15px;
                    }

                    .owl-next {
                        right: 15px;
                    }
                }

                .owl-dots {
                    margin: -3% auto;
                    position: relative;
                    min-width: 10%;
                    max-width: 12%;
                    background-color: $white;
                    border-radius: 25px;
                    padding-top: 3px;

                    @media screen and (max-width: 1100px) {
                        margin-top: -4%;
                        
                    }

                    @include media-breakpoint-down(md) {
                        margin-left: 80%;
                    }

                    @media screen and (max-width: 875px) {
                        margin-top: -6%;
                        max-width: 14%;
                    }

                    @media screen and (max-width: 768px) {
                        max-width: 18%;
                    }

                    @media screen and (max-width: 596px) {
                        max-width: 21%;
                        margin-left: 76%;
                    }

                    @media screen and (max-width: 576px) {
                        height: 20px;
                        padding-top: 1px;
                    }

                    @media screen and (max-width: 517px) {
                        max-width: 25%;
                        margin-left: 72%;
                    }

                    @media screen and (max-width: 440px) {
                        margin-top: -8%;
                        margin-left: 64%;
                        max-width: 35%;
                    }

                    @media screen and (max-width: 360px) {
                        margin-top: -10%;
                        margin-left: 55%;
                        max-width: 40%;
                    }

                    @media screen and (max-width: 280px) {
                        margin-left: 50%;
                    }

                    .owl-dot {
                        
                        span {
                            width: 10px;
                            height: 10px;
                            background-color: $input-border;

                            @media screen and (max-width: 280px) {
                                width: 6px;
                                height: 6px;
                            }
                        }

                        &.active {
                            span {
                                background-color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
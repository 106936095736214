.page-category {
  position: relative;
  min-height: 900px;

  @media screen and (max-width: 1200px) {
    min-height: 750px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 500px;
  }

  @media screen and (max-width: 991px) {
    min-height: 350px;
  }

  .name-category {
    h1 {
      font-size: 32px;
      font-weight: 600;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }

    .tag-nb-product {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 24px;
      padding: 4px 8px;
      border-radius: 56px;
      background-color: $white;
      border: thin solid $input-border;
      color: $primary-color;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .sub-categories-wrapper {
    border-bottom: 1px solid $input-border;
    padding-bottom: 25px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .sub-categories {

      .line-sub-category {
        display: flex;
        justify-content: flex-start;
        transition: opacity 1s ease-in-out;

        &.closed {
          display: none;
          opacity: 0;
        }

        .sub-category {
          max-width: 24%;
          padding: 12px 16px;
          background-color: $white;
          border: thin solid $input-border;
          border-radius: 6px;
          box-shadow: $shadow;

          &:not(:last-child) {
            margin-right: 12px;

            @media screen and (max-width: 1214px) {
              margin-right: 10px;
            }

            @media screen and (max-width: 1013px) {
              margin-right: 8px;
            }
          }

          .img-wrapper {
            width: 32px;
            height: 32px;

            img {
              min-width: 32px;
              max-width: 32px;
              min-height: 32px;
              max-height: 32px;

              &[alt] {
                font-size: 7px;
                line-height: 10px;
              }
            }
          }

          h2 {
            font-size: 14px;
            font-weight: 500;
            color: $black;
          }
        }
      }
    }

    .display-more-cat {
      font-size: 12px;
      font-weight: 500;

      a {
        color: $text-color;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }

  .no-result-found {
    color: $text-color;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin: 50px 0;

    @media screen and (max-width: 375px) {
      margin: 30px 0;
    }
  }

  .products-area {
    aside {
      padding-left: 0 !important;

      &.filter-area-desktop {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &.filter-area-mobile {
        .buttons-wrapper {
          margin-bottom: 25px;

          .btn-wrapper {
            .filter-product-btn,
            .sort-product-btn {
              width: 100%;
              padding: 12px 16px;
              background-color: $white;
              border-radius: 1px;
              border: 1px solid $input-border;

              img {
                margin-right: 10px;
              }
            }

            .modal {
              .modal-dialog {
                @include media-breakpoint-down(md) {
                  max-width: 100vw;
                  width: 100vw;
                  margin-top: 0rem;
                  margin-left: 0;
                }

                @media screen and (max-width: 430px) {
                  margin-top: 0;
                }

                .modal-content {
                  position: absolute;
                  top: 0;
                  min-height: 100vh;
                  border-radius: 0;
                  border: none;

                  .modal-header {
                    border-bottom: none;

                    .modal-title {
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 24px;
                      color: $text-color;
                    }
                  }

                  .modal-body {
                    padding: 0;
                  }
                }
              }
            }

            #sortProductModal {
              .modal-header {
                border-bottom: 1px solid $input-border;
              }

              .modal-body {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                min-height: 70vh;
                max-height: 70vh;
                padding: 20px !important;

                .form-check-wrapper {
                  width: 100%;
                  margin-bottom: auto;

                  .form-check {
                    padding-bottom: 5px;

                    label {
                      margin-left: 5px;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                      color: $text-color;
                    }
                  }
                }

                .sort-btns-wrapper {
                  width: 100%;
                  border-top: 1px solid $input-border;
                  padding-top: 15px;

                  .apply-sort,
                  .cancel {
                    width: 100%;
                    padding: 12px 16px;
                    margin-bottom: 10px;
                    height: 48px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                  }

                  .cancel {
                    border: 1px solid $primary-color;
                    color: $primary-color;
                  }
                }
              }
            }
          }
        }
      }
    }

    .products-list {

      &__header {
        font-size: 14px;
        flex-wrap: nowrap;

        .nb-products {
          font-weight: 500;
          color: $text-color;
          padding-right: 0;

          .nb-products-wrap {
            padding-right: 0;
          }

          .filter-tickets {
            display: flex;
            flex-wrap: wrap;

            .filter-ticket {
              height: 32px;
              padding: 6px 10px;
              margin-right: 5px;
              margin-bottom: 5px;
              background-color: $white;
              border: 1px solid $input-border;
              border-radius: 56px;
              font-size: 14px;
              line-height: 20px;

              &:hover {
                border-color: $body-secondary;
              }

              .close-filter {
                position: relative;
                top: -1px;
                width: 10px;
                margin-left: 5px;
              }
            }
          }
        }

        .sort-products,
        .display-products {
          label {
            font-weight: 500;
            color: $text-light-gray;
          }

          select {
            border: none;
            background-color: transparent;
            font-weight: 500;
            padding-top: 2px;
            margin-top: -5px;
            padding-right: 0;
            padding-left: 4px;
            font-size: 14px !important;

            &:focus {
              box-shadow: none;
            }

            option {
              background-color: $white;
            }
          }
        }

        .sort-products {
          justify-content: end;
          padding-right: 10px;

          @media screen and (max-width: 1265px) {
            padding-left: 0;
          }

          select {
            width: 70%;
            @media screen and (max-width: 1225px) {
              width: 68%;
            }

            @media screen and (max-width: 1199px) {
              width: 62%;
            }
          }
        }

        .display-products {
          justify-content: end;
          padding-right: 0;

          @media screen and (max-width: 1035px) {
            padding-left: 0;
          }

          label {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              left: -10px;
              display: block;
              width: 1px;
              height: 24px;
              background-color: $input-border;

              @media screen and (min-width: 1200px) {
                left: -20px;
              }
            }
          }

          select {
            width: 58%;
          }
        }
      }

      &__content {
        .product-overview {
          width: 25%;

          @include media-breakpoint-down(md) {
            width: 33.3%;
          }

          @include media-breakpoint-down(sm) {
            width: 49.9%;
          }

          @media (max-width: 575.98px) {
            width: 100%;
          }

          &.bb-content.content {
            margin: 0;
          }

          .card-body {
            .quantity {
              @media screen and (max-width: 575.98px) {
                width: 75%;
              }

              .input-group-btn {
                position: relative;

                .subst-prod {
                  position: relative;
                  z-index: 1;

                  .glyphicon-minus {
                    position: relative;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                  }
                }

                .add-prod {
                  position: relative;
                  z-index: 1;

                  .glyphicon-plus {
                    position: relative;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;

                    &.added-in-shopping-card {
                      background-color: $primary-color;
                      color: $white;
                    }
                  }
                }
              }

              .nb-product {
                position: relative;
                z-index: 0;
                width: 44%;
                max-width: 44%;
                padding: 0px;

                @media screen and (max-width: 575.98px) {
                  width: 70%;
                  max-width: 70%;
                }

                @media screen and (max-width: 360px) {
                  width: 50%;
                  max-width: 50%;
                }

                &.added-in-shopping-card {

                  @media screen and (max-width: 575.98px) {
                    width: 70%;
                    max-width: 70%;
                  }

                  @media screen and (max-width: 360px) {
                    width: 50%;
                    max-width: 50%;
                  }
                }
              }
            }

            .add-shop {
              width: 40px;
              height: 40px;

              img {
                width: 40px;
                height: 40px;
                max-width: 40px;
                height: 40px;
              }
            }

            .tooltip-custom {
              box-shadow: 1px 1px 4px 0px #17172F2B;
              width: 233px;
              max-width: 233px;

              @media screen and (min-width: 1300px) and (max-width: 1399px) {
                left: -40px;
              }

              @media screen and (min-width: 1200px) and (max-width: 1299px) {
                left: -55px;
              }

              @media screen and (min-width: 1131px) and (max-width: 1199px) {
                left: -70px;
              }

              @media screen and (min-width: 992px) and (max-width: 1130px) {
                left: -85px;
              }

              @media screen and (max-width: 430px) {
                left: 15% !important;
              }

              @media screen and (max-width: 360px) {
                left: 5% !important;
              }

              @media screen and (max-width: 340px) {
                left: 0% !important;
              }

              .list-group-item {
                .nb-stock {
                  padding-right: 3px;
                }
              }
            }
          }
        }
      }

      .pagination-wrapper {
        margin: auto;

        .pagination {
          @media screen and (max-width: 575px) {
            position: relative;
          }

          a[aria-label="Previous"],
          a[aria-label="Next"] {
            @media screen and (max-width: 575px) {
              padding-top: 5px;
            }

            @media screen and (max-width: 430px) {
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
}
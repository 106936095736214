#addProductInComparatorModal {
    background-color: rgba(0, 0, 0, 0.2);
    padding-right: 0 !important;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .modal-header {
        .modal-title {
            font-size: 16px;
            font-weight: 600;
        }
    }

    .modal-body {
        .indication-four-products, 
        .empty-comparator {
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
            color: $text-color;
        }
        
        .indication-four-products {
            margin-top: 15px;
            margin-bottom: 30px;
        }

        .empty-comparator {
            margin-top: 45px;
            margin-bottom: 85px;
        }

        .products-comparator-wrapper {
            .product-comparator {
                position: relative;
                width: 24%;
                min-width: 24%;
                max-width: 24%;
                padding: 15px;

                &:not(:first-of-type) {
                    border-left: thin solid $input-border;
                }

                .delete-of-comparator {
                    //text-align: center;
                    position: absolute;
                    right: 15px;
                    top: 15px;

                    .delete-btn {
                        // padding-left: 0;
                        // padding-right: 0;
                        // margin-bottom: 20px;
                        padding: 0.5rem;
                        border: thin solid $input-border;

                        span {
                            display: block;
                            width: 16px;
                            height: 19px;
                            background-image: url('/img/icons/delete.svg');
                            background-repeat: no-repeat;
                            background-position: 50%;

                            &:hover {
                                background-image: url('/img/icons/delete_black.svg');
                            }
                        }
                    }
                }

                .product-photo-wrapper {
                    max-height: 150px;
                    height: 150px;
                    //min-height: 150px;
                    margin-bottom: 20px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: center;

                    @include media-breakpoint-down(md) {
                        max-height: 100px;
                        height: 100px;
                        min-height: 100px;
                    }

                    img {
                        max-height: 100%;
                        width: auto;
                    }
                }

                .product-designation {
                    max-height: 38.4px;
                    min-height: 38.4px;
                    height: 38.4px;
                    margin-bottom: 10px;
                    overflow: hidden;

                    p {
                        font-size: 13px;
                        font-weight: 600;
                        display: -webkit-box;
                        display: -moz-box;
                        display: box;
                        max-height: 33.4px;
                        min-height: 33.4px;
                        height: 33.4px;
                        -webkit-line-clamp: 2;
                        box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        
                        a {
                            color: $text-color;
                        }
                    }
                }

                .product-brand {
                    margin-bottom: 10px;
                    max-height: 34.4px;
                    min-height: 34.4px;
                    height: 34.4px;
                    overflow: hidden;

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        color: $text-light-gray;
                        display: -webkit-box;
                        display: -moz-box;
                        display: box;
                        max-height: 31.4px;
                        min-height: 31.4px;
                        height: 31.4px;
                        -webkit-line-clamp: 2;
                        box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .modal-footer {
        border-top: none;

        .buttons-wrapper-action {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                gap: 10px;
            }

            .clear-comparator,
            .confirm-comparate-product {
                width: 48%;
                padding: 12px 0px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
            }

            .clear-comparator {
            border: 1px solid $input-border;

                &:hover {
                    background-color: $background-secondary;
                }
            }

            .confirm-comparate-product {
                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }  
    }
}
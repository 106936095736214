.main-menu {
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 500;

    @include media-breakpoint-down(lg) {
        padding: 10px 16px;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
    
    ul {
        .item-main-menu {
            a {
                color: $banner-nav-link;
            }
        }
    }
}
.page-product {
  h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .control-prev-products,
  .control-next-products,
  .control-prev-similary-products,
  .control-next-similary-products {
    position: absolute;
    top: calc(50% - 20px);
    padding: 5px;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    background-color: $white;
    border: 1px solid $input-border;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    span {
      display: inline-block;
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
    }
  }

  .control-prev-products,
  .control-prev-similary-products {
    left: -20px;

    .control-prev-products-icon {
      background-image: url("/img/icons/chevron_left.svg");
    }
  }

  .control-next-products,
  .control-next-similary-products {
    right: -20px;

    .control-next-products-icon {
      background-image: url("/img/icons/chevron_right.svg");
    }
  }


  .associated-products {
    margin-bottom: 25px;
  }

  .associated-products-list {
    &::-webkit-scrollbar {
      /* Works on Chrome, Edge, and Safari */
      display: none;
    }

    /* Works on Firefox */
    scrollbar-width: none;
  }

  .associated-products-list,
  .similary-products-list {
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0;
    // height: 443px;
    // max-height: 443px;
    border: 1px solid $input-border;
    border-radius: 6px;

    .horizontal-scroll-wrapper {
      height: 100%;
      padding: 0;
      flex-wrap: nowrap;
      touch-action: pan-x;

      .product-overview {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        max-width: 228px;
        width: 228px;
        min-width: 228px;

        .card {
          height: 100%;

          .card-body {
            .product {
              &__price {
                .eco-part {
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 16px;
                  color: $text-light-gray;
                }
              }

              &__shop-actions {
                @media screen and (max-width: 575.98px) {
                  min-height: 65px;
                }
              }

              &__availibility {
                .tooltip-custom.visible {
                  bottom: 30px;
                  top: auto;

                  @media screen and (max-width: 375px) {
                    left: 0;
                  }

                  @media screen and (max-width: 330px) {
                    left: -10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .slider-container {
    @include media-breakpoint-up(lg) {
      max-width: 58.33333333% !important;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  .info-container {
    &__brand {
      .brand-name {
        font-size: 13px;
        font-weight: 600;
        color: $text-light-gray;
      }
    }

    &__name {
      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        color: $text-color;
      }
    }

    &__reference {
      .ref-fab {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        color: $text-color;
      }

      .ref-calvez-code-article {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $text-color;

        @include media-breakpoint-down(sm) {
          display: flex;
          flex-direction: column;
        }

        span {
          &:first-of-type {
            &::after {
              content: ".";
              display: inline-block;
              margin: 0 8px;
              transform: translateY(-5px);
              -webkit-transform: translateY(-5px);
              -moz-transform: translateY(-5px);
              -o-transform: translateY(-5px);

              @include media-breakpoint-down(sm) {
                  display: none;
              }
            }
          }
        }
      }
    }

    &__price {
      .price {
        .price-main,
        .price-cents,
        .price-change,
        .price-tax {
          color: $text-color;
        }

        .price-main,
        .price-tax {
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }

        .price-cents,
        .price-change {
          display: inline-block;
          padding-top: 2px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          vertical-align: top;
        }

        .price-cents {
        //   margin-left: -6px;
        }

        .price-eco-part {
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .price-reco {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }

      .uv {
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
      }
    }

    &__availability {
      .stock {

        &.active {
            cursor: pointer;
        }

        .color-stock {
          width: 14px;
          height: 14px;
          max-width: 14px;
          max-height: 14px;
          min-width: 14px;
          min-height: 14px;

          img {
            width: 14px;
            height: 14px;
            max-width: 14px;
            max-height: 14px;
            min-width: 14px;
            min-height: 14px;
          }
        }

        .item-stock {
          font-size: 12px;
          font-weight: 500;
          color: $text-color;
        }

        .tooltip-stock {
          cursor: pointer;

          img {
            width: 15px;
            height: 15px;
            max-width: 15px;
            max-height: 15px;
            min-width: 15px;
            min-height: 15px;
          }
        }
      }

      .tooltip-custom {
        position: absolute;
        top: 25px;
        left: 5px;
        z-index: 1;
      }
    }

    &__shop-list {
      @include media-breakpoint-down(md) {
        margin-bottom: 1.75rem !important;
      }

      .fds {
        padding: 12px 16px;
        background-color: $primary-color;
        border: 1px solid $input-border;
        border-radius: 50px;
        transition: background-color .4s ease-in-out;

        @media screen and (max-width: 575px) {
          width: 100%;
          text-align: center;
        }

        a {
          font-size: 14px;
          font-weight: 500;
          //line-height: 24px;
          text-align: center;
          color: $white;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          background-color: $primary-color-hover;
        }
      }

      .product-quantity {

        @media screen and (max-width: 576px) {
          padding-left: 0;
          padding-right: 0;
        }

        .input-group {

          .input-group-btn {
            width: 20%;

            &.added-in-shopping-card {
              width: 12.5%;
            }

            .subst-prod,
            .add-prod {
              background-color: $white;
              width: 100%;
              height: 48px;

              &:focus {
                outline: none;
                box-shadow: none;
              }

              &.added-in-shopping-card {
                width: 100%;
              }

              span {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                border-radius: 50%;

                @media screen and (max-width: 345px) {
                  width: 28px;
                  height: 28px;
                }
              }
            }

            .subst-prod {
              padding-left: 0.75rem;
              padding-right: 1rem;
              border-radius: 25px 0 0 25px;
              border-left: 1px solid $input-border;
              border-top: 1px solid $input-border;
              border-bottom: 1px solid $input-border;
              display: flex;
              justify-content: start;
              align-items: center;

              @include media-breakpoint-up(lg) {
                padding-right: 0;
              }

              @media screen and (max-width: 545px) {
                padding-left: 0.25rem;
              }

              @media screen and (max-width: 465px) {
                padding-right: 0;
              }
            }

            .add-prod {
              padding-left: 1rem;
              padding-right: 0.75rem;
              border-radius: 0 25px 25px 0;
              border-right: 1px solid $input-border;
              border-top: 1px solid $input-border;
              border-bottom: 1px solid $input-border;
              display: flex;
              justify-content: end;
              align-items: center;

              @include media-breakpoint-up(lg) {
                padding-left: 0;
              }

              @media screen and (max-width: 545px) {
                padding-right: 0.25rem;
              }

              @media screen and (max-width: 465px) {
                padding-left: 0;
              }

              .glyphicon-plus {
                &.added-in-shopping-card {
                  border-color: $primary-color;
                  background-color: $primary-color;
                  color: $white;
                }
              }
            }
          }

          .nb-product {
            width: 55%;
            max-width: 55%;
            height: 48px;
            text-align: center;
            background-color: $white;
            border-top: 1px solid $input-border;
            border-bottom: 1px solid $input-border;
            border-left: none;
            border-right: none;
            font-size: 13px;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            &.added-in-shopping-card {
              width: 75%;
              max-width: 75%;
            }

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                opacity: 0;
                margin: 0;
            }
            -moz-appearance: textfield;
                /* Firefox */


            &:hover,
            &:focus {
                -moz-appearance: number-input;
            }
          }
        }
      }

      .add-shop-list {
        @media screen and (max-width: 340px) {
          padding-right: 0;
        }

        &.user-no-logged {
          text-align: center;
          font-size: 12px;
          background-color: rgba(255,255,255,0.25);
          border-radius: 6px;
          padding: 25px;
          box-shadow: $shadow;

          p.title {
            margin-bottom: 10px;
            font-size: 16px;
            color: $text-color;
            font-weight: 500;
            text-align: center;
          }

          a.login a.btn, .add-shop-list {
            // display: block;
            // margin: auto;
            // font-size: 14px;
            // text-align: center;
            // color: $body-secondary;

            p.indication {
                display: block;
                margin: auto;
                font-size: 14px;
                text-align: center;
                color: $body-secondary;
                  font-weight: 400;
              line-height: 16px;
            }
          }
        }

        .add-shopping-card {
          width: 100%;
          padding: .275rem 2.25rem;
          height: 46px;
          border-radius: 25px;
          font-size: 14px;

          @media screen and (max-width: 575px) {
            padding-left: 12px;
            padding-right: 12px;
          }

          @media screen and (max-width: 340px) {
            padding-left: 8px;
            padding-right: 8px;
          }
        }
      }
    }

    &__functionalities-prod {
      @include media-breakpoint-up(lg) {
        justify-content: end !important; // à retirer au retour du comparateur
      }

      .favorite,
      .comparator {
        font-size: 14px;
        color: $text-color;
        font-weight: 500;

        a,
        a:hover {
          cursor: pointer;
        }

        a:hover {
          color: $primary-color !important;
        }
      }

      .favorite {
        // L'accès au comparateur étant commenté | à décommenter au retour du comparateur
        //border-right: thin solid $input-border;
        //text-align: end;
        text-align: center; // à retirer au retour du comparateur

        @include media-breakpoint-down(sm) {
          text-align: center;
        }

        .add-favorite {
          position: relative;
          margin-left: 25px;


          &::before {
            content: url('assets/img/account/favorite.svg');
            position: absolute;
            left: -28px;
            top: -1px;
          }

          &:hover {
            &::before {
              content: url('assets/img/account/favorite.svg');
              filter: contrast(200%);
              transform: scale(1.1);
            }
          }

          a {
            color: $text-color;

            &.disabled {
              padding: 0;
              border: none;
              display: inline;
              font-size: 14px;
              vertical-align: baseline;
            }
          }
        }

        .favorite-remove {
          padding-right: 15px;
        }
      }

      .comparator {
        @media screen and (min-width: 992px) and (max-width: 1038px) {
          padding-right: 0;
        }

        @include media-breakpoint-down(sm) {
          margin: 20px 0;
          text-align: center;
        }

        .add-comparator {
          position: relative;
          margin-left: 30px;

          @media screen and (max-width: 1065px) {
            margin-left: 25px;
          }

          @media screen and (min-width: 992px) and (max-width: 1038px) {
            margin-left: 22px;
          }

          &::before {
            content: url('assets/img/icons/add-compare_arrows.svg');
            position: absolute;
            left: -25px;
            top: 2px;

            // @include media-breakpoint-down(sm) {
            //     position: relative;
            //     left: -2px;
            // }
          }

          &:hover {
            &::before {
              content: url('assets/img/icons/add-compare_arrows.svg');
              filter: contrast(200%);
              transform: scale(1.1);
            }
          }

          a {
            color: $text-color;

            &.disabled {
              padding: 0;
              border: none;
              display: inline;
              font-size: 14px;
              vertical-align: baseline;
            }
          }
        }
      }

      .modal {
        .modal-header {
          .modal-title {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .modal-footer {
          border-top: none;

          .buttons-wrapper-save {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            @include media-breakpoint-down(sm) {
              flex-direction: column;
              gap: 10px;
            }

            .cancel,
            .confirm-save-product {
              width: 48%;
              padding: 12px 0px;
              border-radius: 25px;
              font-size: 14px;
              font-weight: 500;
              text-align: center;

              @include media-breakpoint-down(sm) {
                width: 100%;
              }
            }

            .cancel {
              border: 1px solid $input-border;

              &:hover {
                background-color: $background-secondary;
              }
            }

            .confirm-save-product {
              &:hover {
                background-color: $primary-color-hover;
              }
            }
          }

          // .confirmation-add-estimate-basket-msg {
          //     .done-added-basket,
          //     .done-backup-basket,
          //     .fail {
          //         display: none;
          //         margin-top: 15px;
          //         border-radius: 6px;
          //         padding: 10px 15px;
          //         border: 1px solid $input-border;
          //         justify-content: center;
          //         align-items: flex-start;
          //         box-shadow: $shadow;

          //         p {
          //             font-size: 14px;
          //             font-weight: 500;
          //             line-height: 24px;
          //             color: $text-color;

          //             @media screen and (max-width:430px) {
          //                 margin-left: 15px;
          //             }
          //         }

          //         img {
          //             width: 25px;
          //             margin-right: 8px;

          //             @media screen and (max-width:425px) {
          //                 margin-top: 5px;
          //                 margin-right: 5px;
          //             }
          //         }

          //         &.visible {
          //             display: flex;
          //         }
          //     }
          // }
        }
      }
    }
  }

  .accordion-container {
    @include media-breakpoint-down(md) {
      margin-top: 50px;
    }

    .accordion-product {
      .nav-link {
        @media screen and (max-width: 339px) {
          width: 100%;
        }
      }
    }
  }

  .associated-products,
  .similary-products {
    .quantity {
      .input-group-btn {
        .add-prod {
          display: flex;
          justify-content: end;
        }
      }
    }
  }
}
.order-confirmation,
.order-error {
    .message-container {
        padding: 75px 15px 125px;

        .img-wrapper {
            width: 64px;
            height: 64px;
            max-width: 64px;
            max-height: 64px;
            min-width: 64px;
            min-height: 64px;

            img {
                width: 64px;
                height: 64px;
                max-width: 64px;
                max-height: 64px;
                min-width: 64px;
                min-height: 64px;
            }
        }

        h1 {
            margin: 15px 0 10px; 
            font-size: 32px;
            font-weight: 600;
            line-height: 40px; 

            @media screen and (max-width: 576px) {
                font-size: 18px;
            }
        }

        .description {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $body-secondary;
        }

        .back-to-home {
            margin-top: 25px;
            border-radius: 25px;
            padding: 0.75rem 1rem;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
        }
    }
}
.autoblock-vertical {
    .image-text-vertical {
        position: relative;
        padding: 2rem 0;
    
        &:not(:last-of-type) {
            border-bottom: 1px solid $input-border;
        }
    
        .col-md-8 {
           .published-at-and-tag-wrap {
                align-items: center;
                margin-bottom: 5px;
    
                @include media-breakpoint-down(sm) {
                    margin-top: 15px;
                }
    
                .metadata-wrapper {
                    position: relative;
                    margin-top: 0;
                    margin-right: 5px;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-color;
                }
    
                .tag {
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 20px;
                    color: $text-light-gray;
    
                    &::before {
                        content: "";
                        display: inline-block;
                        margin: 0 .5em;
                        width: 5px;
                        height: 5px;
                        background-color: $text-color;
                        border-radius: 50%;
                        vertical-align: middle;
                    }
    
                    a {
                        transition: color .2s ease-in-out;
                        
                        &:hover {
                            color: $primary-color;
                        }
                    }
                }
            }
            
            h3 {
                a {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 26px;
                    color: $text-color;
                    transition: color .2s ease-in-out;
    
                    &:hover {
                        color: $primary-color;
                        text-decoration: none;
                    }
                }
            }
    
            .abstract {
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: $text-color;
    
                @include media-breakpoint-down(sm) {
                    margin-top: 1rem;
                }
            }
        }
    }

    .image-text-horizontal {
        padding: 2rem 0;

        &:not(:last-of-type) {
            border-bottom: 1px solid $input-border;
        }

        @media screen and (max-width: 575px) {
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
        }

        figure {
            @media screen and (max-width: 575px) {
                margin-bottom: 1.25rem;
            }
        }

        h3 {
            a {
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                color: $text-color;
                transition: color .2s ease-in-out;

                &:hover {
                    color: $primary-color;
                    text-decoration: none;
                }
            }
        }

        .metadata-wrapper {
            position: relative;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            color: $text-color;
        }

        .abstract {
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: $text-color;

            // @include media-breakpoint-down(sm) {
            //     margin-top: 1rem;
            // }
        }
    }

    .text-image-vertical {
        &:not(:last-of-type) {
            border-bottom: 1px solid $input-border;
        }

        .col-md-8 {
            h3 {

                @media screen and (max-width: 575px) {
                    margin-bottom: 10px; 
                }

                a {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 26px;
                    color: $text-color;
                    transition: color .2s ease-in-out;
    
                    &:hover {
                        color: $primary-color;
                        text-decoration: none;
                    }
                }
            }

            .abstract {
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
                color: $text-color;
    
                @include media-breakpoint-down(sm) {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }

            .metadata-wrapper {
                position: relative;
                margin-top: 0;
                margin-right: 5px;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                color: $text-color;
            }

            .published-at-and-tag-wrap {
                .metadata-wrapper {
                    position: relative;
                    //margin-top: 0;
                    //margin-right: 5px;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-color;
                }
            }
        }
    }
}
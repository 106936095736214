.user-invoices {
    .main-user-invoices {
        padding: 15px;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        @media screen and (max-width: 430px) {
            padding: 15px 5px;
        }

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }
        }

        .invoices-list-wrapper {
            .invoice-wrapper {
                margin-bottom: 15px;
                padding: 20px 5px;
                background-color: $white-color-10;
                border: $input-border;
                border-radius: 6px;
                box-shadow: $shadow; 

                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                }

                .invoice-information {
                   .number-invoice,
                   .total-invoice {
                        margin-top: 10px;
                   }
                   
                   .number-invoice {
                    font-weight: 600;
                   }
                }

                .invoice-action-wrapper {
                    display: flex;
                    align-items: end;

                    @media screen and (max-width: 767px) {
                        margin: 25px 0 5px;
                    }

                    .download-invoice {
                        width: 100%;
                        padding: 8px 0px;
                        border-radius: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
        }
    }
}
.breadcrumb-navigation,
.breadcrumb-back,
.breadcrumb-all-categorie {
    .breadcrumb {
        background-color: transparent;
        font-size: 12px;
        padding-left: .5rem;

        .breadcrumb-item {

            &:nth-child(2)::before {
                content: "|";
            }
            
            &.come-back {
                a {
                    color: $primary-color;
                    font-weight: 500;
                    
                    &::before {
                        content: url("assets/img/icons/arrow_back.svg");
                        position: relative;
                        left: -8px;
                        margin-right: 4px;
                    }
                } 
            }

            &.active {
                a {
                    color: $text-light-gray;
                }
            }

            a {
                text-decoration: none;
                color: $text-color;
            }
        }
    }
}
.user-estimate-detail {
    position: absolute;
    top: 0;
    right: -100vw;
    background-color: $background-secondary;
    height: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity .15s linear;

    &.open {
        left: 0;
        right: unset;
        opacity: 1;
    }

    .back-to-estimates-list {
        button {
            position: relative;
            font-size: 14px;
            color: $primary-color;
            padding-bottom: 15px;
            padding-left: 1.5rem;

            &::before {
                content: url('/img/icons/arrow_back.svg');
                position: absolute;
                left: 0px;
                top: 7px;
            }

            &:hover {
                color: $text-color;
            }
        }
    }

    .main-detail-estimate {
        .bg-modal {
            display: none;
            opacity: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.5);
            z-index: 3;

            &.open {
                display: block !important;
                opacity: unset;
            }

            &.other-open {
                background-color: rgba(0,0,0,0.75);
            }
        }

        .estimate-wrapper {
            padding-left: 25px !important;
            padding-right: 25px !important;

            @media screen and (max-width: 575px) {
                padding: 20px !important;
            }

            .estimate-information {
                margin-bottom: 0 !important;
            }

            .estimate-action-wrapper {
                .estimate-status {
                    text-align: right !important;

                    @media screen and (max-width: 767px) {
                        text-align: left !important;
                        margin-top: 10px;
                    }
                }
            }
        }

        .recap-estimated-articles {
            margin-top: 25px;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px; 
            }

            .product-line-header {
                border-bottom: 1px solid $input-border;
                padding-bottom: 8px;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                div {
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: 600;

                    // @media screen and (max-width: 1153px) {
                    //     font-size: 9px;
                    // }

                    &.pu-ht-head,
                    &.quantity-head,
                    &.total-ht-head {
                        text-align: right;
                    }

                    &.quantity-head {
                        @media screen and (min-width: 992px) {
                            padding-left: 0;
                        }
                    }

                    &.articles-head {
                        padding-left: 15.5px;
                    }
                }
            }

            .product-line {

                &:not(:last-of-type) {
                    border-bottom: 1px solid $input-border;

                    @include media-breakpoint-down(md) {
                        border-bottom-width: 2px;
                    }
                }

                .product-line-header-mobile {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid $input-border;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;

                    div {
                        &.articles-head {
                            @media screen and (max-width: 991px) {
                                padding-left: 0;
                            } 
                        }

                        &.pu-ht-head {
                            @media screen and (max-width: 991px) {
                                padding-left: 0;
                            } 
                        }

                        &.quantity-head {
                            @media screen and (max-width: 430px) {
                                text-align: center;
                            } 
                        }

                        &.total-ht-head {
                            @media screen and (max-width: 430px) {
                                text-align: right;
                                padding-right: 0;
                            }
                        }
                    }
                }

                .picture-product {
                    @media screen and (max-width: 575px) {
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }

                    .img-wrapper {
                        max-width: 112px;
                        max-height: 112px;
                        width: 112px;
                        height: 112px;
                        text-align: center;

                        @media screen and (max-width: 1024px) {
                            max-width: 100px;
                            max-height: 100px;
                            width: 100px;
                            height: 100px; 
                        }

                        @media screen and (max-width: 575px) {
                            margin: auto;
                            max-width: 150px;
                            max-height: 150px;
                            width: 150px;
                            height: 150px;
                        }

                        img {
                            width: auto;
                            height: 112px;
                            max-height: 112px;

                            @media screen and (max-width: 1024px) {
                                height: 100px;
                                max-height: 100px;
                            }

                            @media screen and (max-width: 575px) {
                                height: 150px;
                                max-height: 150px;
                            }
                        }
                    }
                }

                .description-product {

                    .brand-name {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 500;
                        color: $text-light-gray;
                    }

                    .name-product {
                        a {
                            font-size: 13px;
                            line-height: 20px;
                            font-weight: 600;
                            color: $text-color;
                        }
                    }

                    .ref-brand {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: 400;
                        color: $text-light-gray;
                    }
                }

                // .brand-product {
                //     .brand-name {
                //         font-size: 13px;
                //         line-height: 20px;
                //         font-weight: 500;
                //         color: $text-light-gray;

                //         @media screen and (max-width: 430px) {
                //             text-align: right;
                //         }
                //     }
                // }

                .quantity-product {
                    padding-left: 0;
                    padding-right: 0;

                    .quantity-wrapper {
                        @media screen and (max-width: 1024px) {
                            padding-left: 0;
                            padding-right: 0;
                        }

                       .unit-price,
                       .nb-quantity,
                       .total-one-product {
                            p {
                                text-align: right;

                                @include media-breakpoint-down(md) {
                                    text-align: left;
                                }

                                @media screen and (max-width: 350px) {
                                    font-size: 13px;
                                }

                                @media screen and (max-width: 329px) {
                                    font-size: 12px;
                                }
                            }
                        } 

                        .unit-price {
                            @include media-breakpoint-up(lg) {
                                justify-content: end;
                                padding-right: 15px;
                            }

                            @media screen and (max-width: 430px) {
                                padding-right: 0;
                            }
                        }

                        .nb-quantity {
                            @include media-breakpoint-up(lg) {
                                padding-right: 0;
                            }

                            @media screen and (max-width: 430px) {
                                .quantity {
                                    text-align: center;
                                }
                            }
                        }
                       
                        .total-one-product {
                            padding-left: 0;
                            padding-right: 0;

                            @include media-breakpoint-down(md) {
                                padding-left: 7.5px;
                            }

                            @media screen and (max-width: 430px) {
                                padding-right: 7.5px;
                                padding-left: 0;

                                .rate-tot-one-product {
                                    text-align: right;
                                }
                            }
                        }  
                    }
                }
            }  
        }

        .total-amount-estimate {
            display: flex;
            justify-content: end;
            border-top: 1px solid $input-border;

            table {
                width: 50%;
                margin-top: 1rem;

                @media screen and (max-width: 900px) {
                    width: 60%;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }

                th, tr, td {
                    padding: 0.5rem;
                }

                .total {
                    font-weight: bold;
                }

                .item {
                    @media screen and (max-width: 991px) {
                        padding-left: 0;
                    }
                }

                .item-value {
                    text-align: right;
                    padding-right: 0;

                    @media screen and (max-width: 430px) {
                        min-width: 100px;
                    }
                }
            }
        }

        .btn-wrapper {
            border-top: 1px solid $input-border;
            padding-top: 25px;

            .add-btn {
                z-index: 1;
                position: relative;
                width: 100%;
                padding: 12px 16px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;

                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }

        .modal {
            .modal-content {
                @media screen and (max-width: 500px) {
                    max-width: 100vw;
                }
            }
            
            .modal-body {
                img {
                    display: block;
                    margin: 10px auto;
                }

                .advertisment {
                    margin: 10px 0;
                    color: $text-color;
                    font-size: 16px;
                    line-height: 25px;
                    text-align: center;

                    @media screen and (max-width: 576px) {
                        margin: 0;
                        font-size: 14px;
                    }
                }
            }

            .modal-footer {
                .buttons-wrapper-footer {
                    flex-direction: column !important;

                    .backup-estimate-basket,
                    .confirm-add-estimate-basket {
                        width: 100% !important;
                        padding: 5px 16px !important;
                        padding: 12px 0 !important;
                        transition: all .2s ease-in-out;
                    }

                    .backup-estimate-basket {
                        margin-bottom: 15px;
                        color: $text-color;
                        border: 1px solid $input-border;

                        &:hover {
                            background-color: $background-secondary-80;
                        }

                        @media screen and (max-width: 576px) {
                            margin-bottom: 10px;
                        }
                    }

                    .confirm-add-estimate-basket {
                        background-color: $primary-color;
                        color: $white;

                        &:hover {
                            background-color: $primary-color-hover;
                        }
                    }
                }

                .buttons-wrapper-save {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .cancel,
                    [class^='confirm-save-estimate-'] {
                        width: 48%;
                        padding: 12px 0px;
                        border-radius: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
                    }

                    .cancel {
                        border: 1px solid $input-border;

                        &:hover {
                            background-color: $background-secondary;
                        }
                    }

                    [class^='confirm-save-estimate-'] {
                        &:hover {
                            background-color: $primary-color-hover;
                        }
                    }
                }

                .confirmation-add-estimate-basket-msg {
                    .done-added-basket,
                    .done-backup-basket,
                    .fail {
                        display: none;
                        margin-top: 15px;
                        border-radius: 6px;
                        padding: 10px 15px;
                        border: 1px solid $input-border;
                        justify-content: center;
                        align-items: flex-start;
                        box-shadow: $shadow;
                        
                        p {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 24px;
                            color: $text-color;
        
                            @media screen and (max-width:430px) {
                                margin-left: 15px;
                            }
                        }
        
                        img {
                            width: 25px;
                            margin-right: 8px;

                            @media screen and (max-width:425px) {
                                margin-top: 5px;
                                margin-right: 5px;
                            }
                        }
        
                        &.visible {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
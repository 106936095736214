.shopping-list {
    margin-bottom: 75px;

    @include media-breakpoint-down(md) {
        margin-bottom: 45px;
    }

    &__header {
        @media screen and (max-width: 575px) {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            margin-right: 0;
        }

        .h1-wrapper {
            @media screen and (max-width: 333px) {
                padding-right: 0;
            }

            @media screen and (max-width: 280px) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            h1 {
                font-size: 32px;
                font-weight: 600;

                @media screen and (max-width: 576px) {
                    font-size: 18px;
                    margin-top: 3px;
                }
            }
        }

        .empty-shopping-list-btn {
            padding-top: 5px;

            @media screen and (max-width: 576px) {
                padding-top: 0;
                padding-right: 0;
            }

            @media screen and (max-width: 280px) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            .empty-shopping-list {
                padding: 0 0 0 15px;

                @media screen and (max-width: 280px) {
                    padding-left: 0;
                }

                .empty-list {
                    color: $body-secondary;
                    font-size: 14px;
                    font-weight: 400;

                    @media screen and (max-width: 280px) {
                        font-size: 12px;
                    }

                    &::before {
                        content: url('/img/icons/delete.svg');
                        width: 15px;
                        display: inline-block;
                        position: relative;
                        left: -10px;
                        top: 4px;

                        @media screen and (max-width: 280px) {
                            left: -4px;
                        }

                    }

                    &:hover {
                        color: $text-color;

                        &::before {
                            content: url('/img/icons/delete_black.svg');
                        }
                    }

                }
            }

            .modal {
                .modal-dialog {
                    .modal-content {
                        .modal-header {
                            border-bottom: 1px solid $input-border;
                            .modal-title {
                                font-size: 16px;
                                font-weight: 600;
                                color: $text-color;
                            }
                        }

                        .modal-body {
                            .empty-icon-wrap {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 12px;
                                margin-bottom: 15px;
                                width: 48px;
                                height: 48px;
                                border-radius: 50%;
                                background-color: $primary-color-10;
                            }

                            .empty-title {
                                margin-bottom: 10px;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 28px;
                                text-align: center;
                                color: $text-color;
                            }

                            .empty-text {
                                margin-bottom: 10px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 20px;
                                text-align: center;
                                color: $body-secondary;
                            }
                        }

                        .modal-footer {
                            justify-content: space-between;
                            border-top: none;

                            button {
                                width: 48%;
                                padding: 12px 16px;
                                border-radius: 25px;
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;

                                @media screen and (max-width: 439px) {
                                    width: 45%;
                                    padding: 12px 0px;
                                }

                                &.cancel {
                                    border: 1px solid $input-border;

                                    &:hover {
                                        background-color: $background-secondary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__content {
        @include media-breakpoint-down(md) {
            padding-right: 0 !important;
        }

        .products-container {

            .product-line {
                border-top: 1px solid $input-border;

                @media screen and (max-width: 575px) {
                    margin-left: 0;
                    margin-right: 0;
                }

                &:last-of-type {
                    border-bottom: 1px solid $input-border;
                }

                .picture-product {
                    .img-wrapper {
                        width: 112px;
                        height: 112px;
                        max-width: 112px;
                        max-height: 112px;

                        @media screen and (max-width: 345px) {
                            width: 100px;
                            height: 100px;
                            max-width: 100px;
                            max-height: 100px;
                        }

                        img {
                            width: auto;
                            height: 112px;
                            max-width: 112px;
                            max-height: 112px;

                            @media screen and (max-width: 345px) {
                                width: auto;
                                height: 100px;
                                max-width: 100px;
                                max-height: 100px;
                            }
                        }
                    }
                }

                .description-product {
                    padding-right: 0px;

                    @media screen and (min-width: 992px) and (max-width: 1125px) {
                        padding-left: 20px;
                    }

                    @media screen and (max-width: 412px) {
                        padding-right: 0;
                        padding-left: 15px;
                    }

                    @media screen and (max-width: 375px) {
                        padding-left: 25px;
                    }

                    @media screen and (max-width: 345px) {
                        padding-left: 30px;
                    }

                    .brand-product,
                    .name-product,
                    .ref-brand,
                    .ref-calvez {
                        font-size: 13px;
                        line-height: 20px;
                    }

                    .brand-product,
                    .ref-brand,
                    .ref-calvez {
                        color: $body-secondary;
                    }

                    .brand-product {
                        font-weight: 500;
                    }

                    .ref-brand,
                    .ref-calvez {
                        font-weight: 400;
                    }

                    .name-product {
                        font-weight: 600;
                        a {
                            color: $text-color;
                        }
                    }
                }

                .quantity-product {

                    .quantity-wrapper {
                        .unit-price {
                            display: flex;
                            justify-content: end;
                            align-items: center;
                            padding-left: 0;
                            padding-right: 5px;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 20px;
                            color: $body-secondary;
                        }

                        .btn-quantity {
                            @media screen and (max-width: 280px) {
                                flex: 0 0 90%;
                                max-width: 90%;
                            }

                            .input-group {
                                @include media-breakpoint-down(md) {
                                    width: 20%;
                                    margin: auto;
                                    padding-left: 0rem;
                                }

                                @include media-breakpoint-down(sm) {
                                    width: 32%;
                                    padding-left: 2.75rem;
                                }

                                @media screen and (max-width: 660px) {
                                    width: 36%;
                                }

                                @media screen and (max-width: 576px) {
                                    width: 44%;
                                    padding-left: 3.75rem;
                                }

                                @media screen and (max-width: 525px) {
                                    width: 48%;
                                    padding-left: 3.75rem;
                                }

                                @media screen and (max-width: 495px) {
                                    width: 54%;
                                    padding-left: 3.75rem;
                                }

                                @media screen and (max-width: 455px) {
                                    width: 68%;
                                    padding-left: 4.5rem;
                                }

                                @media screen and (max-width: 412px) {
                                    width: 74%;
                                    padding-left: 5.25rem;
                                }

                                @media screen and (max-width: 395px) {
                                    width: 90%;
                                    padding-left: 5.25rem;
                                }

                                @media screen and (max-width: 345px) {
                                    padding-left: 5.2rem;
                                }

                                @media screen and (max-width: 280px) {
                                    padding-left: 4rem;
                                }



                                .subst-prod,
                                .add-prod {
                                    background-color: $white;
                                    min-width: 30px;
                                    padding-left: 0.25rem;
                                    padding-right: 0.25rem;
                                    //height: calc(1.5em + .75rem + 1px);
                                    height: calc(1.5em + .75rem + 3px);

                                    span {
                                        width: 20px;
                                        height: 20px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border: 1px solid transparent;
                                        border-radius: 50%;
                                    }
                                }

                                .subst-prod {
                                    border-radius: 25px 0 0 25px;
                                    border-left: 1px solid $input-border;
                                    border-top: 1px solid $input-border;
                                    border-bottom: 1px solid $input-border;
                                }

                                .add-prod {
                                    border-radius: 0 25px 25px 0;
                                    border-right: 1px solid $input-border;
                                    border-top: 1px solid $input-border;
                                    border-bottom: 1px solid $input-border;
                                }

                                .nb-product {
                                    margin-top: 1px;
                                    //height: calc(1.5em + .75rem + 3px);
                                    height: calc(1.5em + .75rem + 1px);
                                    font-size: 13px;
                                    border: none;
                                    text-align: center;
                                    padding: 0;
                                    background-color: #fff;
                                }
                            }
                        }

                        .total-one-product {
                            display: flex;
                            justify-content: end;
                            align-items: center;
                            font-size: 13px;
                            font-weight: 600;
                            line-height: 20px;
                            color: $text-color;

                            @include media-breakpoint-down(md) {
                                justify-content: start;
                                margin-bottom: 10px;
                                padding-left: 33.5%;
                            }

                            @media screen and (max-width: 412px) {
                                padding-left: 36.5%;
                            }

                            @media screen and (max-width: 375px) {
                                padding-left: 40.5%;
                            }

                            @media screen and (max-width: 345px) {
                                padding-left: 42.5%;
                            }

                            .rate-tot-one-product {
                                @media screen and (min-width: 992px) {
                                    margin-right: 5px;
                                }
                            }

                        }

                         // Remove product btn for mobile view
                        .remove-product {
                            padding-left: 0;

                            @media screen and (max-width: 280px) {
                                flex: 0 0 10%;
                                max-width: 10%;
                            }

                            .icon-wrapper-remove {
                                margin-top: 5px;
                                text-align: right;

                                .remove-product-btn {
                                    padding: 0;
                                    float: left;
                                }

                                .modal {
                                    .modal-dialog {
                                        .modal-content {
                                            .modal-header {
                                                border-bottom: 1px solid $input-border;
                                                .modal-title {
                                                    font-size: 16px;
                                                    font-weight: 600;
                                                    color: $text-color;
                                                }
                                            }

                                            .modal-body {
                                                .remove-icon-wrap {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    padding: 12px;
                                                    margin-bottom: 15px;
                                                    width: 48px;
                                                    height: 48px;
                                                    border-radius: 50%;
                                                    background-color: $primary-color-10;
                                                }

                                                .remove-title {
                                                    margin-bottom: 10px;
                                                    font-size: 20px;
                                                    font-weight: 600;
                                                    line-height: 28px;
                                                    text-align: center;
                                                    color: $text-color;
                                                }

                                                .remove-text {
                                                    margin-bottom: 10px;
                                                    font-size: 14px;
                                                    font-weight: 400;
                                                    line-height: 20px;
                                                    text-align: center;
                                                    color: $body-secondary;
                                                }
                                            }

                                            .modal-footer {
                                                justify-content: space-between;
                                                border-top: none;

                                                button {
                                                    width: 48%;
                                                    padding: 12px 16px;
                                                    border-radius: 25px;
                                                    font-size: 14px;
                                                    font-weight: 500;
                                                    text-align: center;

                                                    @media screen and (max-width: 439px) {
                                                        width: 45%;
                                                        padding: 12px 0px;
                                                    }

                                                    &.cancel {
                                                        border: 1px solid $input-border;

                                                        &:hover {
                                                            background-color: $background-secondary;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // storage for desktop view
                    .storage-state {
                        text-align: center;

                        @include media-breakpoint-up(lg) {
                            padding-left: 0;
                            padding-right: 15%;
                        }

                        p {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 20px;
                            color: $text-color;

                            .color-stock {
                                display: inline-block;

                                img {
                                    margin-right: 5px;
                                    vertical-align: sub;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }

                // Remove product btn for desktop view
                .remove-product {
                    .icon-wrapper-remove {
                        margin-top: 1.25rem ;
                        text-align: right;

                        .remove-product-btn {
                            padding: 0;

                            span {
                                display: block;
                                width: 16px;
                                height: 19px;
                                background-image: url('/img/icons/delete.svg');
                                background-repeat: no-repeat;
                                background-position: center;

                                &:hover {
                                    background-image: url('/img/icons/delete_black.svg');
                                }
                            }
                        }

                        .modal {
                            .modal-dialog {
                                .modal-content {
                                    .modal-header {
                                        border-bottom: 1px solid $input-border;
                                        .modal-title {
                                            font-size: 16px;
                                            font-weight: 600;
                                            color: $text-color;
                                        }
                                    }

                                    .modal-body {
                                        .remove-icon-wrap {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            padding: 12px;
                                            margin-bottom: 15px;
                                            width: 48px;
                                            height: 48px;
                                            border-radius: 50%;
                                            background-color: $primary-color-10;
                                        }

                                        .remove-title {
                                            margin-bottom: 10px;
                                            font-size: 20px;
                                            font-weight: 600;
                                            line-height: 28px;
                                            text-align: center;
                                            color: $text-color;
                                        }

                                        .remove-text {
                                            margin-bottom: 10px;
                                            font-size: 14px;
                                            font-weight: 400;
                                            line-height: 20px;
                                            text-align: center;
                                            color: $body-secondary;
                                        }
                                    }

                                    .modal-footer {
                                        justify-content: space-between;
                                        border-top: none;

                                        button {
                                            width: 48%;
                                            padding: 12px 16px;
                                            border-radius: 25px;
                                            font-size: 14px;
                                            font-weight: 500;
                                            text-align: center;

                                            &.cancel {
                                                border: 1px solid $input-border;

                                                &:hover {
                                                    background-color: $background-secondary;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // storage for mobile view
                .storage-state {
                    text-align: left;
                    padding-left: 34%;

                    @media screen and (max-width: 576px) {
                        padding-left: 35%;
                    }

                    @media screen and (max-width: 412px) {
                        padding-left: 37%;
                    }

                    @media screen and (max-width: 375px) {
                        padding-left: 40%;
                    }



                    p {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        color: $text-color;

                        .color-stock {
                            display: inline-block;

                            img {
                                margin-right: 5px;
                                vertical-align: sub;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
            }

            .products-hook {
                max-width: 550px;
                width: 100%;
                padding: 3rem;
                margin: auto;
                background-color: $white;
                border-radius: 6px;
                box-shadow: $shadow;
                text-align: center;

                img {
                    display: block;
                    margin: auto;
                    width: 36px;
                }

                p {
                    margin: 10px 0 25px;
                    color: $text-color;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 28px;
                    text-align: center;
                }

                a {
                    padding: 12px 16px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .total-shop-list-container {
            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
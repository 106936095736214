.contact-form {
    position: relative;

    form {
        max-width: 600px;
        margin: 0 auto;
        padding: 25px;
        background-color: $white;
        border: 1px solid $input-border;
        border-radius: 6px;
        box-shadow: $shadow;

        .label-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: $text-color;
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select {
            box-shadow: $shadow;
            height: 48px;

            &.is-invalid {
                border: 2px solid $primary-color;
                background-image: none;
            }
        }

        select {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            color: $text-light-gray; 
        }

        textarea {
            box-shadow: $shadow;
            
            &.is-invalid {
                border: 2px solid $primary-color;
                background-image: none;
            }
        }

        button[type="submit"] {
            width: 100%;
            padding: 12px 16px;
            border-radius: 25px;
            font-size: 14px;
            font-weight: 500;
        }

        .gdpr-consent-group {
            input[type="checkbox"] {
                &.is-invalid {
                    border: 2px solid $primary-color;
                }
            }
            .gdprConsent-label {
                margin-left: 5px;
                color: $text-color;
                font-size: 14px;

                a {
                    color: $primary-color;
                }
            }
        }

        .msg-error {
            display: none;
            visibility: hidden;
            margin-top: 10px;
            padding: 10px;
            color: $primary-color;
            background-color: $primary-color-10;
            border: 1px solid $input-border-error;
            border-radius: 6px;
            font-size: 14px;

            p {
                line-height: 22px;
            }
           
            &.visible {
                display: block;
                visibility: visible;
            }
        }

        .confirmation-msg {
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            margin-top: 15px;
            border-radius: 6px;
            padding: 10px 15px;
            border: 1px solid $input-border;
            box-shadow: $shadow;
            
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                color: $text-color;

                @media screen and (max-width:430px) {
                    margin-left: 15px;
                }
            }

            img {
                width: 25px;
                margin-top: 5px;
            }
        }

    } 
    
    .feedback {
        margin-top: 25px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $body-secondary;
    }

    .back-to-home {
        margin-top: 25px;
        border-radius: 25px;
        padding: 0.75rem 1rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }   
}
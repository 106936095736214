.news-feed-home {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;

    // @media screen and (max-width: 576px) {
    //     margin-left: 25px;
    //     margin-right: 25px;
    // }

    @media screen and (max-width: 575.98px) {
        margin-left: 0px;
        margin-right: 0px;
    }

    &__header {
        padding-top: 50px;
        padding-bottom: 15px;
        justify-content: space-between;

        .title-wrapper {
            /// MOCKUP STYLE
            // h2 {
            //     font-size: 20px;
            //     font-weight: 600;
            //     line-height: 28px;
            // }

            h2 {
                font-size: 24px;
                font-weight: 600;
        
                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                }
            }
        }

        .link-all-news {
            margin-right: 8px;

            a {
                position: relative;
                color: $primary-color;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                text-decoration: none;

                &::after {
                    content: url('img/icons/chevron_right_red.svg');
                    position: relative;
                    top: 1px;
                    margin-left: 15px;
                }
            }
        }
    }

    &__content {
        .articles-wrapper {
            position: relative;
            justify-content: space-between;
            flex-wrap: wrap;

            @media screen and (max-width: 575.98px) {
                padding-left: 7px;
                padding-right: 7px;
            }

            .article {
                max-width: 32.5%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 25px;

                @include media-breakpoint-down(md){
                    max-width: 100%;
                }
               
                &__picture {
                    border-radius: 6px;
                    margin-bottom: 15px;
                    height: 245px;
                    min-height: 245px;
                    max-height: 245px;

                    img {
                        width: auto;
                        min-width: 100%;
                        min-height: 245px;
                        max-height: 245px;
                        object-fit: cover;
                        object-position: top center;
                        border-radius: 6px;
                        transition: all .4s ease-in-out;

                        &:hover {
                            transform: scale(1.025);
                        }
                    }     
                }

                &__tag {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $text-light-gray; 
                }

                &__title {
                    // font-size: 16px;
                    // font-weight: 600;
                    // line-height: 24px;

                    // a {
                    //     color: $text-color;
                    //     text-decoration: none;
                    //     transition: all .4s ease-in-out;

                    //     &:hover {
                    //         text-decoration: underline;
                    //     }
                    // }
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 26px;
                    
                    a {
                        color: $text-color;
                        text-decoration: none;
                        transition: color .4s ease-in-out;

                        &:hover {
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}
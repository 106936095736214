.user-favorites {
    .main-user-favorites {
        padding: 15px;
        margin-bottom: 25px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;

        p {
            line-height: 22px;

            &.title {
                font-weight: 600;
                font-size: 15px;
            }
        }

        .create-list-wrapper {
            margin: 25px 0 45px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 25px;
            }

            .create-list-indication {
                padding-left: 0;

                @include media-breakpoint-down(sm) {
                    display: flex;
                    align-items: center;
                }
            }

            .btn-wrapper {
                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    padding-right: 0;
                }

                @media screen and (max-width: 575px) {
                    margin: 20px 0;
                }

                button {
                    width: 100%;
                    padding: 8px 0px;
                    border-radius: 25px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                }
            }

            // Modal
            .modal {
                background-color: rgba(0, 0, 0, 0.2);
                
                .modal-dialog {
                    margin: auto;

                    .modal-content {
                        @media screen and (max-width: 500px) {
                            max-width: 100vw;
                        }
                    }

                    .modal-header {
                        border-bottom: 1px solid $input-border;
                        
                        .modal-title {
                            font-size: 16px;
                            font-weight: 600;
                            color: $text-color;
                        }
                    }

                    .modal-body {
                        form {
                            input[type="text"] {
                                &.has-error {
                                    border: 2px solid $primary-color;
                                }
                            }
    
                            .label-title {
                                position: relative;
                                font-size: 14px;
                                font-weight: 500;
                                color: $text-color;
                            }
    
                            .msg-error {
                                display: none;
                                visibility: hidden;
                                margin-top: 10px;
                                padding: 10px;
                                color: $primary-color;
                                background-color: $primary-color-10;
                                border: 1px solid $input-border-error;
                                border-radius: 6px;
                                font-size: 14px;
            
                                p {
                                    line-height: 22px;
                                }
            
                                &.visible {
                                    display: block;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    .modal-footer {
                        display: block;
                        border-top: none;

                        .buttons-wrapper-footer {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;

                            @include media-breakpoint-down(sm) {
                                flex-direction: column;
                                gap: 10px;
                            }
        
                            button {
                                width: 48%;
                                padding: 12px 0px;
                                border-radius: 25px;
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
        
                                @include media-breakpoint-down(sm) {
                                    width: 100%;
                                }
        
                                &.cancel {
                                    border: 1px solid $input-border;
                                }
        
                                &.create-favorite-list {
                                    &:hover {
                                        background-color: $primary-color-hover;
                                    }
                                }
                            }
                        }

                        .confirmation-form-favorite-list-created-msg {
                            .done,
                            .fail {
                                display: none;
                                margin-top: 15px;
                                border-radius: 6px;
                                padding: 10px 15px;
                                border: 1px solid $input-border;
                                justify-content: center;
                                align-items: flex-start;
                                box-shadow: $shadow;
                                
                                p {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 24px;
                                    color: $text-color;
                
                                    @media screen and (max-width:430px) {
                                        margin-left: 15px;
                                    }
                                }
                
                                img {
                                    width: 25px;
                                    margin-right: 8px;

                                    @media screen and (max-width:425px) {
                                        margin-top: 5px;
                                        margin-right: 5px;
                                    }
                                }
                
                                &.visible {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }

        .favorite-list-wrapper {

            .favorite-wrapper {
                margin-bottom: 15px;
                padding: 25px 5px;
                background-color: $white-color-10;
                border: $input-border;
                border-radius: 6px;
                box-shadow: $shadow; 

                .favorite-information {
                    .name-list {
                        span {
                            font-weight: 600;
                        }
                    }

                    .creation-list {
                        font-size: 12px;
                    }
                }

                .favorite-action-wrapper {
                    justify-content: space-between;

                    @include media-breakpoint-down(md) {
                        margin-top: 20px;
                    }

                    @media screen and (max-width: 575px) {
                        flex-wrap: wrap;
                    }    

                    button {
                        width: 48%;
                        gap: 5px;
                        padding: 8px 0px;
                        border-radius: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;

                        @media screen and (max-width: 575px) {
                            width: 100%;
                        } 


                        &.delete-list-btn {
                            border: 1px solid $input-border;

                            @media screen and (max-width: 575px) {
                                margin-bottom: 15px;
                            } 

                            &:hover {
                                background-color: $background-secondary;
                            }
                        }
                    }
                }
            }
        }

         // Modal
         .modal {
            background-color: rgba(0, 0, 0, 0.2);
            
            .modal-dialog {
                margin: auto;

                .modal-content {
                    @media screen and (max-width: 500px) {
                        max-width: 100vw;
                    }
                }

                .modal-header {
                    border-bottom: 1px solid $input-border;
                    
                    .modal-title {
                        font-size: 16px;
                        font-weight: 600;
                        color: $text-color;
                    }
                }

                .modal-body {
                    .confirm-delete {
                        margin: 10px 0;
                        color: $text-color;
                        font-size: 16px;
                        line-height: 25px;

                        @media screen and (max-width: 576px) {
                            margin: 0;
                        }
                    }
                }

                .modal-footer {
                    display: block;
                    border-top: none;

                    .buttons-wrapper-footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;

                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                            gap: 10px;
                        }
    
                        button {
                            width: 48%;
                            padding: 12px 0px;
                            border-radius: 25px;
                            font-size: 14px;
                            font-weight: 500;
                            text-align: center;
    
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                            }
    
                            &.cancel {
                                border: 1px solid $input-border;

                                &:hover {
                                    background-color: $background-secondary;
                                }
                            }
    
                            &[class^='confirm-delete-favorite-list-'] {
                                &:hover {
                                    background-color: $primary-color-hover;
                                }
                            }
                        }
                    }

                    .confirmation-form-favorite-list-deleted-msg {
                        .done,
                        .fail {
                            display: none;
                            margin-top: 15px;
                            border-radius: 6px;
                            padding: 10px 15px;
                            border: 1px solid $input-border;
                            justify-content: center;
                            align-items: flex-start;
                            box-shadow: $shadow;
                            
                            p {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 24px;
                                color: $text-color;
            
                                @media screen and (max-width:430px) {
                                    margin-left: 15px;
                                }
                            }
            
                            img {
                                width: 25px;
                                margin-right: 8px;

                                @media screen and (max-width:425px) {
                                    margin-top: 5px;
                                    margin-right: 5px;
                                }
                            }
            
                            &.visible {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    } 
}
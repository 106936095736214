.forgottenPwd {
    background-color: rgba(0, 0, 0, .2);

    .modal-dialog {
        margin: .5rem auto;

        .modal-header {
            position: relative;
            border-bottom: none;
            border-bottom: 1px solid $input-border;

            .modal-title {
                font-size: 16px;
            }

            .close {
                position: absolute;
                right: 15px;
            }
        }

        .modal-body {
            padding: 1rem 2rem;

            @media screen and (max-width: 390px) {
                padding: .75rem;
            }

            label {
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: $text-color;
                text-align: center;
            }

            input[type="email"] {
                box-shadow: $shadow;
                height: 48px;

                &.msg-error-mail {
                    border: 2px solid $input-border-error;
                }

                &::placeholder {
                    text-align: center;
                }
            }

            input[type="submit"]{
                margin-top: 20px;
                width: 100%;
                height: 48px;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }
        }

        .modal-footer {
            border-top: none;
            justify-content: center;
            padding: 0 2rem .75rem 2rem;

            @media screen and (max-width: 390px) {
                padding: .75rem;
            }

            .msg-confirmation-form {    
                width: 100%;

                .done {
                    display: none;
                    margin-top: 15px;
                    border-radius: 6px;
                    padding: 10px 15px;
                    border: 1px solid $input-border;
                    justify-content: center;
                    align-items: flex-start;
                    box-shadow: $shadow;
                    
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 24px;
                        color: $text-color;
    
                        @media screen and (max-width:430px) {
                            margin-left: 15px;
                        }
                    }
                    
                    img {
                        width: 25px;
                        margin-right: 8px;
                        
                        @media screen and (max-width:425px) {
                            margin-top: 5px;
                            margin-right: 5px;
                        }
                    }
    
                    &.visible {
                        display: flex;
                    }
                }

                .fail {
                    display: none;
                    margin-top: 15px;
                    border-radius: 6px;
                    padding: 10px 15px;
                    border: 1px solid $input-border;
                    justify-content: center;
                    align-items: flex-start;
                    box-shadow: $shadow;

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                        color: $text-color;
    
                        @media screen and (max-width:430px) {
                            margin-left: 15px;
                        }
                    }
    
                    img {
                        width: 25px;
                        margin-right: 8px;

                        @media screen and (max-width:425px) {
                            margin-top: 5px;
                            margin-right: 5px;
                        }
                    }
    
                    &.visible {
                        display: flex;
                    }
                }
            }
        }
    }
}